<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-card id="page_Authentication">
      <tabs type="primary" v-model="active_tab" centered>
        <tab-pane id="ISP" :label="this.t('ISP')">
        <span slot="label">
          <i class="now-ui-icons business_bank"></i>
        </span>
          <session-control v-if="active_tab === 'ISP'" guiStore="isp" />
          <identity-providers
            v-if="active_tab === 'ISP'"
            guiStore="isp"
          />
        </tab-pane>

        <tab-pane
          id="Enterprise"
          :label="this.t('Enterprise')"
          v-if="$store.getters['settings/isIOT']"
        >
        <span slot="label">
          <i class="now-ui-icons business_briefcase-24"></i>
        </span>
          <session-control
            v-if="active_tab === 'Enterprise'"
            guiStore="ent"
          />
          <identity-providers
            v-if="active_tab === 'Enterprise'"
            guiStore="ent"
          />
        </tab-pane>

        <tab-pane
          :label="this.t('Residential')"
          id="Residential"
          v-if="!$store.getters['settings/isIOT']"
        >
        <span slot="label">
          <i class="now-ui-icons users_single-02"></i>
        </span>
          <session-control
            v-if="active_tab === 'Residential'"
            guiStore="res"
          />
          <identity-providers
            v-if="active_tab === 'Residential'"
            guiStore="res"
          />
        </tab-pane>
      </tabs>
    </b-card>
  </div>
</template>

<script>
import { TabPane, Tabs } from "@/components";
import SessionControl from "./components/SessionControl";
import IdentityProviders from "./components/IdentityProviders";
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "authentication",
  components: {
    SessionControl,
    IdentityProviders,
    TabPane,
    Tabs,
    PageTitleCard
  },
  props: {
    guiStore: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active_tab: "ISP",
    };
  },
  mounted() {
    this.active_tab =
      this.guiStore == "isp"
        ? "ISP"
        : this.guiStore == "ent"
        ? "Enterprise"
        : "Residential";
  },
  methods: {},
};
</script>
<style scoped lang="scss">
</style>
