<template>
  <b-card  >
    <slot name="header">
      <h5 slot="header" class="title">
        <svgicon class="icon" icon="users" width="16" height="16" />
        <span style="padding-left:1em">{{t('users')}}</span>
      </h5>
    </slot>

    <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
      <b-row  align-h="center" >
        <b-col lg="6" md="12">
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-users-objects"
                v-model="server.users.objects"
                v-validate="{ required: true }"
                name="User Objects"
                :error="getError('User Objects')"
                :label="t('objects')"
                @input="(event) => $emit('users-change', event, 'objects')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-users-name"
                v-model="server.users.name"
                v-validate="{ required: true }"
                name="User Name"
                :error="getError('User Name')"
                :label="t('name')"
                @input="(event) => $emit('users-change', event, 'name')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-users-filter"
                v-model="server.users.filter"
                @input="(event) => $emit('users-change', event, 'filter')"
                :label="t('filtering-data')"
                name="filter"
                v-validate=""
                :error="getError('filter')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <el-select
                id="ldap-users-get"
                v-model="server.users.get"
                class="select-primary"
                :placeholder="'----------------'"
                @change="(event) => $emit('users-change', event, 'get')"
              >
                <el-option v-for="option in $options.getOptions"
                  class="select-primary"
                  :value="option.value"
                  :label="t(option.label)"
                  :key="option.value">
                </el-option>
              </el-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" md="12">
          <b-row class="row-height">
            <b-col>
              <fg-input
              id="ldap-users-members"
                v-model="server.users.members"
                v-validate=""
                name="User Members"
                :error="getError('User Members')"
                :label="t('members')"
                @input="(event) => $emit('users-change', event, 'members')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-users-email"
                v-model="server.users.email"
                @input="(event) => $emit('users-change', event, 'email')"
                :label="t('electronics-mail')"
                type="mail"
                name="email"
                v-validate="'required'"
                :error="getError('email')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-users-alias"
                v-model="server.users.alias"
                @input="(event) => $emit('users-change', event, 'alias')"
                :label="t('alias')"
                name="alias"
                v-validate="{ required: true }"
                :error="getError('alias')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-users-ha1"
                v-model="server.users.ha1"
                @input="(event) => $emit('users-change', event, 'ha1')"
                :label="t('ha1')"
                name="ha1"
                v-validate=""
                :error="getError('ha1')"
              ></fg-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>
<script>
import { Select, Option } from 'element-ui';
import { Switch } from '@/components';
import bus from './bus';
import { find, propEq } from 'ramda'

export default {
  inject: ['$validator'],
  name: 'users-form',
  getOptions: [
    {value: "first", label: "First"},
    {value: "last", label: "Last"},
    {value: "-", label: "----------------"}
  ],
  props: {
    server: {
      type: Object,
      default: () => {
        return {
          users: {
            objects: "",
            name: "",
            filter: "",
            get: "-",
            members: "",
            email: "",
            alias: "",
            ha1: ""
          }
        }
      }
    },
    clusterName: {
      type : String,
    },
    clusterType: {
      type : String,
    }
  },
  data() {
    return {
      updating: false
    }
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Switch.name]: Switch
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    onValidate() {
      this.$validator.validateAll();
      if (this.errors.any()) bus.$emit('errors-changed', this.errors.errors);
    }
  },
  computed:{
    ldap() { return this.clusterType.indexOf('ldap') >= 0 }
  },
  mounted() {
    // Listen on the bus for the parent component running validation
    bus.$on('validate', this.onValidate);

    // Watch for the changes to the childs error bag and pass back to the parent
    this.$watch(() => this.errors.errors, (newValue, oldValue) => {
      const newErrors = newValue.filter(error => { find(propEq('field', error.field))(oldValue) === undefined });
      const oldErrors = oldValue.filter(error => { find(propEq('field', error.field))(newValue) === undefined });
      bus.$emit('errors-changed', newErrors, oldErrors);
    });
  },
  beforeDestroy() {
    // When destroying the element remove the listeners on the bus.
    // Useful for dynaically adding and removing child components
    bus.$emit('errors-changed', [], this.errors.errors);
    bus.$off('validate', this.onValidate);
  }
}

</script>

<style lang="scss" scoped>
  .no-margin-bottom {
    margin-bottom: 0;
  }
  .align-flex-end {
    align-items: flex-end;
  }
  .no-margin {
    margin: 0;
  }
  .refresh {
    cursor: pointer;
    margin-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .row-height {
    height: 25%;
  }
</style>
