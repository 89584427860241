<template>
  <form @submit.prevent="save">
    <b-row>
      <b-col
        :class="[
          {'col-12': isBasicMode},
          {'col-12 col-md-6 border-right': isAdvancedMode}
        ]"
      >
        <b-row>
          <b-col
            :class="[
              'mb-4',
              {'col-12 col-md-6': isBasicMode},
              {'col-10': isAdvancedMode}
            ]"
          >
            <fg-input
              v-model="textsCustomization.app_name"
              :visible_for_permissions="visible_for_permissions"
              id="app_name"
              name="app_name"
              autocapitalize="yes"
              :label="t('application_name_label')"
              :placeholder="t('application_name_placeholder')"
              v-validate="'max:16'"
              :error="getError('app_name')"
              style="margin-bottom: 6px;"
            />
            <p class="help-text ml-3">
              {{ t('application_name_help_text') }}
            </p>
          </b-col>
          <b-col
            :class="[
            'mb-4',
            {'col-12 col-md-6': isBasicMode},
            {'col-10': isAdvancedMode}
          ]"
          >
            <fg-input
              v-model="textsCustomization.company_name"
              :visible_for_permissions="visible_for_permissions"
              id="company_name"
              name="company_name"
              autocapitalize="yes"
              :label="t('company_name_label')"
              :placeholder="t('company_name_placeholder')"
              v-validate="'max:32'"
              :error="getError('company_name')"
              style="margin-bottom: 6px;"
            />
            <p class="help-text ml-3">
              {{ t('company_name_help_text') }}
            </p>
          </b-col>
          <b-col
            :class="[
            'mb-4',
            {'col-12': isBasicMode},
            {'col-10': isAdvancedMode}
          ]"
          >
            <fg-input
              v-model="textsCustomization.company_url"
              :visible_for_permissions="visible_for_permissions"
              id="company_url"
              name="company_url"
              autocapitalize="yes"
              :label="t('company_website_label')"
              :placeholder="t('company_website_placeholder')"
              v-validate="{ required: true, url: { require_protocol: true }, max: 1024 }"
              :error="getError('company_url')"
              style="margin-bottom: 6px;"
            />
            <p class="help-text ml-3">
              {{ t('company_website_help_text') }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        :class="[
          {'col-12': isBasicMode},
          {'col-12 col-md-6': isAdvancedMode}
         ]"
      >
        <b-row class="mb-4" v-if="isAdvancedMode">
          <b-col md="12" class="pl-md-4">
            <label>{{ t('supported_languages_title') }}</label>
              <multi-select-chip
                :items="languagesSelectList"
                @select="handleLanguageSelect"
              />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="pl-md-4">
            <label>{{ t('available_information_title') }}</label>
            <div class="mb-3">
              <n-checkbox
                :title="t('show_footer_label')"
                :checked="textsCustomization.show_footer_text"
                @input="onStatusChange($event, 'show_footer_text')"
                :visible_for_permissions="visible_for_permissions"
              />
              <p class="help-text ml-4">
                {{ copyrightSuffix }}
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="pl-md-4">
            <div class="mb-3" v-if="isAdvancedMode">
              <n-checkbox
                :title="t('allow_add_users_label')"
                :checked="textsCustomization.env_options.visibility.acc_show_add_users_button"
                @input="onStatusChange($event, 'acc_show_add_users_button')"
                :visible_for_permissions="visible_for_permissions"
              />
              <p class="help-text ml-4">
                {{ t('allow_add_users_help_text') }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="3" md="4" xs="12" sm="6" class="ml-auto">
        <n-button
          :visible_for_permissions="visible_for_permissions"
          native-type="submit"
          type="primary"
          id="btn_submit"
          round block
        >
          <template>
            {{ t('Save') }}
          </template>
        </n-button>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import { Switch } from '@/components';
import Checkbox from '@/components/Inputs/Checkbox';
import MultiSelectChip from "@/components/Chips/MultiSelectChip";
import FormMixin from '@/mixins/FormMixin';
import permissions from '@/mixins/permissions';
import _ from "lodash";
import {mapActions} from "vuex";

export default {
  name: "security-manager-ui-texts-tab",
  mixins: [FormMixin, permissions],
  components: {
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch,
    [MultiSelectChip.name]: MultiSelectChip
  },
  props: {
    value: {
      type: Object,
    },
    customizationType: {
      type: String
    },
    visible_for_permissions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      textsCustomization: null,
      languagesCustomization: null,
      year: new Date().getFullYear(),
      isInitDone: false
    }
  },
  created() {
    if (this.value){
      this.textsCustomization = _.cloneDeep(this.value.textsCustomization);
      this.languagesCustomization = _.cloneDeep(this.value.languagesCustomization);
    }
  },
  mounted() {
    this.isInitDone = true;
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue){
        this.textsCustomization = _.cloneDeep(newValue.textsCustomization);
        this.languagesCustomization = _.cloneDeep(newValue.languagesCustomization);
        setTimeout(() => {
          this.$validator.reset();
        }, 100)
      }
    },
    textsCustomization: {
      handler: function (newValue) {
        if (this.isInitDone) {
          const dirty = !_.isEqual(newValue, this.value.textsCustomization);
          this.setIsFormDirty(dirty);
        }
      },
      deep: true,
    },
    languagesCustomization: {
      handler: function (newValue) {
        if (this.isInitDone) {
          const dirty = !_.isEqual(newValue, this.value.languagesCustomization);
          this.setIsFormDirty(dirty);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('settings', ['setIsFormDirty']),
    onStatusChange(value, id) {
      switch(id) {
        case 'show_footer_text':
          this.textsCustomization.show_footer_text = value;
          break;
        case 'acc_show_add_users_button':
          this.textsCustomization.env_options.visibility.acc_show_add_users_button = value;
          break;
      }
    },
    handleLanguageSelect(languages) {
      this.languagesCustomization = languages.map(language => ({
        language_id: language.value,
        enabled: language.selected,
      }));;
    },
    isUnselectDisabled(language) {
      return !!(this.enabledLanguages.length === 1
        && this.enabledLanguages[0].language_id === language.language_id)
    },
    async save(){
      let isValid = await this.$validator.validateAll();
      if (isValid){
        this.$emit('save', {
          textsCustomization: this.textsCustomization,
          languagesCustomization: this.languagesCustomization
        })
        this.setIsFormDirty(false);
        setTimeout(() => {
          this.$validator.reset();
        }, 100)
      }
    },
  },
  computed: {
    copyrightSuffix() {
      return `\u00A9 ${this.year}, ${this.t('show_footer_help_text')}`;
    },
    languagesSelectList() {
      if (this.languagesCustomization.length) {
        return this.languagesCustomization.map(language => ({
          value: language.language_id,
          selected: language.enabled,
          disabled: !this.permission_granted || this.isUnselectDisabled(language)
        }));
      }
    },
    enabledLanguages() {
      return this.languagesCustomization
        .filter(language => language.enabled);
    },
    isAdvancedMode() {
      return this.customizationType === 'acc';
    },
    isBasicMode() {
      return ['isp', 'ent'].includes(this.customizationType);
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .border-right {
    border-right: none !important;
  }
}
</style>
