<template>
  <b-container class="p-0" fuild style="max-width: 2560px;">
    <b-row>
      <b-col
        :md="admin ? '5': '12'"
        class="row-eq-height"
      >
            <identity-card v-model="identity" 
                    :change-password="!isLdap"
                    :account_type="'isp'"
                    :isOperationsAdmin="isOperationsAdmin"
                    :visible_for_permissions='[{actions: ["POST"], path: "/managers"}]'>

                <div slot="header">
                    <h5 class="title"><i class="mr-2"><svgicon class="icon" icon="item-admin"/></i>
                        <template v-if="admin">
                            {{t('authentication')}}
                        </template>
                        <template v-else>
                            {{t('newAdmin')}}
                        </template>
                    </h5>
                </div>
            </identity-card>
      </b-col>
      <b-col md="7" v-if="admin" class="entranceFromRight row-eq-height">
          <edit-profile-form v-model="profile" :editable="!isLdap" :visible_for_permissions='[{actions: ["POST"], path: "/managers"}]' :isIspAdmin='isIspAdmin'>
            <div slot="header">
                <h5 class="title"><i class="now-ui-icons business_badge mr-2"/>{{t('profile')}}</h5>
            </div>
          </edit-profile-form>
      </b-col>

      <!-- <b-col v-if="admin" class="entranceFromBottom">
            <roles-form :asmroles="$store.getters.asm_roles" :nsroles="$store.getters.ns_roles" v-model="roles" @update="refresh">
            <div slot="header">
                <h5 class="title"><i class="now-ui-icons objects_key-25 mr-2"/>Roles Asignment</h5>
            </div>
            </roles-form>
      </b-col> -->
   </b-row>
  </b-container>
</template>

<script>
import EditProfileForm from '../Managers/EditProfileForm';
import IdentityCard from '../Managers/IdentityCard'
import RolesForm from './RolesForm'

import _ from 'lodash';
import i18n from "../../i18n";
import isIspAccount from '@/util/util';

import { mapGetters } from "vuex";

export default {
    name: 'administrator-edit',
    components: {
      EditProfileForm,
      IdentityCard,
      RolesForm
    },
    default_profile: { 
        created_at: null,
        email: '',
        name: '',
        phone_number: null,
        updated_at: null,
        user_id: null,
        language_id: 'en-US',
        time_zone: 'America/New_York'
    },
    props: [ "id"],
    data() {
      return {
        admin: null,
        newid: null,
        saved_admin: null
      }
    },
    mounted() {
        this.refresh(0);
    },
    methods: {
        refresh(wait){
            this.$store.dispatch('generalManagement/administrators/getRoles', wait)
            this.$store.dispatch('providers/getActiveProviders')
            if (this.id){
            let _vm = this
                this.$store.dispatch('generalManagement/administrators/getAdministrator', this.id)
                .then( res => _vm.admin = res)
                .catch( err => this.$router.replace('/'))
            }
        },
        updateAdministrator(data_to_retry){
            this.$store.dispatch(this.updateAdminFn, { id: this.realid, data: data_to_retry})
            .then( response => {
                this.$store.commit('setSuccess', this.t('save_success'))
                this.admin = _.merge(this.admin, data_to_retry)
            })
            .catch( error => {
                if (error.response && error.response.data){
                    let data = error.response.data
                    if ( data.type.endsWith('NEEDS_AUTHORIZATION')){
                        // open re-authentication dialog, result will be provided by change in privileges
                        this.$store.commit('auth/toggleReauthenticationModal', {
                                toggle: true, 
                                privileges_requested: data.title.split(','),
                                retry_data: data_to_retry
                        });
                    }
                    else if (data.title.includes("identity.email")) {
                        this.$store.commit("setError", this.t("errors_email"));
                    } else if (data.title.includes("identity.phone_number")) {
                        this.$store.commit("setError", this.t("errors_phone"));
                    } else {
                        this.$store.commit("setError", error);
                    }
                }
            })
        },
    },
    watch:{
      newid(newValue){
        let _vm = this
            this.$store.dispatch('generalManagement/administrators/getAdministrator', newValue)
              .then( res => _vm.admin = res)
              .catch( err => this.$router.replace('/'))
      },
      privileges: function(newValue, oldValue){
        if (newValue && newValue.length){
            this.updateAdministrator(this.retry_data)
        }
      },
      admin(newValue, oldValue){
          this.saved_admin = newValue
      }
    },
    computed: {
        ...mapGetters('auth', ['privileges', 'retry_data', 'manager']),
        isLdap() { return this.identity && this.identity.identity_provider_type === 'IdentityProviderLDAP'},
        realid(){
          return this.id || this.newid
        },
        updateAdminFn(){
            if (this.realid){
                if (this.realid === this.manager)
                    return 'generalManagement/administrators/updateAdministratorProfile'
                else
                    return 'generalManagement/administrators/updateAdministrator'
            }
            else
                return 'generalManagement/administrators/createAdministrator'
        },
        profile: {
            get() {
              let ret = null
              if(this.admin){
                  ret = { created_at : this.admin.identity.created_at,
                          user_id: this.admin.identity.identity_instances.user_id,
                          email: this.admin.identity.email,
                          name: this.admin.identity.name,
                          phone_number: this.admin.identity.phone_number,
                          updated_at: this.admin.identity.updated_at,
                          language_id: this.admin.manager_metadata.language_id,
                          time_zone: this.admin.manager_metadata.time_zone
                  }
              }
              else{
                  ret = this.$options.default_profile
              }
              return ret
            },
            set(newValue){
                let data = {
                    identity: {
                    },
                    manager_metadata: {
                        language_id: newValue.language_id,
                        time_zone: newValue.time_zone
                    }
                }
                if (!!newValue.name)
                    data.identity.name = newValue.name
                else if (!!this.saved_admin.identity.name)
                    data.identity.name = ''

                if (!!newValue.phone_number){
                    if (newValue.phone_number !== this.saved_admin.identity.phone_number)
                        data.identity.phone_number = newValue.phone_number
                }
                else if (!!this.saved_admin.identity.phone_number)
                    data.identity.phone_number = null

                if (!!newValue.email){
                    if (newValue.email !== this.saved_admin.identity.email)
                        data.identity.email = newValue.email
                }
                else if (!!this.saved_admin.identity.email)
                    data.identity.email = null

                if (this.admin){
                    this.updateAdministrator(data)
                }
                else{
                    data.identity.identity_instances = [{ user_id: newValue.user_id,
                                                        identity_instance_type: 'IdentityInstanceDatabase',
                                                        identity_provider_type: 'IdentityProviderDatabase'}]
                    let _vm = this
                    this.$store.dispatch('generalManagement/administrators/createAdministrator', data).then( res => _vm.newid = res,
                    error => {
                        this.$store.commit('setError', this.t(error.type));
                    });
                }
            }
        },
        identity: {
            get() {
              let ret = null
              if(this.admin){
                  ret = { blocked: this.admin.identity.blocked,
                          identity_instance_type: this.admin.identity.identity_instances.identity_instance_type,
                          identity_provider_type: this.admin.identity.identity_instances.identity_provider_type,
                          user_id: this.admin.identity.identity_instances.user_id,
                          last_ip: this.admin.identity.last_ip,
                          last_login: this.admin.identity.last_login,
                          last_password_reset: this.admin.identity.last_password_reset,
                          IdentityInstanceDatabase: {
                              password: ''
                          },
                          roles: this.admin.roles.length > 0 ? this.admin.roles[0] : ''
                  }
              }
              else{
                  ret = { blocked: false,
                          identity_instance_type: 'IdentityInstanceDatabase',
                          identity_provider_type: 'IdentityProviderDatabase',
                          user_id: null,
                          last_ip: null,
                          last_login: null,
                          last_password_reset: null,
                          IdentityInstanceDatabase: {
                              password: ''
                          },
                          roles: 'Operations Administrator'
                  }
              }
              return ret
            },
            set(newValue){
                let data = {
                    identity: {
                        blocked: newValue.blocked,
                    },
                    roles: [newValue.roles]
                }
                if ( !!newValue[newValue.identity_instance_type].password){
                    let password = newValue[newValue.identity_instance_type].password;
                    data.identity.identity_instances = {
                        user_id: newValue.user_id,
                        identity_instance_type: newValue.identity_instance_type,
                        identity_provider_type: newValue.identity_provider_type,
                        password : password
                    }
                }

                if (this.admin){
                    this.updateAdministrator(data)
                }
                else{
                    data.identity.identity_instances = [data.identity.identity_instances]
                    data.manager_metadata= { language_id: 'en-US',
                                             time_zone: 'America/New_York' }
                    let _vm = this

                    this.$store.dispatch('generalManagement/administrators/createAdministrator', data).then( res => _vm.newid = res ,
                    error => {
                        if (error.type && error.type === 'MANAGER.LIMIT_EXCEEDED') {
                            this.$store.commit('setError', i18n.t('MAX_ADMINS_ALLOWED'), {root: true});
                        } else {
                            this.$store.commit('setError', this.t(error.type), {root: true});
                        }
                    });
                }

            }
        },
        isOperationsAdmin() {
            return this.$store.getters['auth/userRoles'].length > 0 && this.$store.getters['auth/userRoles'][0] == 'Operations Administrator';
        },
        isIspAdmin() {
            return isIspAccount(this.admin.account_id);
        }
    }
  }
</script>
<style>
</style>
