<template>
    <div>
      <page-title-card
        :page-title="t('title')"
        :page-description="t('description')"
      >
        <template #button>
          <n-button
            @click.native="handleNew"
            type="primary"
            :visible_for_permissions='[{actions: ["POST"], path: "/managers/.*"}]'
            size="md" round block
          >
            <i slot="label"><svgicon class="icon" icon="icon-new" /></i>
            {{t('new')}}
          </n-button>
        </template>
      </page-title-card>
      <b-card no-body>
        <b-card-body style="padding: 0px;">
          <paginated-table :rows='users'
                           :columns='columns'
                           :actions="actions"
                           :search="[{value: 'name', key: 'name', label : t('name')},
                                      {value: 'user_id', key: 'user_id', label : t('login_name')}]"
                           @item-edit="handleEdit" @item-remove="handleRemove"
                           :searchRestrictions = "{'name': '^[a-zA-Z0-9\-\._@]+$', 'user_id': '^[a-zA-Z0-9\-\._@]+$'}"
                           :buttonDisabled="buttonDisabled.bind(this)"
                           v-model="search_field_value"
                           :updating="updating"
                           :itemsPerPage="query.limit"
                           :serverpagination="search_pagination" @prev="handlePrevPage" @next="handleNextPage" @pagination="HandlePagination"/>
        </b-card-body>

        <template v-slot:footer>
          <div class="hr-grey mb-2"/>
          <div @click="reload" v-if="!updating" style="cursor: pointer;display: inline;">
            <i class="now-ui-icons arrows-1_refresh-69" />
            {{ t('Update now') }}
          </div>
          <div v-else>
            <i class="now-ui-icons loader_refresh spin"/>
            {{ t('Updating...') }}
          </div>
        </template>
      </b-card>
    </div>
</template>

<script>
import { PaginatedTable } from '@/components';
import { ListFormatter, AvatarFormatter, DateTimeFormatter, SwitchFormatter } from '@/components/Tables/Formatters'
import PageTitleCard from "../../components/AsmConfigurations/PageTitleCard";

export default {
    name: 'administrators',
    components: {
      PageTitleCard,
      PaginatedTable
    },
    data() {
        return {
            updating: false,
            columns: [
                {
                    prop: 'name',
                    label: '',
                    minWidth: 75,
                    formatter: AvatarFormatter
                },
                {
                    prop: 'name',
                    label: this.t('name'),
                    minWidth: 185
                },
                {
                    prop: 'user_id',
                    label: this.t('login_name'),
                    minWidth: 120
                },
                {
                    prop: 'created_at',
                    label: this.t('Created At'),
                    minWidth: 195,
                    formatter: DateTimeFormatter
                },
                {
                    prop: 'roles',
                    label: this.t('Roles'),
                    minWidth: 200,
                    formatter: ListFormatter
                }
            ],
            actions: {
                minWidth: 100,
                label: this.t('Actions'),
                fixed: 'right',
                items: [
                    {type: 'warning', icon: 'now-ui-icons business_badge', event: 'item-edit', action: this.t('Edit'), visible_for_permissions: [{actions: ["PATCH"], path: "/managers/.*"}]},
                    {type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'item-remove', action: this.t('Remove'), confirm: true, visible_for_permissions: [{actions: ["DELETE"], path: "/managers/.*"}]},
                ]
            },
            managers: new Map(),
            query: {
                field_name: 'name',
                sort: 'asc',
                limit: 10,
            },
            self_query: {},
            currentPage: 1,
            nextPage: 0,
            changingPage: false,
            lastManagerRemoved: false
        }
    },
    mounted() {
        this.refresh(0)
    },
    computed: {
        users(){
            let ret = [];
            let vm = this
            this.managers.forEach( (value, key) => {
                let item = {
                    manager_id: value.manager_id,
                    name: value.identity.name,
                    user_id: value.identity.identity_instances.user_id,
                    created_at: this.toLocaleString(this.datetimeFromISO(value.identity.created_at)),
                    roles: value.roles.map(role => (role == "Troubleshooting" ? this.t(role + "_ROL") : this.t(role)))
                }
                ret.push(item)
            })
            let arRet = this.search_pagination
                ? ret
                : Array.from(ret).sort( (a,b) => {
                    return a.user_id.toUpperCase() > b.user_id.toUpperCase() ? 1: -1
                })
            return arRet;
        },
        search_field_value: {
            get(){
                if( this.query && this.query.field_value)
                    return { field: 'name', value: this.query.field_value}
                else
                    return  { field: 'name', value: ''}
            },
            set(newValue){
                this.handleSearch(newValue)
            }
        },
        search_pagination: {
            get(){
                return { prev: !!this.query && !!this.query.prev_id,
                        next: !!this.query && !!this.query.next_id,
                        currentPage: this.currentPage }
            }
        }
    },
    methods: {
        handleNew(){
            this.$router.push({ name: "General Management / Administrators / New"})
        },
        handleEdit(data){
            this.$router.push( {path: '/general_management/administrators/edit/' + encodeURIComponent(data.row.manager_id)})
        },
        handleRemove(data){
            if ( data.row.user_id == 'admin')
                this.$store.commit('setError', this.t('Initial admin can\'t be removed'))
            else{
                this.updating = true;
                this.$store.dispatch('generalManagement/administrators/getAdministrators', {query: {sort: 'asc'}})
                .then( res => {
                    if ( res.size <= 1){
                        this.updating = false;
                        this.$store.commit('setError', this.t('The administrator cannot be removed: There should be at least one administrator'))
                    }
                    else{
                        this.$store.dispatch('generalManagement/administrators/removeAdministrator', data.row.manager_id )
                        .then (res => {
                            if(this.managers.size === 1 && this.currentPage !== 1){
                                this.lastManagerRemoved = true
                                this.handlePrevPage()
                            }
                            else if(this.currentPage === 1){
                                this.HandlePagination(this.query.limit)
                            }
                            else{
                                this.reload()
                            }
                        })
                        .catch( err => {
                            this.updating = false;
                        })
                    }
                })
                .catch(err => {
                    this.updating = false;
                })
            }
        },
        handlePrevPage(){
            if (this.query){
                ['next_id', 'next_name'].forEach( item => {
                    if ( this.query[item])
                    delete this.query[item]
                });
                if ( this.query.prev_id){
                    this.nextPage = -1
                    this.changingPage = true;
                    this.refresh()
                }
            }
        },
        handleNextPage(){
            if (this.query){
                ['prev_id', 'prev_name'].forEach( item => {
                    if ( this.query[item])
                    delete this.query[item]
                });
                if ( this.query.next_id){
                    this.nextPage = 1
                    this.changingPage = true;
                    this.refresh()
                }
            }
        },
        HandlePagination(value){
            this.currentPage = 1;
            this.nextPage = 0;
            this.changingPage = false;
            this.self_query.limit = value;
            ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
                if ( this.self_query[item])
                   delete this.self_query[item]
            });
            this.reload()
        },
        handleSearch: _.debounce(function (value) {
            this.currentPage = 1
            this.nextPage = 0;
            if ( value){
                this.query.field_operator = 'sw'
                this.query.field_name = value.field
                this.query.field_value = value.value;
            }
            else{
                ['field_operator', 'field_name', 'field_value'].forEach( item => {
                        if ( this.query[item])
                            delete this.query[item]
                    });
            };
            ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
                if ( this.query[item])
                  delete this.query[item]
            });
            this.refresh();
        }, 500),
        reload(){
            if (this.self_query){
                this.changingPage = false;
                this.query = this.self_query
                this.nextPage = 0;
                this.refresh()
            }
        },
        refresh(wait){
            this.updating = true;
            this.$store.dispatch('generalManagement/administrators/getAdministrators', { wait: wait, query: this.query})
            .then( res => {
                this.managers = new Map(res.items)
                this.query = res.query
                this.self_query = res.self
                if(this.changingPage){
                    this.currentPage += this.nextPage
                }
                if(this.lastManagerRemoved && this.managers.get(this.query.next_id)){
                    this.lastManagerRemoved = false
                    delete this.query.next_id
                }
                else{
                    this.lastManagerRemoved = false
                }
                this.updating = false
            })
            .catch( err => {
                this.updating = false
            });
        },
        buttonDisabled(row, event) {
            // Disable logged in admin account removal
            let res;
            (row.user_id == this.$store.getters["auth/id"] && event == 'item-remove') ? res = true : res = false;
            return res;
        }
    }
}
</script>

