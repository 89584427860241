<template>
  <b-card class="card-height">
    <slot name="header">
      <h5 slot="header" class="title">
        <svgicon class="icon" icon="icon-groups" width="16" height="16" />
        <span style="padding-left:1em">{{t('groups')}}</span>
      </h5>
    </slot>

    <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
      <b-row  align-h="center" >
        <b-col>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-groups-objects"
                v-model="server.groups.objects"
                v-validate="{ required: true }"
                name="Groups Objects"
                :error="getError('Groups Objects')"
                :label="t('objects')"
                @input="(event) => $emit('groups-change', event, 'objects')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-groups-name"
                v-model="server.groups.name"
                v-validate="{ required: true }"
                name="Groups Name"
                :error="getError('Groups Name')"
                :label="t('name')"
                @input="(event) => $emit('groups-change', event, 'name')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-groups-members"
                v-model="server.groups.members"
                v-validate="{ required: true }"
                name="Groups Members"
                :error="getError('Groups Members')"
                :label="t('members')"
                @input="(event) => $emit('groups-change', event, 'members')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-groups-nested"
                v-model="server.groups.nested"
                v-validate="'required|numeric'"
                type="number"
                name="nested"
                :error="getError('nested')"
                :label="t('nested')"
                @input="(event) => $emit('groups-change', event, 'nested')"
              ></fg-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>
<script>
import bus from './bus';
import { find, propEq } from 'ramda';

export default {
  inject: ['$validator'],
  name: 'groups-form',
  props: {
    server: {
      type: Object,
      default: () => {
        return {
          groups: {
            objects: "",
            name: "",
            nested: 0,
            members: ""
          }
        }
      }
    },
    clusterName: {
      type : String,
    },
    clusterType: {
      type : String,
    }
  },
  data() {
    return {
      updating: false
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    onValidate() {
      this.$validator.validateAll();
      if (this.errors.any()) bus.$emit('errors-changed', this.errors.errors);
    }
  },
  mounted() {
    // Listen on the bus for the parent component running validation
    bus.$on('validate', this.onValidate);

    // Watch for the changes to the childs error bag and pass back to the parent
    this.$watch(() => this.errors.errors, (newValue, oldValue) => {
      const newErrors = newValue.filter(error => { find(propEq('field', error.field))(oldValue) === undefined });
      const oldErrors = oldValue.filter(error => { find(propEq('field', error.field))(newValue) === undefined });
      bus.$emit('errors-changed', newErrors, oldErrors);
    });
  },
  beforeDestroy() {
    // When destroying the element remove the listeners on the bus.
    // Useful for dynaically adding and removing child components
    bus.$emit('errors-changed', [], this.errors.errors);
    bus.$off('validate', this.onValidate);
  }
}

</script>

<style lang="scss" scoped>
  .no-margin-bottom {
    margin-bottom: 0;
  }
  .align-flex-end {
    align-items: flex-end;
  }
  .no-margin {
    margin: 0;
  }
  .refresh {
    cursor: pointer;
    margin-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .row-height {
    height: 25%;
  }
  .card-height {
    height: calc(100% - 30px);
  }
</style>
