<template>
  <span class="flex-container">
    <component :is="tag"
             :type="nativeType"
             class="btn"
             :disabled="!permission_granted || button_disabled"
             v-for="(button,index) in buttons"
             style="margin: 5px; flex: 20%;"
             :class="classes(button)"
             :key="index"
             @click="toggle(button)">
    <span class="btn-label" v-if="$slots.label">
      <slot name="label"></slot>
    </span>
    <slot></slot>
    <span class="btn-label btn-label-right" v-if="$slots.labelRight">
      <slot name="labelRight"></slot>
    </span>
    <span>{{button.label}}</span>
    </component>
  </span>
</template>
<script>

import permissions from '@/mixins/permissions'

export default {
  name: 'n-selectable-button-list',
  mixins: [permissions],
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    type: {
      type: String,
      default: 'default'
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonList: {
      type: Array,
      default: () => []
    },
    round: Boolean,
    simple: Boolean,
    block: Boolean,
    social: Boolean,
    link: Boolean,
    icon: Boolean,
    wide: Boolean,
    size: String
  },
  watch: { 
    buttonList: function(newVal, oldVal) {
      this.buttons = _.cloneDeep(newVal);
    }
  },
  data() {
      return {
        buttons: []
      };
  },
  computed: {
    classes: function() { return (button) => {
          let btnClasses = [
            { 'btn-simple': this.simple },
            { 'btn-icon': this.icon },
            { 'btn-round': this.round },
            { 'btn-block': this.block },
            { 'btn-social': this.social },
            { 'btn-link': this.link },
            { 'btn-wd': this.wide },
            {'btn-primary': button.active}
          ];
          if (this.size) {
            btnClasses.push(`btn-${this.size}`);
          }
          return btnClasses;
        }
    },
    button_disabled() {
      return this.disabled;
    }
  },
  methods: {
    toggle(button) {
      button.active = !button.active;
      this.$emit('selectedButtons', this.buttons.filter(btn => btn.active).map(btn => btn.id));
    }
  }
};
</script>
<style>
  .flex-container {
    display: flex;
    flex-direction: row;
    text-align: center;
  }
  @media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}
</style>
