<template>
  <b-card>
    <slot name="header">
      <h5 slot="header" class="title">
        <svgicon class="icon" icon="server-configuration" width="16" height="16" />
        <span style="padding-left:1em">{{t('Server Configuration')}}</span>
      </h5>
    </slot>
    <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
      <b-row align-h="center" >
        <b-col lg="6" md="12">
          <b-row class="row-height">
            <b-col>
              <div class="form-group has-label">
                <label>{{ t('server-type') }}</label>
              </div>
              <el-select
                id="sms-server-type"
                v-model="server.type"
                class="select-primary"
                :placeholder="t('server-type')"
                @change="(event) => $emit('server-change', event, 'type')"
              >
                <el-option
                  v-for="option in $options.smsTypeOptions"
                  class="select-primary"
                  :value="option.value"
                  :label="t(option.label)"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="sms-server-host"
                v-model="server.host"
                @input="(event) => $emit('server-change', event, 'host')"
                :label="t('host')"
                name="host"
                v-validate="modelValidations.host"
                :error="getError('host')">
              </fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="sms-server-port"
                v-model="server.port"
                @input="(event) => $emit('server-change', event, 'port')"
                :label="t('port')"
                name="port"
                v-validate="modelValidations.port"
                :error="getError('port')"
                type="number"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <div class="form-group has-label no-margin-bottom">
                <label>{{ t('encrypt-communications') }}</label>
              </div>
              <el-select
                id="sms-server-encryption"
                v-model="server.encryption"
                class="select-primary"
                :placeholder="t('encrypt-communications')"
                @change="(event) => $emit('server-change', event, 'encryption')"
              >
                <el-option
                  v-for="option in $options.encryptOptions"
                  class="select-primary"
                  :value="option.value"
                  :label="t(option.label)"
                  :key="option.value">
                </el-option>
              </el-select>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="sms-server-path"
                v-model="server.path"
                @input="(event) => $emit('server-change', event, 'path')"
                :label="t('path')"
                name="path"
              >
              </fg-input>
            </b-col>
          </b-row>
          <b-row v-if="showFromField" class="row-height">
            <b-col>
              <fg-input
                id="sms-server-from"
                v-model="server.from"
                @input="(event) => $emit('server-change', event, 'from')"
                :label="t('from')"
                name="from"
                v-validate="modelValidations.from"
                :error="getError('from')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="sms-server-order"
                v-model="server.order"
                @input="(event) => $emit('server-change', event, 'order')"
                :label="t('order')"
                name="order"
                v-validate="modelValidations.order"
                :error="getError('order')"
                type="number"
              ></fg-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" md="12">
          <b-row class="row-height">
            <b-col>
              <b-form-group label-cols="4" :label="t('status')" label-for="sms-server-status">
                <n-switch
                  id="sms-server-status"
                  :value="server.status === 'on' ? true : false"
                  @input="(event) => $emit('server-change', event, 'status')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="sms-server-username"
                v-model="server.username"
                @input="(event) => $emit('server-change', event, 'username')"
                :label="t('username')"
                name="username"
                v-validate="modelValidations.username"
                :error="getError('username')"
                autocomplete="off"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="sms-server-password"
                v-model="server.password"
                @input="(event) => $emit('server-change', event, 'password')"
                :label="t('password')"
                name="password"
                type="password"
                autocomplete="new-password"
                v-validate="modelValidations.password"
                :error="getError('password')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row v-if="showAuthorizationHeaderField" class="row-height">
            <b-col>
              <fg-input
                id="sms-server-authorization-header"
                v-model="server.authorizationHeader"
                @input="(event) => $emit('server-change', event, 'authorizationHeader')"
                :label="t('authorization-header')"
                name="authorizationHeader"
                v-validate="modelValidations.authorizationHeader"
                :error="getError('authorizationHeader')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="sms-server-additional"
                v-model="server.additionalFixedParameters"
                @input="(event) => $emit('server-change', event, 'additionalFixedParameters')"
                :label="t('additional')"
                name="additional"
                v-validate="modelValidations.additionalFixedParameters"
                :error="getError('additional')"
              ></fg-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import { Select, Option } from 'element-ui';
import { Switch } from '@/components';
import CertificateSelector from "./CertificateSelector";
import bus from "./bus";
import {find, propEq} from "ramda";
import {phoneRegExp} from "../../../../util/regexConsts";

export default {
  name: 'sms-server-form',
  inject: ['$validator'],
  props: {
    server: {
      type: Object,
      default: () => {
        return {
          type: "kannel",
          status: 'off',
          host: "",
          port: "13013",
          encryption: "none",
          path: "cgi-bin/sendsms",
          order: "",
          username: "",
          password: "",
          additionalFixedParameters: "",
          mode: "GET",
          from: "",
          authorizationHeader: ""
        }
      }
    },
  },
  data () {
    return {
      modelValidations: {
        host: {
          required: true,
          max: 128,
        },
        port: {
          required: true,
          min_value: 1,
          max_value: 65535
        },
        username: {
          required: true
        },
        password: {
          required: true
        },
        from: {
          required: true,
          regex: phoneRegExp,
        },
        authorizationHeader: {
          max: 49152
        },
        additionalFixedParameters: {
          max: 1024
        },
        order: {
          min_value: 0
        }
      },
    }
  },
  encryptOptions: [
    {value: "none", label: "not-encrypted"},
    {value: "ssl", label: "SSL"},
  ],
  smsTypeOptions: [
    {value: "kannel", label: "kannel"},
    {value: "twillio", label: "twillio"},
    {value: "3rd party service", label: "3rd-party-service"}
  ],
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Switch.name]: Switch,
    [CertificateSelector.name] : CertificateSelector,
  },
  computed: {
    showAuthorizationHeaderField () {
      return this.server.type === "3rd party service";
    },
    showFromField () {
      return this.server.type === "twillio";
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    onValidate() {
      this.$validator.validateAll();
      if (this.errors.any()) {
        bus.$emit('errors-changed', this.errors.errors)
      }
    },
  },
  mounted() {
    bus.$on('validate', this.onValidate);
    this.$watch(() => this.errors.errors, (newValue, oldValue) => {
      const newErrors = newValue.filter(error => { find(propEq('field', error.field))(oldValue) === undefined });
      const oldErrors = oldValue.filter(error => { find(propEq('field', error.field))(newValue) === undefined });
      bus.$emit('errors-changed', newErrors, oldErrors);
    });
  },
  beforeDestroy() {
    bus.$emit('errors-changed', [], this.errors.errors);
    bus.$off('validate', this.onValidate);
  }
}
</script>

<style lang="scss" scoped>
  .no-margin-bottom {
    margin-bottom: 0;
  }
  .align-flex-end {
    align-items: flex-end;
  }
  .no-margin {
    margin: 0;
  }
  .refresh {
    cursor: pointer;
    margin-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .row-height {
    height: 95px;
  }

</style>

