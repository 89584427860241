<template>
  <b-card>
    <template v-slot:header>
      <h4>
        <i class="mr-2"><svgicon class="icon" icon="item-sso"/></i>
        <span>{{t('identity_provider_configuration')}}</span>
      </h4>
    </template>

    <el-form
      ref="identityProviderForm"
      :model="identityProvider.configuration"
      label-width="140px"
      label-position="top"
      style="padding-top: 30px; padding-left: 30px; padding-right: 30px;"
      :rules="formRules"
    >
    <div class="row">
      <div class="col-12 col-md-6 switch-group">
        <div class="switch-div">
          <label class="">{{t('enabled')}}</label>
          <n-switch
            class="switch"
            :value="identityProvider.enabled" @input="toggleState"
          />
        </div>
        <div class="switch-div">
          <label class="">{{t('logas in frame')}}</label>
          <n-switch
            class="switch"
            v-model="identityProvider.configuration.log_as_in_frame"  @input="toggle($event, 'log_as_in_frame')"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label-legend :label="t('identity_provider_type')" :legend="t('identity_provider_type_desc')" />
        <el-form-item class="sso-logas__identity-provider" prop="identity_provider_type">
          <el-select
            class="select-primary sso-logas__provider-type-select"
            v-model="identityProvider.identity_provider_type"  @input="toggle($event, 'identity_provider_type')"
            disabled
          >
            <el-option
              v-for="option in $options.identityProviderOptions"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div class="row">
          <div class="col-12">
            <label-legend :label="t('url_redirection')" :legend="t('url_redirection_desc')" />
            <el-form-item class="sso-logas-form-group" prop="redirect_sso_jwt_url">
              <el-input
                v-model.lazy="identityProvider.configuration.redirect_sso_jwt_url" @input="debounceToggle($event, 'redirect_sso_jwt_url')"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-12">
            <label-legend :label="t('shared_secret')" :legend="t('shared_secret_desc')" />
            <el-form-item class="sso-logas-form-group" prop="jwt_secret">
              <el-input
                type="password"
                 v-model.lazy="identityProvider.configuration.jwt_secret" @input="debounceToggle($event, 'jwt_secret')"
              ></el-input>
            </el-form-item>
          </div>
    </div>
    </el-form>
  </b-card>
   
</template>

<script>
import { Form, FormItem, Input } from "element-ui";
import { Switch, LabelLegend } from "@/components";
import { EventBus } from "./EventBus";

import _ from 'lodash';

export default {
  name: 'sso-logas',
  components: {
    [Switch.name]: Switch,
    LabelLegend,
    Form,
    FormItem,
    Input
  },
  identityProviderOptions: [
    { value: 'IdentityProviderSSO', label: 'IdentityProviderSSO' },
    { value: 'IdentityProviderDatabase', label: 'IdentityProviderDatabase' },
  ],
  props: {
    value: {
        type: Object,
        required: true
    },
  },
  data() {
    return {
      identityProvider: { 
        configuration: {
          jwt_secret: '',
          log_as_in_frame: false,
          redirect_sso_jwt_url: ''
        },
        identity_provider_type: null,
        identity_provider_id: 'IdentityProviderSSO',
        enabled: false,
        system: null
      },
      formRules: {
        redirect_sso_jwt_url: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            type: "url",
            message: this.t('url_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 1024,
            message: `${this.t('max_field')} 1024`,
            trigger: ['blur', 'change']
          }
        ],
        jwt_secret: [
          {
            required: false,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 256,
            message: `${this.t('max_field')} 256`,
            trigger: ['blur', 'change']
          },
          {
            min: 16,
            message: `${this.t('min_field')} 16`,
            trigger: ['blur', 'change']
          }
        ],
      },
    };
  },
  watch: {
      value(newValue, oldValue) {          
          if ( newValue){
              this.identityProvider = _.cloneDeep(newValue);
          }
      },
  },
  mounted () {
      EventBus.$on('resetState', this.resetState)
      if ( !!this.value){
          this.identityProvider = _.cloneDeep(this.value);          
      }
  },
  methods: {
      debounceToggle: _.debounce(function (value, name) {          
          this.identityProvider.configuration[name] = value; 
          this.$refs['identityProviderForm'].validate( isValid => {
            if ( isValid)
              this.$emit( 'input', this.identityProvider)
          });
      }, 500),
      toggle(event, name){
          this.identityProvider[name] = event
          this.$emit( 'input', this.identityProvider)
      },
      toggleState(event){
          this.$emit( 'input', { ...this.identityProvider, enabled: event })
      },
      resetState(){
          this.$emit( 'input', { ...this.identityProvider, enabled: !!!this.identityProvider.enabled })
      },
      getFormRef() {
          return this.$refs['identityProviderForm'];
      }
  }
};
</script>
<style lang="scss">
  .sso-logas-form-group {
    label {
      margin-bottom: 0em;
      padding-bottom: 0.2em !important;
    }
    input {
      border-radius: 50px;
    }
  }
  .sso-logas__identity-provider {
    label {
      margin-bottom: 0em;
      padding-bottom: 0.2em !important;
    }
    input {
      border-radius: 50px;
    }
  }
  .sso-logas__provider-type-select {
    width: 100%;
    max-width: 32em;
  }
</style>
