<template>
  <b-card class="border">
    <div class="d-flex flex-row justify-content-between">
      <div>
        <div class="title-container">
          <h5 class="title">{{ title }}</h5>
          <template v-if="popoverTitle">
            <svgicon
              original
              icon="info"
              class="icon popover-icon"
              width="20"
              height="20"
              :id="`popover-color-${title}`"
              @click="togglePopover"
            />
            <b-popover
              :show.sync="popoverShow"
              :target="`popover-color-${title}`"
              placement="bottomright"
              custom-class="popover-body"
            >
              <template #title>
                <h6 class="subtitle my-3">{{ popoverTitle }}</h6>
                <p class="popover-text">{{ popoverTextOne }}</p>
                <p class="popover-text">{{ popoverTextTwo }}</p>
              </template>
              <span class="close-popover" @click="togglePopover">{{ t('OK') }}</span>
            </b-popover>
          </template>
        </div>
        <p>{{ value }}</p>
        <p>{{ colorRGB }} </p>
        <p
          v-if="isShowResetColor"
          @click="resetColor"
          class="default-text"
        >
          {{ resetColorText }}
        </p>
        <p class="default" v-else-if="isDefaultColor">{{t("Default")}}</p>
      </div>
      <div class="picker-container">
        <b-form-input
          type="color"
          :value="value"
          :disabled="isPickerDisabled"
          @input="setColor"
          class="color-picker"
          :class="{ 'color-picker-disabled': isPickerDisabled }"
        />
        <div
          class="icon-container"
          :class="{ 'icon-container-disabled': isPickerDisabled }"
        >
          <svgicon icon="item-filteringlogconfig" />
        </div>
      </div>
    </div>
    <hr class="line" />
    <div class="bottom-container">
      <h6 class="subtitle mt-4">{{ t("Usage") }}</h6>
      <p>{{ usageDescription }}</p>
    </div>
  </b-card>
</template>

<script>
import { convertHexToRGB } from "@/util/convertColors";
import permissions from '@/mixins/permissions';

export default {
  name: 'color-card',
  mixins: [permissions],
  data() {
    return {
      popoverShow: false,
    }
  },
  props: {
    isDefaultColor: Boolean,
    popoverTitle: String,
    popoverTextOne: String,
    popoverTextTwo: String,
    resetColorText: String,
    title: String,
    value: String,
    usageDescription: String,
  },
  computed: {
    colorRGB() {
      return convertHexToRGB(this.value);
    },
    isPickerDisabled() {
      return !this.permission_granted;
    },
    isShowResetColor() {
      return !this.isDefaultColor && this.permission_granted;
    }
  },
  methods: {
    setColor(color) {
      this.$emit('input', color);
    },
    resetColor() {
      this.$emit('resetColor');
    },
    togglePopover() {
      this.popoverShow = !this.popoverShow;
    },
  },
}
</script>

<style lang="scss" scoped>
h5.title,
h6.subtitle {
  text-transform: uppercase;
  color: var(--light);
}

.title {
  font-size: 16px;
}

.subtitle {
  font-size: 14px;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

p.default-text {
  cursor: pointer;
  color: var(--primary);
  margin-top: 15px;
}

p.default {
  color: var(--light);
  margin-top: 15px;
}

.picker-container {
  position: relative;
  display: inline-block;
  margin-bottom: 25px;

  &:hover .icon-container {
    opacity: 1;
  }
  &:hover .icon-container-disabled {
    opacity: 0;
  }
}

input.color-picker {
  cursor: pointer;
  width: 140px;
  height: 140px;
  border: none;
}

input.color-picker-disabled {
  cursor: default;
  &.form-control[disabled] {
    background: transparent;
    opacity: 1;
  }
}

.icon-container {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  margin: 10px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
}

.line {
  margin: 0 -15px;
}

.bottom-container {
  min-height: 70px;
}

.popover-icon {
  cursor: pointer;
  line-height: 1.4em;
  margin-inline-start: 10px;
  margin-bottom: 15px;
}

.popover-text {
  color: inherit;
}

.close-popover {
  cursor: pointer;
  color: var(--primary);
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}

.popover-body h3 {
  margin: 0 15px;
}
</style>
