<template>
    <b-card class="card-user" >
        <div class="author" style="margin-top: 0px;">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
        </div>
        <slot name="header" >
            <h5 slot="header" class="title" style="margin-top: 25px;">{{ t('Customization')}}</h5>
        </slot>
        <form @submit.prevent="save" style="margin-top: 25px;">
            <fieldset ref="imagesForm">
                <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                    <b-row>
                        <b-col md="12" lg="6" class="row-eq-height p-2" v-if="showFooterImageSection">
                            <div>
                                <div v-if="show_footer_image">
                                    <label>{{ t('Footer image')}}</label>
                                    <picture-input
                                        :id="'footer_' + type"
                                        width="200"
                                        height="120"
                                        radius=10
                                        accept="image/*"
                                        class="mt-2"
                                        size="2"
                                        name="image_footer"
                                        removable removeButtonClass="btn btn-round btn-danger"
                                        buttonClass="btn btn-round btn-primary"
                                        :customStrings="this.localized_text"
                                        :prefill="image_footer"
                                        ref="image_footer" :z-index="1"
                                        @change="onChangeImage('image_footer', $event)"
                                        @remove="onRemoveImage('image_footer')">
                                    </picture-input>
                                </div>
                                <b-container>
                                    <b-row no-gutters>
                                        <b-col>
                                            <label>{{t('Show footer image')}}</label>
                                            <n-switch
                                                v-model="show_footer_image"
                                                class="ml-2"
                                                :visible_for_permissions="visible_for_permissions"
                                            />
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                        </b-col>
                        <b-col md="12" lg="6" class="row-eq-height p-2">
                            <div>
                            <label>{{ t('Favicon')}}</label>
                            <picture-input :id="'favicon_' + type" width="64" height="64" radius=10 accept="image/*" class="mt-1"
                                size="1" name="image_favicon" 
                                removable removeButtonClass="btn btn-round btn-danger" buttonClass="btn btn-round btn-primary"
                                :customStrings="this.localized_text"
                                :prefill="image_favicon"
                                ref="image_favicon" :z-index="1"
                                @change="onChangeImage('image_favicon', $event)" @remove="onRemoveImage('image_favicon')">
                            </picture-input>
                            </div>
                        </b-col>
                        <b-col md="12" lg="6" class="row-eq-height p-2">
                            <div>
                                <label>{{ t('Logo image')}}</label>
                                    <picture-input :id="'logo_' + type" width="150" height="95" radius=10 accept="image/*" class="mt-5"
                                        size="2" name="image_logo"
                                        removable removeButtonClass="btn btn-round btn-danger" buttonClass="btn btn-round btn-primary"
                                        :customStrings="this.localized_text"
                                        :prefill="image_logo"
                                        ref="image_logo" :z-index="1"
                                        @change="onChangeImage('image_logo', $event)" @remove="onRemoveImage('image_logo')">
                                    </picture-input>
                            </div>
                        </b-col>
                        <b-col md="12" lg="6" class="row-eq-height p-2" >
                            <div >
                            <label>{{ t('Background image')}}</label>
                            <picture-input :id="'back_' + type" width="500" height="300" radius=10 accept="image/*"
                                size="4" name="image_background"
                                removable removeButtonClass="btn btn-round btn-danger" buttonClass="btn btn-round btn-primary"
                                :customStrings="this.localized_text"
                                :prefill="image_background"
                                ref="image_background" :z-index="1"
                                @change="onChangeImage('image_background', $event)" @remove="onRemoveImage('image_background')">
                            </picture-input>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </fieldset>
        </form>
    </b-card>
</template>

<script>
import PictureInput from 'vue-picture-input'
import { Switch } from '@/components';
import permissions from '@/mixins/permissions'

export default {
    name: 'images-form',
    mixins: [permissions],
    components: {
        PictureInput,
        [Switch.name]: Switch,
    },
    props: {
        value: {
            type: Object,
        },
        type: {
            type: String,
        },
        visible_for_permissions: {
            type: Array,
            default: function () { return [] }
        },
        localized_text: {
            type: Object,
            default: function () { return {} }
        }
    },
    data() {
        return {
            customization: null,
        }
    },
    watch: {
        value(newValue, oldValue) {
            if ( newValue)
                this.customization = newValue;
        },
    },
    created() {
        this.customization = { show_footer_image: true}
    },
    mounted () {
        if ( !!this.value){
            this.customization = this.value;
        }
        this.checkFormPermission();
    },
    computed: {
        image_background: {
            get(){
                if (this.$refs.image_background && this.$store.getters['settings/getLanguage']) {
                    this.$refs.image_background.updateStrings();
                }
                return this.imageembedded(this.customization.image_background)
            }
        },
        image_logo: {
            get(){
                if (this.$refs.image_logo && this.$store.getters['settings/getLanguage']) {
                    this.$refs.image_logo.updateStrings();
                }
                return this.imageembedded(this.customization.image_logo)
            }
        },
        image_footer: {
            get(){
                if (this.$refs.image_footer && this.$store.getters['settings/getLanguage']) {
                    this.$refs.image_footer.updateStrings();
                }
                return this.imageembedded(this.customization.image_footer)
            }
        },
        image_favicon: {
            get(){
                if (this.$refs.image_favicon && this.$store.getters['settings/getLanguage']) {
                    this.$refs.image_favicon.updateStrings();
                }
                return this.imageembedded(this.customization.image_favicon)
            }
        },
        show_footer_image: {
            get() {
                try{
                    return this.customization.show_footer_image
                } catch(err){
                    return true
                }
            },
            set(newValue){
                this.customization.show_footer_image = newValue
                this.$emit('input', this.customization)
            }
        },
        showFooterImageSection() {
            return ['isp', 'enterprise'].includes(this.type);
        }
    },
    methods: {
        imageembedded(picture_base64) {
            if ( !picture_base64)
                return ''
            else
                return 'data:image/png;base64,' + picture_base64
        },
        onChangeImage(image, event){
            if (this.permission_granted) {
                this.$emit('update', {image: image, data: event.split(';base64,')[1]})
            }
        },
        onRemoveImage(image){
            if (this.permission_granted) {
                this.$emit('reset', image)
            }
        },
        checkFormPermission() {
            if (!this.permission_granted && this.$refs.imagesForm) {
                this.$refs.imagesForm.disabled = true;
            }
        }
    }
};

</script>
