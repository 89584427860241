<template>
  <b-card>
    <template v-slot:header>
      <h4 id="session_control_part">
        <i class="mr-2"><svgicon class="icon" icon="item-passwordpolicy"/></i>
        <span>{{t('session_control')}}</span>
      </h4>
    </template>

    <el-form
      :ref="`form-${guiStore}`"
      :model="form"
      label-width="140px"
      label-position="top"
      style="padding-top: 30px;"
      :rules="formRules"
    >
      <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
        <b-row>
          <b-col class="col-6">
            <el-form-item class="session-control" :label="t('Number of multiple sessions allowed') + ':'" prop="maxSessions">
              <el-input
                id="sessionControl_maxSessions"
                type="number"
                v-model.number="form.maxSessions"
              ></el-input>
            </el-form-item>
          </b-col>
          <b-col class="col-6">
            <el-form-item class="session-control" :label="t('Session expiration time (in minutes)') + ':'" prop="expirationTime">
              <el-input
                id="sessionControl_expirationTime"
                type="number"
                v-model.number="form.expirationTime"
              ></el-input>
            </el-form-item>
          </b-col>
        </b-row>
         <el-form-item class="session-control" :label="t('Incorrect password attempts allowed') + ':'" prop="maxIncorrectAttempts">
            <el-input
              id="sessionControl_maxIncorrectAttempts"
              type="number"
              v-model.number="form.maxIncorrectAttempts"
            />
        </el-form-item>
        <el-form-item class="session-control" :label="t('Lock time period (in seconds)') + ':'" prop="lockTime">
          <el-input
            id="sessionControl_lockTime"
            type="number"
            v-model.number="form.lockTime"
          />
        </el-form-item>
        <b-row>
          <b-col lg="2" md="3" sm="12" class="ml-auto mt-2">
            <n-button id="sessionControl_save"
                      class="ml-auto"
                      size="md"
                      style
                      type="primary" round block @click.native="onSubmit"
                      :visible_for_permissions='[{actions: ["POST"], path: "/identity/session/configuration/.*"}]'>{{t('save')}}</n-button>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </b-card>
   
</template>

<script>
import { Form, FormItem, Input } from "element-ui";

export default {
  name: 'session-control',
  components: {
    Form,
    FormItem,
    Input
  },
  props: {
    guiStore: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        maxSessions: 0,
        expirationTime: 0,
        maxIncorrectAttempts: 0,
        lockTime: 0
      },
      validations: {
        required: {
          required: true,
          message: this.t('required_field'),
          trigger: ['blur', 'change']
        },
        numeric: {
          type: 'number',
          message: this.t('numeric_field'),
          trigger: ['blur', 'change']
        },
        min2: {
          type: 'number',
          min: 2,
          message: this.t('min_value_field') + 2,
          trigger: ['blur', 'change']
        },
        max100: {
          type: 'number',
          max: 100,
          message: this.t('max_value_field') + 100,
          trigger: ['blur', 'change']
        }
      }
    };
  },
  computed: {
    formRules() {
      return {
        maxSessions: [
          this.validations.required,
          this.validations.numeric,
          this.validations.max100
        ],
        expirationTime: [
          this.validations.required,
          this.validations.numeric,
          this.validations.min2
        ],
        maxIncorrectAttempts: [
          this.validations.required,
          this.validations.numeric,
          this.validations.max100
        ],
        lockTime: [
          this.validations.required,
          this.validations.numeric
        ]
      }
    }
  },
  methods: {
    async getSession() {
      await this.$store.dispatch('sessions/getSessionsManagement', this.guiStore);
      this.form = _.cloneDeep(this.$store.getters[`sessions/${this.guiStore}PasswordPolicy`]);
    },
    async onSubmit() {
      this.$refs[`form-${this.guiStore}`].validate(
        async (valid, errors) => {
          this.$store.commit('settings/startLoading', true);
          if(valid) {
            await this.$store.dispatch('sessions/setSessionsManagement', {
              type: this.guiStore,
              payload: {
                maxSessions: parseInt(this.form.maxSessions,10),
                expirationTime: parseInt(this.form.expirationTime,10) * 60,
                maxIncorrectAttempts: parseInt(this.form.maxIncorrectAttempts,10),
                lockTime: parseInt(this.form.lockTime,10)
              }
            });

            if (this.$store.getters['error']) {
              this.$store.commit('settings/finishLoading', true);
              return;
            }

            setTimeout(() => {
              this.$store.commit('settings/finishLoading', true);
              this.$notify({
                message: this.t('form_save'),
                type: 'success',
                verticalAlign: 'bottom',
              });
            }, 1000);
          } else {
            this.$store.commit('settings/finishLoading', true);
            this.$store.commit('setError', this.t('errors_in_form'));
          }
        }
      );
    }
  },
  created() {
    this.getSession();
  }
};
</script>
<style lang="scss">
  .session-control {
    label {
      margin-bottom: 0em;
      padding-bottom: 0.2em !important;
    }
    input {
      border-radius: 50px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>    