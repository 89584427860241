<template>
  <div class="typography-tab">
    <p class="mb-4">{{ t("description") }}</p>
    <b-row>
      <b-col>
        <h5 class="title">{{t('Configuration')}}</h5>
        <h5 class="subtitle">{{ t("Headlines") }}</h5>
        <p>{{ t("Choose a font") }}</p>
        <div class="mb-2">
          <custom-dropdown
            :defaultValue="defaultHeadlineFont"
            :dropdownItems="headlinesDropdownList"
            :selectedValue="createDropdownItem(selectedHeadlineFont)"
            :visible_for_permissions="visible_for_permissions"
            :isShowSearch="true"
            @selectValue="onSelectHeadlineFont"
          />
        </div>
        <languages-message
          v-if="headlinesNotSupportFonts.length > 0"
          :languagesList="headlinesNotSupportFonts"
          :fontFamily="selectedHeadlineFont.font_family"
          :fallbackFont="fallbackFont"
        />
        <hr class="left-line" />
        <h5 class="subtitle">{{ t("Subheads & Body") }}</h5>
        <p>{{ t("Choose a font") }}</p>
        <div class="mb-2">
          <custom-dropdown
            :defaultValue="defaultBodyFont"
            :dropdownItems="bodyDropdownList"
            :selectedValue="createDropdownItem(selectedBodyFont)"
            :visible_for_permissions="visible_for_permissions"
            :isShowSearch="true"
            @selectValue="onSelectBodyFont"
          />
        </div>
        <languages-message
          v-if="bodyNotSupportFonts.length > 0"
          :languagesList="bodyNotSupportFonts"
          :fontFamily="selectedBodyFont.font_family"
          :fallbackFont="fallbackFont"
        />
        <hr class="left-line" />
        <h5 class="title">{{t("Upload custom font")}}</h5>
        <p class="mb-4">{{t("Upload custom font file")}}:</p>
        <div class="d-flex align-items-center">
          <upload-font
            class="upload-font-button"
            :text="t('Upload font')"
            :visible_for_permissions="visible_for_permissions"
            v-model="loadedFile"
          />
          <div class="vertical-line"></div>
          <div class="upload-text">{{t("For maximum browser support upload TTF and OTF")}}</div>
        </div>
        <div v-if="loadedFileName">
          <div class="grey-text text-uppercase mt-4 mb-2">{{t("Uploaded fonts")}}</div>
          <p>{{t("Uploaded fonts will be available")}}</p>
          <p>{{loadedFileName}}</p>
        </div>
      </b-col>
      <b-col>
        <preview-fonts-card
          :bodyFontFamily="bodyFontFamily"
          :selectedBodyFont="selectedBodyFont.font_family"
          :headlinesFontFamily="headlinesFontFamily"
          :selectedHeadlineFont="selectedHeadlineFont.font_family"
        />
      </b-col>
    </b-row>
    <b-col xl="3" md="4" xs="12" sm="6" class="ml-auto mt-5">
      <n-button
        block
        round
        type="primary"
        class="text-uppercase"
        :disabled="isSaveDisabled"
        :visible_for_permissions="visible_for_permissions"
        @click.native="saveChanges"
      >
        {{ t("Save fonts") }}
      </n-button>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { btoa } from 'js-base64';

import Dropdown from "@/components/Inputs/Dropdown";
import PreviewFontsCard from "./PreviewFontsCard";
import LanguagesMessage from "./LanguagesMessage";
import UploadFont from "./UploadFont";
import permissions from '@/mixins/permissions';

export default {
  name: 'typography-tab',
  mixins: [permissions],
  components: {
    [Dropdown.name]: Dropdown,
    [LanguagesMessage.name]: LanguagesMessage,
    [PreviewFontsCard.name]: PreviewFontsCard,
    [UploadFont.name]: UploadFont,
  },
  data() {
    return {
      loadedFile: null,
      loadedFileName: "",
      selectedHeadlineFont: {},
      selectedBodyFont: {},
      fallbackFont: "Montserrat, Helvetica, Arial, sans-serif",
    }
  },
  computed: {
    ...mapGetters('generalManagement/customization', ['accLanguages', 'fonts', 'defaultFonts', 'selectedFonts']),
    languagesList() {
      return this.accLanguages.reduce((acc, language) => {
        if (language.enabled) {
          acc.push(language.language_id)
        }

        return acc
      }, []);
    },
    isSaveDisabled() {
      return false;
    },
    headlinesFontFamily() {
      if (this.selectedHeadlineFont.font_family) {
        return `${this.selectedHeadlineFont.font_family}, ${this.fallbackFont}`;
      }
    },
    bodyFontFamily() {
      if (this.selectedBodyFont.font_family) {
        return `${this.selectedBodyFont.font_family}, ${this.fallbackFont}`;
      }
    },
    headlinesNotSupportFonts() {
      return this.checkNotSupportLanguages(this.selectedHeadlineFont)
    },
    bodyNotSupportFonts() {
      return this.checkNotSupportLanguages(this.selectedBodyFont)
    },
    defaultHeadlineFont() {
      if (this.defaultFonts) {
        return this.createDropdownItem(this.defaultFonts.headlines)
      }

      return {
        label: "AmpleSoft-Pro-Regular",
        value: "AmpleSoft-Pro-Regular",
      }
    },
    defaultBodyFont() {
      if (this.defaultFonts) {
        return this.createDropdownItem(this.defaultFonts.body)
      }

      return {
        label: "Museo-Sans-400",
        value: "Museo-Sans-400",
      }
    },
    headlinesDropdownList() {
      return this.fonts.reduce((acc, font) => {
        if(!this.defaultFonts || font.font_id !== this.defaultFonts.headlines.font_id) {
          acc.push(this.createDropdownItem(font));
        }
        return acc;
      }, []);
    },
    bodyDropdownList() {
      return this.fonts.reduce((acc, font) => {
        if(!this.defaultFonts || font.font_id !== this.defaultFonts.body.font_id) {
          acc.push(this.createDropdownItem(font));
        }
        return acc;
      }, []);
    },
  },
  watch: {
    loadedFile(newValue) {
      this.getBase64(newValue).then((data) => {
        this.$store
          .dispatch("generalManagement/customization/uploadFont", data)
          .then(() => {
            this.$store.dispatch("generalManagement/customization/getFontsList");
            this.$store.commit("setSuccess", this.t("save_success"));
            this.loadedFileName = newValue.name
          });
      });
    },
  },
  methods: {
    createDropdownItem(font) {
      return {
        label: font.font_family,
        value: font.font_id,
      }
    },
    checkNotSupportLanguages(selectedFont) {
      const font = this.fonts.find((font) => {
        return font.font_id === selectedFont.font_id
      })

      if (font) {
        return this.languagesList.reduce((acc, language) => {
          if (!font.supported_languages.includes(language)) {
            acc.push(language)
          }

          return acc
        }, [])
      }

      return []
    },
    getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = () => {
            resolve(btoa(reader.result));
          }
          reader.onerror = error => reject(error);
        });
    },
    saveChanges() {
      const data = {
        fonts: {
          headlines: {
            font_id: this.selectedHeadlineFont.font_id,
          },
          body: {
            font_id: this.selectedBodyFont.font_id,
          },
        }
      };

      this.$store
        .dispatch("generalManagement/customization/updateCustomization", {
          id: "acc",
          data: { env_options: data },
        })
        .then(() => {
          this.$store.commit("setSuccess", this.t("save_success"));
        });
    },
    onSelectBodyFont(newFont) {
      this.selectedBodyFont = this.formatFontObject(newFont);
    },
    onSelectHeadlineFont(newFont) {
      this.selectedHeadlineFont = this.formatFontObject(newFont);
    },
    formatFontObject(newFont) {
      const selectedFont = {
        font_id: newFont.value,
        font_family: newFont.label,
      };

      this.getFontFile(selectedFont);

      return selectedFont;
    },
    getFontFile(newFont) {
      const isFontAlreadyUploaded = document.fonts.check(`12px ${newFont.font_family}`);

      if (!isFontAlreadyUploaded) {
        this.$store.dispatch("generalManagement/customization/getFont", newFont);
      }
    }
  },
  mounted() {
    this.$store.dispatch("generalManagement/customization/getFontsList").then(() => {
      this.selectedBodyFont = this.selectedFonts.body;
      this.selectedHeadlineFont = this.selectedFonts.headlines;
      this.$store.dispatch("generalManagement/customization/getFont", this.selectedFonts.body)
      this.$store.dispatch("generalManagement/customization/getFont", this.selectedFonts.headlines)
    });
  }
}
</script>

<style lang="scss">
.typography-tab {
  .upload-font-button {
    min-width: 160px;
  }

  h5.title {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    color: #9BA2AF;
  }

  h5.subtitle {
    margin-top: 35px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    color: #B3B8C1;
  }

  .left-line {
    margin: 20px 0;
  }

  .vertical-line {
    border-left: 1px solid #DADDE2;
    height: 40px;
    margin-inline-end: 20px;
  }

  .select-new {
    input {
      background: transparent;
      border: 1px solid var(--light);
      color: inherit;
    }

    i {
      color: black !important;
    }
  }

  .grey-text {
    color: #B3B8C1;
  }

  .upload-text {
    color: #344675;
  }
}
</style>
