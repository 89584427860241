<template>
  <b-card class="border">
    <h5 class="title mt-1">{{ t('Preview') }}</h5>
    <hr class="line" />
    <b-row class="mt-4">
      <b-col cols="8" class="preview-title" :style="{fontFamily: headlinesFontFamily}">
        {{t("Headlines")}}
      </b-col>
      <b-col class="grey-text">
        {{ selectedHeadlineFont }}, {{t('Bold')}}
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="8" class="preview-subtitle" :style="{fontFamily: bodyFontFamily}">
        {{t("Subheads")}}
      </b-col>
      <b-col class="grey-text">
        {{ selectedBodyFont }}, {{t('Regular')}}
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="8" class="preview-text" :style="{fontFamily: bodyFontFamily}">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. <span class="font-weight-bold">Aenean</span> eget metus elit.
      </b-col>
      <b-col class="grey-text">
        {{ selectedBodyFont }}, {{t('Regular')}}
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "preview-fonts-card",
  props: {
    bodyFontFamily: String,
    selectedBodyFont: String,
    headlinesFontFamily: String,
    selectedHeadlineFont: String,
  },
}
</script>

<style lang="scss" scoped>
.line {
  margin: 0 -15px;
}

.left-line {
  margin: 20px 0;
}

div.preview-title {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #303030;
}

div.preview-subtitle {
  font-size: 16px;
  text-transform: uppercase;
  color: #B3B8C1;
  font-weight: 400;
}

div.preview-text {
  font-size: 16px;
  color: #303030;
  font-weight: 400;
}
</style>
