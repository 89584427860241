<template>
    <b-card class="card-user" >
        <div class="author" style="margin-top: 0px;">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
        </div>
        <slot name="header" >
            <h5 slot="header" class="title" style="margin-top: 25px;">{{ t('Customization')}}</h5>
        </slot>

        <form @submit.prevent="save" style="margin-top: 25px;">
            <b-container fuild style="width: 100%; padding: 0px;" class="form-group">

                <b-row align-h="center" >
                    <b-col xl="6" lg="12" >
                        <fg-input v-model="customization.app_name"
                                    id="app_name"
                                    :label="t('Name of the solution')"
                                    name="app_name"
                                    :placeholder="t('Name of the solution')"
                                    autocapitalize="yes"
                                    v-validate="'max:16'"
                                    :visible_for_permissions="visible_for_permissions"
                                    :error="getError('app_name')">
                        </fg-input>
                        <!--<label>{{ t('Name of the solution use')}}</label>-->
                    </b-col>

                    <b-col xl="6" lg="12" >
                        <fg-input v-model="customization.company_name"
                                    id="company_name"
                                    :label="t('Company name')"
                                    name="company_name"
                                    :placeholder="t('name')"
                                    autocapitalize="yes"
                                    v-validate="'max:32'"
                                    :visible_for_permissions="visible_for_permissions"
                                    :error="getError('company_name')">
                        </fg-input>
                    </b-col>

                    <b-col xl="12">
                        <fg-input v-model="customization.company_url"
                                    id="company_url"
                                    :label="t('Company webpage')"
                                    name="company_url"
                                    :placeholder="t('Url')"
                                    autocapitalize="yes"
                                    v-validate="{ required: true, url: { require_protocol: true }, max: 1024 }"
                                    :visible_for_permissions="visible_for_permissions"
                                    :error="getError('company_url')">
                        </fg-input>
                    </b-col>


                </b-row>
                <b-row>
                    <b-col xl="8" >
                        <label>{{t('Show footer text')}}</label>
                        <n-switch 
                            v-model="customization.show_footer_text" 
                            class="ml-3" 
                            :visible_for_permissions="visible_for_permissions"
                        />
                    </b-col>
                    <b-col xl="3" md="4" xs="12" sm="6" class="ml-auto">
                        <n-button native-type="submit" type="primary" id="btn_submit" round block :visible_for_permissions="visible_for_permissions">
                            <template>
                                {{ t('Save') }}
                            </template>
                        </n-button>
                    </b-col>
                    <b-col xl="8" v-if="isAcc">
                        <label>{{t('Show add user button')}}</label>
                        <n-switch
                            v-model="customization.env_options.visibility.acc_show_add_users_button"
                            class="ml-3"
                            :visible_for_permissions="visible_for_permissions"
                        />
                    </b-col>    
                </b-row>

            </b-container>
        </form>

        <template v-slot:footer>
            <div class="hr-grey mb-2"/>
            <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
                <i class="now-ui-icons arrows-1_refresh-69" />
                {{ t('Update now') }}
            </div>
            <div v-else>
                <i class="now-ui-icons loader_refresh spin"/>
                {{ t('Updating...') }}
            </div>
        </template>

    </b-card>
</template>

<script>
import { Switch } from '@/components';

export default {
    name: 'texts-form',
    components: {
        [Switch.name]: Switch,
    },
    props: {
        isAcc: {
            type: Boolean,
        },
        value: { 
            type: Object,  
        },
        visible_for_permissions: { 
            type: Array, 
            default: function () { return [] }
        }
    },
    data() {
        return {
            customization: null,
            updating: false,
        }
    },
    watch: {
        value(newValue, oldValue) {
            if ( newValue){
                this.customization = newValue;
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)
            }
        },
    },
    created() {
        this.customization = { 
            app_name: 'IoTSecure', 
            company_name: 'Company Name', 
            company_url: 'http://www.companyname.com', 
            show_footer_text: true, 
            env_options: {
                visibility: { 
                    acc_show_add_users_button: true
                }
            }
        }
    },
    mounted () {
        if ( !!this.value){
            this.customization = this.value;
        }
    },
    methods: {
        refresh(wait){
            this.updating = true;
            this.$emit('refresh')
            setTimeout( () => {
			    this.updating = false
		    }, 2000);
        },
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async save(){
            let isValid = await this.$validator.validateAll();
            if (isValid){
                this.$emit('input', this.customization)
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.prefix {
  margin-top: 5px;
  margin-left: 10px;
}
.form-group .el-input__inner{
    padding-left: 46px;
}
.icon{
    font-size: 1.5rem;
    color: #333333;
}
</style>
