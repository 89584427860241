<template>
    <div class="selector">
        <slot name='header'>
            <div class="form-group has-label no-margin-bottom">
                <label>{{ t(label) }}</label>
            </div>
        </slot>

        <form ref="certificateForm">
            <input type="file" ref="certificateFile" accept=".crt, .cer, .cert, .pem"
                        tabindex="-1" class="file hidden " @change="loadfile"/>


            <fg-input class="show"
                    v-model="first"
                    :placeholder="t('import from file')"
                    noTab
                    name="certificate"/>

            <n-button :id="$options.name + '-remove'" @click.native="clear" type="danger" size="sm" round icon class="ml-1 mt-1">
                    <i class="now-ui-icons ui-1_simple-remove"></i>
            </n-button>
        </form>
    </div>
</template>

<script>
var CryptoJS = require("crypto-js");

export default {
    name: 'certificate-selector',
    props: {
        value: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        }
    },
    mimetypes: ['application/x-x509-ca-cert',
                    'application/pkix-cert'
    ],
    data() {
        return {
            raw_certificate: null,
            certificates: []
        }
    },
    computed: {
        first(){
            if (this.certificates && this.certificates.length > 0)
                return this.certificates[0]
            else
                return null
        }
    },
    watch: {
        value(newValue, oldValue) {
            if ( !!newValue){
                if ( this.update(this.value))
                    this.certificates.forEach ( cert => {
                        console.log("Loaded: " + cert.substr(0, 16) + "...")
                    });
                else
                    this.$store.commit('setError', this.t('Error retrieving certificates'))
            }
        },
    },
    mounted () {
        if ( !!this.value){
            if ( this.update(this.value))
                this.certificates.forEach ( cert => {
                    console.log("Loaded: " + cert.substr(0, 16) + "...")
                });
            else
                this.$store.commit('setError', this.t('Error retrieving certificates'))
        }
    },
    methods: {
        loadfile(){
            let file = this.$refs.certificateFile.files[0];
            if ( !file)
                return;
            if ( !!file.type && !this.$options.mimetypes.includes(file.type))
                this.$store.commit('setError', this.t('Unknown file type'))
            else{
                let reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload =  evt => {
                    if ( evt.target.result.length > 8192)
                        this.$store.commit('setError', this.t('File exceeds maximum size'))
                    else{
                        if ( this.update(evt.target.result))
                            this.$emit('input', this.raw_certificate)
                        else
                            this.$store.commit('setError', this.t('File contains no certificates'))
                    }
                }
                reader.onerror = evt => {
                    console.log(evt)
                    this.$store.commit('setError', this.t('File contains no certificates'))
                }
            }
        },
        extract(data){
            try{
                let certs = []
                data.split('-----END CERTIFICATE-----').forEach( cert =>  {
                    cert = cert.split('-----BEGIN CERTIFICATE-----')[1]
                    if ( cert && cert.length > 0){
                        if ( cert[0] == '\r')
                            cert = cert.substr(1)
                        if ( cert[0] == '\n')
                            cert = cert.substr(1)
                        if( cert.slice(-1) == '\r')
                            cert = cert.slice(0, -1)
                        if( cert.slice(-1) == '\n')
                            cert = cert.slice(0, -1)

                        let parsedWordArray = CryptoJS.enc.Base64.parse(cert);
                        let parsedStr = parsedWordArray.toString();

                        certs.push(cert)
                    }
                })

                return certs;
            }
            catch(err){
                console.log(err)
                return null;
            }
        },
        update(value){
            let cert = this.extract(value)
            if ( cert && cert.length > 0){
                this.raw_certificate = value
                this.certificates = cert
                return true
            }
            else
                return false
        },
        clear(){
            this.$refs.certificateForm.reset()
            this.raw_certificate = null
            this.certificates = []
            this.$emit('input', this.raw_certificate)
        }
    }
}
</script>

<style lang="scss" scoped>
.selector{
    height: 67px;

    .no-margin-bottom {
        margin-bottom: 0;
    }
    form {
        position: relative;
        display: flex;

        input.file.hidden{
            position: relative;
            opacity: 0;
            z-index: 2;
            width: 85%;
            height: calc(2.25rem + 2px);
        }

        .show{
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
            width: 85%;
        }
    }
}
</style>