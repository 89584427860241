<template>
  <div>
    <Modal :show="showModal" :maxWidth="maxWidth" :showClose="false">
      <template v-slot:header>
        <h4 class="mx-0 basic-modal__title">
          {{ t(txt) }}
        </h4>
      </template>
      <template v-slot:footer>
        <div v-if="showSecBtn" @click="onSecBtn">
          <n-button size="md" block v-text="t(secBtnTxt)" />
        </div>
        <div v-else></div>
        <div @click="onFirstBtn">
          <n-button type="primary" size="md" block v-text="t(firstBtnTxt)" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "BasicModal",
  components: {
    Modal,
  },
  props: {
    txt: {
      type: String,
      default: () => "Are you sure want to save?",
    },
    showModal: {
      type: Boolean,
    },
    firstBtnTxt: {
      type: String,
      default: "accept",
    },
    secBtnTxt: {
      type: String,
      default: "cancel",
    },
    showSecBtn: {
      type: Boolean,
      default: () => true,
    },
    maxWidth: {
      type: Number,
      default: () => 385,
    },
  },
  mixins: [],
  data() {
    return {};
  },
  created() {},
  methods: {
    onSecBtn() {
      this.$emit("onSecBtn");
    },
    onFirstBtn() {
      this.$emit("onFirstBtn");
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss">
  .basic-modal__title {
    margin: 0 auto;
    font-size: 0.875rem;
    line-height: 1.9;
    color: #525f7f !important;
  }
</style>
