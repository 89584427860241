export const convertHexToRGB = (color) => {
    if (color.length === 4) {
      let first = color.substring(1, 2);
      let second = color.substring(2, 3);
      let third = color.substring(3, 4);
      color = first + first + second + second + third + third;
    }

    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);

    return `rgb(${r}, ${g}, ${b})`;
}

export const createShadeColor = (color, amount) => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}