<template>
  <b-card class="card-user card" >
    <div slot="header">
      <h5 class="title">
        <i class="tim-icons icon-palette mr-2" />
        {{ t("UI Customization") }}
      </h5>
    </div>
    <tabs
      centered
      id="custom-ui-tabs"
      ref="custom-ui-tabs"
      type="primary"
      v-model="active_tab"
      :isTextTabNav="true"
    >
      <tab-pane id="Colors" :label="this.t('Colors')">
        <colors-tab
          v-if="active_tab === 'Colors'"
          :visible_for_permissions="visibleForPermissions"
        />
      </tab-pane>
      <tab-pane id="Typography" :label="this.t('Fonts')">
        <typography-tab
          v-if="active_tab === 'Typography'"
          :visible_for_permissions="visibleForPermissions"
        />
      </tab-pane>
      <tab-pane id="Navigation" :label="this.t('Navigation')">
        <navigation-tab
          v-if="active_tab === 'Navigation'"
          :visible_for_permissions="visibleForPermissions"
        />
      </tab-pane>
    </tabs>
  </b-card>
</template>

<script>
import { TabPane, Tabs } from "@/components";
import Colors from "./Tabs/Colors";
import Navigation from "./Tabs/Navigation";
import Typography from "./Tabs/Typography";

export default {
  name: "ui-customization",
  components: {
    [Colors.name]: Colors,
    [Navigation.name]: Navigation,
    [Typography.name]: Typography,
    TabPane,
    Tabs,
  },
  data() {
    return {
      active_tab: 'Colors',
      visibleForPermissions: [
        { actions: ['PATCH', 'PUT'], path: '/customization/.*' },
      ],
    }
  },
  mounted() {
    this.$store.dispatch("generalManagement/customization/getAccDefaultCustomization");
  }
}
</script>

<style lang="scss" scoped>
.card {
  overflow: visible;
}
</style>
