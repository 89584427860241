var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title-card',{attrs:{"page-title":_vm.t('title'),"page-description":_vm.t('router_jobs_text')}}),_c('b-card',{staticClass:"card-user",attrs:{"no-body":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"hr-grey mb-2"}),(!_vm.updating)?_c('div',{staticStyle:{"cursor":"pointer","display":"inline"},on:{"click":_vm.refresh}},[_c('i',{staticClass:"now-ui-icons arrows-1_refresh-69"}),_vm._v(" "+_vm._s(_vm.t('Update now'))+" ")]):_c('div',[_c('i',{staticClass:"now-ui-icons loader_refresh spin"}),_vm._v(" "+_vm._s(_vm.t('Updating...'))+" ")])]},proxy:true}])},[_c('b-card-body',{staticStyle:{"padding":"0px"}},[_c('paginated-table',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"actions":_vm.actions,"updating":_vm.updating,"search":[
                                      {value: 'job_id', key: 'job_id', label: _vm.t('job_id')},
                                      {
                                          value: 'status', key: 'status', label: _vm.t('status'),
                                          isExtended:true, extendedOptions: _vm.statusOptions, hideTextInput: true
                                      },
                                      {
                                          value: 'action', key: 'action', label: _vm.t('action'),
                                          isExtended:true, extendedOptions: _vm.actionOptions, hideTextInput: true
                                      }
                                  ],"itemsPerPage":_vm.query.limit,"serverpagination":_vm.search_pagination},on:{"pagination":_vm.HandlePagination,"prev":_vm.handlePrevPage,"next":_vm.handleNextPage,"item-stop":_vm.handleStop},scopedSlots:_vm._u([{key:"expand",fn:function(props){return [_c('b-row',[_c('b-col',{attrs:{"cols":"1"}}),_c('b-col',{attrs:{"cols":"10"}},[_c('h5',[_vm._v(_vm._s(_vm.t('Logs'))+":")]),_vm._l((props.row.logs),function(log){return _c('b-row',{key:log},[_c('b-col',{attrs:{"cols":"8"}},[_c('span',[_vm._v(_vm._s(_vm.toLocaleString(_vm.datetimeFromISO(log.substring(0,log.indexOf(' - '))))))]),_c('span',{staticClass:"log-message"},[_vm._v(_vm._s(log.substring(log.indexOf(' - ') + 3)))])])],1)})],2)],1)]}}]),model:{value:(_vm.search_field_value),callback:function ($$v) {_vm.search_field_value=$$v},expression:"search_field_value"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }