<template>
  <b-card>
    <template v-slot:header>
      <h4>
        <i class="mr-2">
          <svgicon class="icon" icon="item-sso" />
        </i>
        <span>{{ t("identity_provider_configuration") }}</span>
      </h4>
    </template>

    <el-form
      ref="identityProviderForm"
      :model="identityProvider"
      label-width="140px"
      label-position="top"
      style="padding-top: 30px; padding-left: 30px; padding-right: 30px;"
    >
      <div class="row">
        <div class="col-12 col-sm-7 switch-group">
          <label class="">{{t('enabled')}}</label>
          <n-switch
            class="switch"
            :value="identityProvider.enabled" @input="toggleState"
          />
        </div>
        <div class="col-12 col-sm-5">
          <label class="">{{t('identity_provider_type')}}</label>
          <el-form-item class="identity-provider" prop="identity_provider_type">
            <el-select
              class="select-primary"
              v-model="identityProvider.identity_provider_type"  @input="toggle($event, 'identity_provider_type')"
              disabled
            >
              <el-option
                v-for="option in $options.identityProviderOptions"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <fg-input
          :label="t('Password history check:')"
          name="entHistoryCheck"
          ref="entHistoryCheck"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('entHistoryCheck')"
          v-model.lazy="identityProvider.password_rotation.history_check_depth"
          @input="handleNumericInput($event, 'password_rotation', 'history_check_depth')"
          @keypress="onKeyPress"
        ></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <fg-input
          :label="t('Minimum length:')"
          name="entMinLength"
          ref="entMinLength"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('entMinLength')"
          v-model.lazy="identityProvider.password_complexity.minimum_password_length"
          @input="handleNumericInput($event, 'password_complexity', 'minimum_password_length')"
          @keypress="onKeyPress"
        ></fg-input>
        <fg-input
          :label="t('Minimum lowercase letters:')"
          name="entMinLowercase"
          ref="entMinLowercase"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('entMinLowercase')"
          v-model.lazy="identityProvider.password_complexity.minimum_lowercase_required"
          @input="handleNumericInput($event, 'password_complexity', 'minimum_lowercase_required')"
          @keypress="onKeyPress"
        ></fg-input>
        <fg-input
          :label="t('Minimum uppercase letters:')"
          name="entMinUppercase"
          ref="entMinUppercase"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('entMinUppercase')"
          v-model.lazy="identityProvider.password_complexity.minimum_uppercase_required"
          @input="handleNumericInput($event, 'password_complexity', 'minimum_uppercase_required')"
          @keypress="onKeyPress"
        ></fg-input>
        <b-row>
          <b-col cols="8" class="form-group has-label pt-2">
            <legend class="bv-no-focus-ring col-form-label switch-label">
              {{ t("Password must change on first login") }}
            </legend>
          </b-col>
          <b-col align-self="center">
            <n-switch id="inpu-enable" v-model="identityProvider.password_rotation.rotate_on_first_login"
                                          @input="toggle($event, 'password_rotation', 'rotate_on_first_login')"/>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8" class="form-group has-label pt-2">
            <legend class="bv-no-focus-ring col-form-label switch-label">
              {{ t("Check sequential numbers") }}
            </legend>
          </b-col>
          <b-col align-self="center">
            <n-switch
              id="inpu-enable"
              v-model="identityProvider.password_complexity.check_sequential_numbers"
                   @input="toggle($event, 'password_complexity', 'check_sequential_numbers')"
            />
          </b-col>
        </b-row>
      </div>
      <div class="col-12 col-md-4">
        <fg-input
          :label="t('Minimum symbol characters:')"
          name="entMinSymbol"
          ref="entMinSymbol"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('entMinSymbol')"
          v-model.lazy="identityProvider.password_complexity.minimum_symbols_required"
          @input="handleNumericInput($event, 'password_complexity', 'minimum_symbols_required')"
          @keypress="onKeyPress"
        ></fg-input>
        <fg-input
          :label="t('Minimum number of numeric characters in the middle:')"
          name="entMinNumCharsInMiddle"
          ref="entMinNumCharsInMiddle"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('entMinNumCharsInMiddle')"
          v-model.lazy="identityProvider.password_complexity.minimum_middle_number_digits_required"
          @input="handleNumericInput($event, 'password_complexity', 'minimum_middle_number_digits_required')"
          @keypress="onKeyPress"
        ></fg-input>
        <fg-input
          :label="t('Minimum number of symbol characters in the middle:')"
          name="entMinSymbolCharsInMiddle"
          ref="entMinSymbolCharsInMiddle"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('entMinSymbolCharsInMiddle')"
          v-model.lazy="identityProvider.password_complexity.minimum_middle_symbols_required"
          @input="handleNumericInput($event, 'password_complexity', 'minimum_middle_symbols_required')"
          @keypress="onKeyPress"
        ></fg-input>
        <b-row>
          <b-col cols="8" class="form-group has-label pt-2">
            <legend class="bv-no-focus-ring col-form-label switch-label">
              {{ t("Check keyboard pattern used") }}
            </legend>
          </b-col>
          <b-col align-self="center">
            <n-switch
              id="inpu-enable"
              v-model="identityProvider.password_complexity.check_keyboard_pattern"
              @input="toggle($event, 'password_complexity', 'check_keyboard_pattern')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8" class="form-group has-label pt-2">
            <legend class="bv-no-focus-ring col-form-label switch-label">
              {{ t("Check sequence repeated") }}
            </legend>
          </b-col>
          <b-col align-self="center">
            <n-switch
              id="inpu-enable"
              v-model="identityProvider.password_complexity.check_repeated_sequence"
              @input="toggle($event, 'password_complexity', 'check_repeated_sequence')"
            />
          </b-col>
        </b-row>
      </div>
      <div class="col-12 col-md-4">
        <fg-input
          :label="t('Minimum numeric characters:')"
          name="entMinNumeric"
          ref="entMinNumeric"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('entMinNumeric')"
          v-model.lazy="identityProvider.password_complexity.minimum_number_digits_required"
          @input="handleNumericInput($event, 'password_complexity', 'minimum_number_digits_required')"
          @keypress="onKeyPress"
        ></fg-input>

        <fg-input
          :label="t('Minimum password period (in days):')"
          name="ispMinPassPeriod"
          ref="ispMinPassPeriod"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('ispMinPassPeriod')"
          v-model.lazy="identityProvider.password_rotation.minimum_elapsed_time_to_change"
          @input="handleNumericInput($event, 'password_rotation', 'minimum_elapsed_time_to_change')"
          @keypress="onKeyPress"
        ></fg-input>

        <fg-input
          :label="t('Maximum password period (in days):')"
          name="ispMaxPassPeriod"
          ref="ispMaxPassPeriod"
          v-validate="passwordValidations.common"
          data-vv-as=" "
          :error="getError('ispMaxPassPeriod')"
          v-model.lazy="identityProvider.password_rotation.maximum_elapsed_time_without_change"
          @input="handleNumericInput($event, 'password_rotation', 'maximum_elapsed_time_without_change')"
          @keypress="onKeyPress"
        ></fg-input>

        <b-row>
          <b-col cols="8" class="form-group has-label pt-2">
            <legend class="bv-no-focus-ring col-form-label switch-label">
              {{ t("Check sequential letters") }}
            </legend>
          </b-col>
          <b-col align-self="center">
            <n-switch
              id="inpu-enable"
              v-model="identityProvider.password_complexity.check_sequential_letters"
               @input="toggle($event, 'password_complexity', 'check_sequential_letters')"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8" class="form-group has-label pt-2">
            <legend class="bv-no-focus-ring col-form-label switch-label">
              {{ t("Check sequence mirrored") }}
            </legend>
          </b-col>
          <b-col align-self="center">
            <n-switch
              id="inpu-enable"
              v-model="identityProvider.password_complexity.check_mirrored_sequence"
               @input="toggle($event, 'password_complexity', 'check_mirrored_sequence')"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    </el-form>
  </b-card>
</template>

<script>
import { Form, FormItem, Select } from "element-ui";
import { Switch } from "@/components";
import { EventBus } from "./EventBus";
import _ from 'lodash';

export default {
  name: "identity-providers-configuration",
  props: {
    model: Object,
  },
  components: {
    [Switch.name]: Switch,
    Form,
    FormItem,
    Select
  },
  props: {
    value: {
        type: Object,
        required: true
    },
  },
  identityProviderOptions: [
    { value: 'IdentityProviderSSO', label: 'IdentityProviderSSO' },
    { value: 'IdentityProviderDatabase', label: 'IdentityProviderDatabase' },
  ],
  data() {
    return {
      passwordValidations: {
        common: {
          required: { numeric: true },
        },
      },
      identityProvider: {
        password_complexity: {
          minimum_password_length: 6,
          minimum_symbols_required: 0,
          minimum_middle_number_digits_required: 0,
          minimum_number_digits_required: 0,
          check_mirrored_sequence: false,
          minimum_uppercase_required: 0,
          check_sequential_letters: false,
          check_repeated_sequence: false,
          check_keyboard_pattern: false,
          minimum_lowercase_required: 0,
          minimum_middle_symbols_required: 0,
          check_sequential_numbers: false
        },
        password_rotation: {
          maximum_elapsed_time_without_change: 90,
          history_check_depth: 5,
          minimum_elapsed_time_to_change: 30,
          rotate_on_first_login: false
        },
        system: true,
        enabled: false,
        identity_provider_type: 'IdentityProviderDatabase',
        identity_provider_id: 'IdentityProviderDatabase',
      },
    };
  },
  computed: {
    identityProviderTotal() {
      return Object.assign( {} , this.identityProvider.configuration, this.identityProvider.redirections)
    },
  },
  watch: {
      value(newValue, oldValue) {
          if ( newValue){
              this.identityProvider = _.cloneDeep(newValue);
          }
      },
  },
  mounted () {
      EventBus.$on('resetState', this.resetState)
      if ( !!this.value){
          this.identityProvider = _.cloneDeep(this.value);
      }
  },
  methods: {
    handleNumericInput (value, object, name) {
        this.identityProvider[object][name] = parseInt(value, 10)
        this.$validator.validateAll().then(isValid => {
          if (isValid)
            this.$emit( 'input', this.identityProvider)
        })
    },
    toggle(event, object, name){
        this.identityProvider[object][name] = event
        this.$emit( 'input', this.identityProvider)
    },
    toggleState(event){
        this.$emit( 'input', { ...this.identityProvider, enabled: event })
    },
    resetState(){
        this.$emit( 'input', { ...this.identityProvider, enabled: !!!this.identityProvider.enabled })
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    onKeyPress(evt) {
      const event = evt || window.event;
      const charCode = event.which || event.keyCode;

      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    getFormRef() {
        return this.$refs['identityProviderForm'];
    }
  },
};
</script>
<style scoped lang="scss">
div.card-body {
  padding: 30px;
}
</style>
