<template>
  <div class="d-flex flex-wrap">
    <div
      class="item-container"
      v-for="item in items"
      :key="item.value"
    >
      <select-chip
        :item="item"
        @click="handleItemClick"
      />
    </div>
  </div>
</template>

<script>
import SelectChip from "@/components/Chips/SelectChip";

export default {
  name: "multi-select-chip",
  components: {
    [SelectChip.name]: SelectChip,
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleItemClick(itemClicked) {
      const updatedItems = this.items.map(item => {
        if (item.value === itemClicked.value) {
          return {...item, selected: itemClicked.selected}
        }
        return item;
      })
      this.$emit('select', updatedItems);
    }
  },
}
</script>

<style scoped>
.item-container {
  margin: 5px;
}
</style>
