<template>
    <b-card class="card-user" >
        <div class="author" style="margin-top: 0px;">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
        </div>
        <slot name="header" >
            <h5 slot="header" class="title" style="margin-top: 25px;">{{ t('Customization')}}</h5>
        </slot>

        <b-row align-h="center" >
            <el-table v-if="rowsCol1.length > 0" :data="rowsCol1" class="col-12 col-md-4" style="padding-right: 0px;">
                    <el-table-column :label="t('Language')">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ t(scope.row.language_id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="t('Status')">
                        <template slot-scope="scope">   
                            <n-switch v-model="scope.row.enabled" @input="langChanged($event, scope.row.language_id)" class="ml-auto" :visible_for_permissions="visible_for_permissions" :disabled="scope.row.buttonDisabled"/>
                        </template>
                    </el-table-column>
                    
            </el-table>
            <el-table v-if="rowsCol2.length > 0" :data="rowsCol2" class="col-12 col-md-4" style="padding-right: 0px;">
                    <el-table-column :label="t('Language')">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ t(scope.row.language_id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="t('Status')">
                        <template slot-scope="scope">   
                            <n-switch v-model="scope.row.enabled" @input="langChanged($event, scope.row.language_id)" class="ml-auto" :visible_for_permissions="visible_for_permissions" :disabled="scope.row.buttonDisabled"/>
                        </template>
                    </el-table-column>
                    
            </el-table>
            <el-table v-if="rowsCol3.length > 0" :data="rowsCol3" class="col-12 col-md-4" style="padding-right: 0px;">
                    <el-table-column :label="t('Language')">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ t(scope.row.language_id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="t('Status')">
                        <template slot-scope="scope">   
                            <n-switch v-model="scope.row.enabled" @input="langChanged($event, scope.row.language_id)" class="ml-auto" :visible_for_permissions="visible_for_permissions" :disabled="scope.row.buttonDisabled"/>
                        </template>
                    </el-table-column>
                    
            </el-table>
        </b-row>
    </b-card>
    
</template>

<script>
import { Switch } from '@/components';
import permissions from '@/mixins/permissions'

export default {
    name: 'languages-form',
    mixins: [permissions],
    components: {
        [Switch.name]: Switch,
    },
    props: {
        value: { 
            type: Object,  
        }
    },
    data() {
        return {}
    },
    computed: {
        rowsCol1: {
            get() {
                if (this.value && this.value.supported_lang)
                    return this.value.supported_lang.slice(0, 3);
                else
                    return null
            }   
        },
        rowsCol2: {
            get() {
                if (this.value && this.value.supported_lang)
                    return this.value.supported_lang.slice(3, 6);
                else
                    return null
            }   
        },
        rowsCol3: {
            get() {
                if (this.value && this.value.supported_lang)
                    return this.value.supported_lang.slice(6, this.value.supported_lang.length);
                else
                    return null
            }   
        }
    },
    methods: {
        langChanged(event, id){
            this.$emit('input', {id: id, event: event});
        }
    }
};

</script>
