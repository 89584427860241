<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('router_jobs_text')"
    />
    <b-card class="card-user" no-body>
      <b-card-body style="padding: 0px;">
        <paginated-table :rows="rows"
                         :columns="columns"
                         :actions="actions"
                         :updating="updating"
                         :search="[
                                        {value: 'job_id', key: 'job_id', label: t('job_id')},
                                        {
                                            value: 'status', key: 'status', label: t('status'),
                                            isExtended:true, extendedOptions: statusOptions, hideTextInput: true
                                        },
                                        {
                                            value: 'action', key: 'action', label: t('action'),
                                            isExtended:true, extendedOptions: actionOptions, hideTextInput: true
                                        }
                                    ]"
                         v-model="search_field_value"
                         :itemsPerPage="query.limit"
                         :serverpagination="search_pagination"
                         @pagination="HandlePagination"
                         @prev="handlePrevPage" @next="handleNextPage"
                         @item-stop="handleStop"
        >
          <template v-slot:expand="props">
            <b-row>
              <b-col cols="1"></b-col>
              <b-col cols="10">
                <h5>{{t('Logs')}}:</h5>
                <b-row v-for="log in props.row.logs" :key="log">
                  <b-col cols="8">
                    <span>{{ toLocaleString(datetimeFromISO(log.substring(0,log.indexOf(' - ')))) }}</span>
                    <span class="log-message">{{ log.substring(log.indexOf(' - ') + 3) }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </paginated-table>
      </b-card-body>

      <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t('Update now') }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin"/>
          {{ t('Updating...') }}
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
    import { PaginatedTable } from '@/components';
    import { DateTimeFormatter, ProgressFormatter } from '@/components/Tables/Formatters'
    import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

    export default {
        name: "gm_router_jobs",
        components: {
            PaginatedTable,
            PageTitleCard
        },
        data() {
            return {
                jobs: [],
                updating: false,
                query: {
                    sort: 'asc',
                    limit: 10
                },
                self_query: {},
                currentPage: 1,
                nextPage: 0,
                unstopableStatus: ['finished', 'cancelled', 'failed'],
            };
        },
        async mounted() {
            this.refresh(0)
        },
        computed: {
            rows(){
                let ret = [];
                this.jobs.forEach((job) => {
                    let row = {
                        job_id: job.job_id,
                        description: job.context.action,
                        created_at: this.toLocaleString(this.datetimeFromISO(job.created_at)),
                        status: job.execution.status,
                        updated_at: this.toLocaleString(this.datetimeFromISO(job.execution.updated_at)),
                        total: job.execution.total,
                        processed: job.execution.processed,
                        updated: job.execution.updated,
                        progress: job.execution.status === 'finished'
                            ? 100
                            : job.execution.total === 0
                                ? 0
                                : parseInt(job.execution.processed * 100 / job.execution.total),
                        logs: job.execution.logs
                    }
                    ret.push(row)
                })
                return ret;
            },
            search_field_value: {
                get(){
                    if( this.query && this.query.field_value)
                        return { field: 'job_id', value: this.query.field_value}
                    else
                        return  { field: 'job_id', value: ''}
                },
                set(newValue){
                    this.handleSearch(newValue)
                }
            },
            search_pagination: {
                get(){
                    return { prev: !!this.query && !!this.query.prev_id,
                            next: !!this.query && !!this.query.next_id,
                            currentPage: this.currentPage }
                }
            },
            columns() {
              return [
                    {
                        prop: 'job_id',
                        label: this.t('job_id'),
                        minWidth: 150
                    },
                    {
                        prop: 'logs',
                        type : 'expand',
                        minWidth: 50,
                    },
                    {
                        prop: 'description',
                        label: this.t('description'),
                        minWidth: 180,
                        translateCells: true
                    },
                    {
                        prop: 'created_at',
                        label: this.t('created_at'),
                        minWidth: 195,
                        formatter: DateTimeFormatter
                    },
                    {
                        prop: 'status',
                        label: this.t('status'),
                        minWidth: 130,
                        translateCells: true
                    },
                    {
                        prop: 'updated_at',
                        label: this.t('last_update'),
                        minWidth: 195,
                        formatter: DateTimeFormatter
                    },
                    {
                        prop: 'total',
                        label: this.t('total_routers'),
                        minWidth: 130
                    },
                    {
                        prop: 'processed',
                        label: this.t('processed_routers'),
                        minWidth: 130
                    },
                    {
                        prop: 'updated',
                        label: this.t('updated_routers'),
                        minWidth: 130
                    },
                    {
                        prop: 'progress',
                        label: this.t('progress'),
                        minWidth: 180,
                        formatter: ProgressFormatter
                    }
                ]  
            },
            actions() {
                return {
                    minWidth: 100,
                    label: this.t('Actions'),
                    fixed: 'right',
                    items: [
                        {
                            type: "danger",
                            size: "sm",
                            isIconify: true,
                            color: '#fff',
                            icon: "clarity:stop-solid",
                            event: "item-stop",
                            action: this.t('stop_job'),
                            confirm: true,
                            message: this.t('stop_confirmation_message'),
                            visible_for_permissions: [
                                {actions: ["POST"], path: "/subsystems/rs/routers/jobs/.*"}
                            ],
                            disabledIf: (row) => { return this.unstopableStatus.includes(row['status']) }
                        }
                    ]
                }
            },
            statusOptions() {
                return [
                    {value : 'not_started', key : 'not_started', label : this.t('not_started')},
                    {value : 'running', key : 'running', label : this.t('running')},
                    {value : 'finished', key : 'finished', label : this.t('finished')},
                    {value : 'cancelled', key : 'cancelled', label : this.t('cancelled')},
                    {value : 'failed', key : 'failed', label : this.t('failed')}
                ]
            },
            actionOptions() {
                return [
                    {value : 'reset_safe_mode', key : 'reset_safe_mode', label : this.t('reset_safe_mode')},
                    {value : 'reset_unstable', key : 'reset_unstable', label : this.t('reset_unstable')},
                    {value : 'set_agent_package_id', key : 'set_agent_package_id', label : this.t('set_agent_package_id')}
                ]
            }            
        },
        methods: {
            refresh(wait){
                this.updating = true;
                this.$store.dispatch('generalManagement/jobs/getRouterJobs', { wait: wait, query: this.query})
                    .then( res => {
                        this.jobs = res.items
                        this.query = res.query
                        this.self_query = res.self
                        this.currentPage += this.nextPage
                        this.updating = false
                    })
                    .catch( err => {
                        this.updating = false
                    });
            },
            reload(){
                if (this.self_query){
                    this.query = this.self_query
                    this.nextPage = 0;
                    this.refresh()
                }
            },
            handlePrevPage(){
                if (this.query){
                    ['next_id', 'next_name'].forEach( item => {
                        if ( this.query[item])
                        delete this.query[item]
                    });
                    if ( this.query.prev_id){
                        this.nextPage = -1
                        this.refresh()
                    }
                }
            },
            handleNextPage(){
                if (this.query){
                    ['prev_id', 'prev_name'].forEach( item => {
                        if ( this.query[item])
                        delete this.query[item]
                    });
                    if ( this.query.next_id){
                        this.nextPage = 1
                        this.refresh()
                    }
                }
            },
            HandlePagination(value){
                this.currentPage = 1;
                this.self_query.limit = value;
                ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
                    if ( this.self_query[item])
                    delete this.self_query[item]
                });
                this.reload()
            },
            handleSearch: _.debounce(function (value) {
                this.currentPage = 1
                this.nextPage = 0;
                if ( value){
                    this.query.field_operator = 'eq'
                    this.query.field_name = value.field;
                    this.query.field_value = value.value;
                }
                else{
                    ['field_operator', 'field_name', 'field_value'].forEach( item => {
                        if ( this.query[item]) delete this.query[item]
                    });
                };
                ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
                    if ( this.query[item]) delete this.query[item]
                });
                this.refresh();
            }, 500),
            handleStop(job) {
    			this.$store.dispatch('routerSecure/troubleshooting/stopJob', job.row.job_id);
            }
        }
    };
</script>
<style lang="scss">
    .iconify-header{
        width: 16px;
        height: 16px;
        margin-right: 0em;
    }
    span.log-message{
        margin-left: 3em;
        font-weight: bold;
    }
</style>
