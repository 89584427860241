<template>
  <div
    @click="onClick"
    class="chip"
    :class="[
        { 'chip-selected': item.selected },
        { 'chip-disabled': item.disabled}
      ]"
    :style="chipStyle"
  >
    <p v-if="$te(item.value)">{{ t(item.value) }}</p>
    <p v-else>{{ item.value }}</p>
    <div class="icon-container" v-if="item.selected">
      <svgicon
        icon="icon-check"
        class="icon-check"
        width="14"
        height="14"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "select-chip",
  props: {
    item: Object,
    chipStyle: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    onClick() {
      if (this.item && !this.item.disabled) {
        this.$emit('click', {
          value: this.item.value,
          selected: !this.item.selected
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.chip {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #D3D7E9;
  height: 32px;
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }

  p {
    user-select: none;
    margin: 0;
    color: #858CA9;
    font-size: 14px;
  }
}

.chip-selected {
  border: 1px solid #E86D42;
  p {
    color: #E86D42;
  }
}

.chip-disabled {
  &:hover {
    cursor: auto;
  }
}

.icon-container {
  display: flex;
  margin-left: 8px;
  .icon-check {
    color: #E86D42;
  }
}
</style>
