<template functional>
  <div>   
    <el-select class="dropdownColor" filterable
            remote :remote-method="props.column.optionInput"
            v-model="props.row[props.column.prop]" 
            @change="props.column.optionChange($event, props.column.prop, props.row)" 
            :placeholder="Select" >
      <el-option
         v-for="option in props.column.selectOptions"
         :value="option.key"
         :label="option.hasOwnProperty('label') ? option.label : option.value"
         :key="option.key"
        />
    </el-select> 
  </div>
</template>

<script>
  import { Autocomplete, Button, Select, Option } from 'element-ui';
export default {
    name: 'editselect-formatter',
    components: {
      'el-autocomplete': Autocomplete,
      'el-button': Button,
      'el-select': Select,
      'el-option': Option
    },
}
</script>
<style lang="scss">
  .dropdownColor {
    width: -webkit-fill-available;
    div > input {
     opacity: 0.5 !important;
     background: #C0C4CC !important;
     outline-color: rgb(255, 255, 255) !important;
     color: black !important;
    }
  }
</style>
