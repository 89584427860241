<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-card-group deck class="license">
      <b-card class="form-group">
        <h4 class="card-title">
          <svgicon
            class="icon"
            icon="license-information"
            width="24"
            height="24"
          />
          <span style="padding-left: 1em">{{ t("License Information") }}</span>
        </h4>
        <b-card-text>
          <b-row v-for="(item, index) in dlicense.license_warnings" :key="index">
            <b-col>
              <template>
                <i
                ><svgicon
                  class="icon"
                  icon="license-alert"
                  width="17"
                  height="17"
                /></i>
                {{ t(item) }}
              </template>
            </b-col>
          </b-row>
        </b-card-text>

        <b-card-text class="border rounded-lg shadow mb-2 d-flex flex-column">
          <b-container class="form-group">
            <b-row>
              <b-col class="border-bottom license-left-text" :class="borderClass"
              >{{ t("License ID") }}:</b-col
              >
              <b-col
                class="border-bottom license-right-text"
                :class="borderClass"
              >{{ dlicense.license_code }}</b-col
              >
            </b-row>
            <b-row>
              <b-col class="border-bottom license-left-text" :class="borderClass"
              >{{ t("Expiration Date") }}:</b-col
              >
              <b-col
                class="border-bottom license-right-text"
                :class="borderClass"
              >{{ dlicense.expiration_date }}</b-col
              >
            </b-row>

            <b-row>
              <b-col class="border-bottom license-left-text" :class="borderClass"
              >{{ t("Number of Network Secure Subscribers") }}:</b-col
              >
              <b-col
                class="border-bottom license-right-text"
                :class="borderClass"
                style="display: flex; align-items: flex-end"
              >{{ dlicense.subscribers_total }}</b-col
              >
            </b-row>

            <b-row>
              <b-col class="border-bottom license-left-text" :class="borderClass"
              >{{ t("Current Network Secure Subscribers") }}:</b-col
              >
              <b-col
                class="border-bottom license-right-text"
                :class="borderClass"
                style="display: flex; align-items: flex-end"
              >{{ dlicense.subscribers_current }}</b-col
              >
            </b-row>

            <b-row>
              <b-col class="border-bottom license-left-text" :class="borderClass"
              >{{ t("Services") }}:</b-col
              >
              <b-col
                class="border-bottom license-right-text"
                :class="borderClass"
              >
                <template v-for="item in dlicense.license_services">
                  <div>{{ item }}</div>
                </template>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="border-bottom license-left-text" :class="borderClass"
              >{{ t("Network Secure Antivirus Engines") }}:</b-col
              >
              <b-col
                class="border-bottom license-right-text"
                :class="borderClass"
              >
                <template v-for="item in dlicense.antivirus_engines">
                  {{ item }}<br />
                </template>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">{{ t("Aditional Engines") }}:</b-col>
              <b-col>
                <template v-for="item in dlicense.additional_engines">
                  <div>{{ item }}</div>
                </template>
              </b-col>
            </b-row>
          </b-container>
        </b-card-text>

        <b-card-text>
          <form @submit.prevent="handleUpdate" style="padding-top: 25px">
            <b-container>
              <b-row>
                <b-col>
                  <template>
                    <fg-input
                      v-model="model.newLicenseID"
                      v-validate="{
                      required: true,
                      regex: /^([a-zA-Z]{4}\-){3}[a-zA-Z]{4}$/,
                    }"
                      :label="t('New License')"
                      name="License ID"
                      :error="getError('License ID')"
                      class="no-border no-height form-control-lg"
                      :placeholder="t('New License ID')"
                      autocapitalize="none"
                      style="font-size: 1rem"
                      :visible_for_permissions="[
                      { actions: ['PUT'], path: '/subsystems/ns/license' },
                    ]"
                    ></fg-input>
                  </template>
                </b-col>
                <b-col xl="4" md="5" xs="12" sm="6" class="ml-auto">
                  <n-button
                    native-type="submit"
                    type="primary"
                    round
                    block
                    :visible_for_permissions="[
                    { actions: ['PUT'], path: '/subsystems/ns/license' },
                  ]"
                  >
                    <template>
                      {{ t("Update") }}
                    </template>
                  </n-button>
                </b-col>
              </b-row>
            </b-container>
          </form>
        </b-card-text>
      </b-card>

      <b-card>
        <h4 class="card-title">
          <svgicon
            class="icon"
            icon="third-party-licenses"
            width="24"
            height="24"
          />
          <span style="padding-left: 1em">{{ t("Third party licenses") }}</span>
        </h4>
        <b-card-text class="border rounded-lg shadow mb-2 d-flex flex-column">
          <b-card-group>
            <b-card-text style="margin: 20px">
              {{
                t(
                  "Content explicitly licensed under the Creative Commons Attribution-ShareAlike 3.0 Unported license"
                )
              }}
              (<a
              href="https://creativecommons.org/licenses/by-sa/3.0/"
              target="_blank"
            >https://creativecommons.org/licenses/by-sa/3.0/</a
            >) ('CC-BY-SA')
              {{
                t(
                  "shall be governed by that license unless otherwise marked including all the specific requirements as set forth therein. The text of the CC BY SA license is found at the link provided above and is hereby incorporated into these Terms by reference, and you agree that you will follow the attribution and all other requirements of the CC BY SA license where applicable. The Creative Commons software and content is contributed by staff or contributors to Creative Commons and is licensed under the Creative Commons Attribution 4.0 International license unless otherwise marked"
                )
              }}.
              <a href="https://easylist.to/" target="_blank">{{
                  t("EasyList repository")
                }}</a>
            </b-card-text>

            <b-card-text style="margin: 20px">
              {{
                t(
                  "Content explicitly licensed under the Creative Commons Attribution 3.0 Unported license"
                )
              }}
              (<a
              href="https://creativecommons.org/licenses/by/3.0/"
              target="_blank"
            >https://creativecommons.org/licenses/by/3.0/</a
            >) ('CC-BY')
              {{
                t(
                  "shall be governed by that license unless otherwise marked including all the specific requirements as set forth therein. The text of the CC BY license is found at the link provided above and is hereby incorporated into these Terms by reference, and you agree that you will follow the attribution and all other requirements of the CC BY license where applicable. The Creative Commons software and content is contributed by staff or contributors to Creative Commons and is licensed under the Creative Commons Attribution 4.0 International license unless otherwise marked"
                )
              }}.
              <a href="https://adaway.org/hosts.txt" target="_blank">Adaway</a>
            </b-card-text>
          </b-card-group>
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import { DateTime as objDateTime } from "luxon";
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "license",
  components: {
    PageTitleCard
  },
  data() {
    return {
      dlicense: {},
      updating: false,
      model: {
        newLicenseID: "",
      },
      modelValidations: {
        newLicenseID: {
          required: true,
        },
      },
    };
  },
  computed: {
    borderClass() {
      return this.$store.state.settings.darkmode
        ? "border-white"
        : "border-dark";
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async handleUpdate() {
      let _vm = this;
      let isValidForm = await this.$validator.validateAll();
      if (!isValidForm) return;

      this.$bvModal
        .msgBoxConfirm(
          this.$t("Are you sure to update license? This action can't be undone"), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                }
        )
        .then((confirmed) => {
          if (confirmed) {
            this.$store
              .dispatch(
                "generalManagement/license/updateLicense",
                this.model.newLicenseID
              )
              .then((ret) => {
                if (ret.license_code != "") {
                  this.$store.commit(
                    "setSuccess",
                    this.$t("License successfully updated")
                  );
                  this.model.newLicenseID = "";
                  this.refresh();
                } else {
                  this.$bvModal.msgBoxConfirm(
                    _vm.$t("license." + ret.license_error), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                });
                }
              })
              .catch((err) => this.refresh());
          }
        })
        .catch(() => {});
    },
    reload() {
      this.refresh();
    },
    refresh(wait) {
      let _vm = this;
      this.updating = true;
      this.$store
        .dispatch("generalManagement/license/getLicense", { wait: wait })
        .then((res) => {
          _vm.dlicense = {
            license_code: res.license_code,
            expiration_date: res.expiration_date
              ? _vm
                  .datetimeFromISO(res.expiration_date)
                  .toLocal()
                  .toLocaleString(objDateTime.DATE_SHORT)
              : "",
            installed_services: res.installed_services,
            license_services: res.license_services,
            subscribers_total: res.subscribers_total,
            subscribers_current: res.subscribers_current,
            antivirus_engines: res.antivirus_engines,
            additional_engines: res.additional_engines,
            license_warnings: res.license_warnings,
          };
          this.updating = false;
        })
        .catch((err) => {
          this.updating = false;
        });
    },
  },
  mounted() {
    this.refresh(0);
  },
};
</script>
<style lang="scss" scoped>
    .license{
        label {
            margin-bottom: 1rem;
        }
        button{
            margin-top: 1.3rem;
        }
        .license-left-text {
            margin-left:5px;
            text-align:right;
        }
        .license-right-text {
            margin-right:5px;
        }
    }
    .card {
        word-wrap: normal
    }
</style>
