<template>
  <card class="padding-1">
    <h6>
      <svgicon class="icon" :icon="icon" width="16" height="16" />  
      <span style="padding-left:1em">{{ title }}</span>
    </h6>
    <el-form :model="notification" :ref="formRef" label-width="120px" label-position="left" :rules='formRules'>
      <div class="row">
        <div class="col-md-12">
          <el-form-item :label="t('minimum-time')" class="label-in-top">
            <div style="margin-bottom: 25px">  
              <el-slider
                v-model="notification.time"
                :min="0"
                :max="12"
                :step="1"
                :marks="displayMarks"
                :format-tooltip="(e) => displayMarks[e]"
              >
              </el-slider>
            </div>
        </el-form-item>
      </div>   
     </div>
     <div class="row">
        <div class="col-md-12">
          <el-form-item class="label-in-top" :label="t('sender')" prop="sender">
            <el-input
              v-model="notification.sender"
              :placeholder="t('type-email')"
            ></el-input>
          </el-form-item>
        </div>
     </div>
     <div class="row">
        <div class="col-md-12">
          <el-form-item class="label-in-top" :label="t('subject')" prop="subject">
            <el-input
              v-model="notification.subject"
              :placeholder="t('type-subject')"
            ></el-input>
          </el-form-item>
        </div>
     </div>
     <div class="row">
        <div class="col-md-12">
          <el-form-item class="label-in-top" :label="t('message')" prop="message">
            <el-input
              v-model="notification.message"
              :placeholder="t('type-message')"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 5 }"
            ></el-input>
          </el-form-item>
        </div>
     </div>
    </el-form>
  </card>
</template>

<script>

  import { Form, FormItem } from 'element-ui';

  export default {
    name: 'notification-card',
    components: {
      'el-form': Form,
      'el-form-item': FormItem,
    },
    props: {
      formRef: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      notification: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        emailRegExp: '',
        formRules: {
          sender: [
            { 
              required: true, message: this.t('required'),
              trigger: 'change'
            },
            { 
              pattern: /^\w+[\w\.\-]*@\w+([\.-]?\w+)*(\.[\w\-]{2,8})+$/,
              message: this.t('must-be-email'), trigger: 'change'
            }
          ],
          subject: [
            { 
              required: true, message: this.t('required'),
              trigger: 'change'
            },
            {
              max: 80, message: this.t('max-80-chars'), trigger: 'change'
            }
          ],
          message: [
            { 
              required: true, message: this.t('required'),
              trigger: 'change'
            },
            {
              max: 512, message: this.t('max-512-chars'), trigger: 'change'
            }
          ]
        },
        displayMarks: {
          0:"1m",
          1:"5m",
          2:"10m",
          3:"30m",
          4:"1h",
          5:"2h",
          6:"4h",
          7:"6h",
          8:"12h",
          9:"1d",
          10:"2d",
          11:"5d",
          12:"7d"
        },
      }
    },
    computed: {

    },
    methods: {
      getFormRef() {
        console.log("getFormRef -> getFormRef")
        return this.$refs[this.formRef]
      }
    }
  }
</script>

<style lang='scss'>
  .el-slider__button {
    border: solid 2px #f36b27;
  }

  .el-slider__bar {
    background-color: #ef4431;
  }

</style>
