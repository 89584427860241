import { YesNoIconFormatter, YesNoListFormatter } from "@/components/Tables/Formatters";
import i18n from "@/i18n";

export const clusterTableColumns = [
  {
    prop: "cluster_name",
    label: i18n.t("extsrvs.NAME"),
    minWidth: 130,
  },
  {
    prop: "servers",
    type: "expand",
    width: 50,
  },
  {
    prop: "cluster_type",
    label: i18n.t("extsrvs.TYPE"),
    minWidth: 130,
  },
  {
    prop: "cluster_status",
    label: i18n.t("extsrvs.STATUS"),
    minWidth: 130,
  },
  {
    prop: "cluster_address",
    label: i18n.t("extsrvs.ADDRESS"),
    minWidth: 130,
  },
  {
    prop: "cluster_mode",
    label: i18n.t("extsrvs.MODE"),
    minWidth: 130,
  },
];

export const clusterTableActions = {
  minWidth: 100,
  label: i18n.t("extsrvs.Actions"),
  fixed: "right",
  items: [
    {
      type: "primary",
      icon: "now-ui-icons ui-1_simple-add",
      event: "newServer",
      action: i18n.t("New"),
      visible_for_permissions: [
        { actions: ["POST"], path: "/external-servers/.*" },
      ],
    },
    {
      type: "danger",
      size: "sm",
      icon: "now-ui-icons ui-1_simple-remove",
      event: "removeCluster",
      action: i18n.t("Remove"),
      confirm: false,
      visible_for_permissions: [
        { actions: ["DELETE"], path: "/external-servers/.*" },
      ],
    },
  ],
};

export const serversInClusterColumns = [
  {
    prop: "server_name",
    label: i18n.t("extsrvs.NAME"),
    minWidth: 130,
  },
  {
    prop: "servers",
    type: "expand",
    width: 50,
  },
  {
    prop: "server_type",
    label: i18n.t("extsrvs.TYPE"),
    minWidth: 130,
  },
  {
    prop: "server_status",
    label: i18n.t("extsrvs.STATUS"),
    minWidth: 130,
    formatter: YesNoListFormatter,
  },
  {
    prop: "server_address",
    label: i18n.t("extsrvs.ADDRESS"),
    minWidth: 130,
  },
  {
    prop: "server_mode",
    label: i18n.t("extsrvs.MODE"),
    minWidth: 130,
  },
];

export const serversInClusterActions = {
  minWidth: 100,
  label: i18n.t("extsrvs.Actions"),
  fixed: "right",
  items: [
    {
      type: "warning",
      icon: "now-ui-icons business_badge",
      event: "editServer",
      action: i18n.t("Edit"),
      visible_for_permissions: [
        { actions: ["PATCH"], path: "/external-servers/.*" },
      ],
    },
    {
      type: "danger",
      size: "sm",
      icon: "now-ui-icons ui-1_simple-remove",
      event: "removeServer",
      action: i18n.t("Remove"),
      confirm: false,
      visible_for_permissions: [
        { actions: ["DELETE"], path: "/external-servers/.*" },
      ],
    },
  ],
};

export const smsEmailColumns = [
  {
    prop: "server_id",
    label: i18n.t("smsEmailServers.id"),
    minWidth: 130,
  },
  {
    prop: "server_type",
    label: i18n.t("smsEmailServers.type"),
    minWidth: 130,
  },
  {
    prop: "server_status",
    label: i18n.t("smsEmailServers.status"),
    minWidth: 130,
    formatter: YesNoIconFormatter,
    default: "not available",
  },
  {
    prop: "server_host",
    label: i18n.t("smsEmailServers.host"),
    minWidth: 130,
  },
  {
    prop: "server_port",
    label: i18n.t("smsEmailServers.port"),
    minWidth: 130,
  },
  {
    prop: "server_mode",
    label: i18n.t("smsEmailServers.mode"),
    minWidth: 130,
  },
];

export const smsEmailActions = {
  minWidth: 100,
    label: i18n.t("smsEmailServers.actions"),
    items: [
    {
      type: "warning",
      icon: "now-ui-icons business_badge",
      event: "item-edit",
      action: i18n.t("Edit")
    },
    {
      type: "danger",
      size: "sm",
      icon: "now-ui-icons ui-1_simple-remove",
      action: i18n.t("Remove"),
      confirm: true,
      event: "item-delete",
    }
  ]
};
