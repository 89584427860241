var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{class:[
        {'col-12': _vm.isBasicMode},
        {'col-12 col-md-6 border-right': _vm.isAdvancedMode}
      ]},[_c('b-row',[_c('b-col',{class:[
            'mb-4',
            {'col-12 col-md-6': _vm.isBasicMode},
            {'col-10': _vm.isAdvancedMode}
          ]},[_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:('max:16'),expression:"'max:16'"}],staticStyle:{"margin-bottom":"6px"},attrs:{"visible_for_permissions":_vm.visible_for_permissions,"id":"app_name","name":"app_name","autocapitalize":"yes","label":_vm.t('application_name_label'),"placeholder":_vm.t('application_name_placeholder'),"error":_vm.getError('app_name')},model:{value:(_vm.textsCustomization.app_name),callback:function ($$v) {_vm.$set(_vm.textsCustomization, "app_name", $$v)},expression:"textsCustomization.app_name"}}),_c('p',{staticClass:"help-text ml-3"},[_vm._v(" "+_vm._s(_vm.t('application_name_help_text'))+" ")])],1),_c('b-col',{class:[
          'mb-4',
          {'col-12 col-md-6': _vm.isBasicMode},
          {'col-10': _vm.isAdvancedMode}
        ]},[_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:('max:32'),expression:"'max:32'"}],staticStyle:{"margin-bottom":"6px"},attrs:{"visible_for_permissions":_vm.visible_for_permissions,"id":"company_name","name":"company_name","autocapitalize":"yes","label":_vm.t('company_name_label'),"placeholder":_vm.t('company_name_placeholder'),"error":_vm.getError('company_name')},model:{value:(_vm.textsCustomization.company_name),callback:function ($$v) {_vm.$set(_vm.textsCustomization, "company_name", $$v)},expression:"textsCustomization.company_name"}}),_c('p',{staticClass:"help-text ml-3"},[_vm._v(" "+_vm._s(_vm.t('company_name_help_text'))+" ")])],1),_c('b-col',{class:[
          'mb-4',
          {'col-12': _vm.isBasicMode},
          {'col-10': _vm.isAdvancedMode}
        ]},[_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, url: { require_protocol: true }, max: 1024 }),expression:"{ required: true, url: { require_protocol: true }, max: 1024 }"}],staticStyle:{"margin-bottom":"6px"},attrs:{"visible_for_permissions":_vm.visible_for_permissions,"id":"company_url","name":"company_url","autocapitalize":"yes","label":_vm.t('company_website_label'),"placeholder":_vm.t('company_website_placeholder'),"error":_vm.getError('company_url')},model:{value:(_vm.textsCustomization.company_url),callback:function ($$v) {_vm.$set(_vm.textsCustomization, "company_url", $$v)},expression:"textsCustomization.company_url"}}),_c('p',{staticClass:"help-text ml-3"},[_vm._v(" "+_vm._s(_vm.t('company_website_help_text'))+" ")])],1)],1)],1),_c('b-col',{class:[
        {'col-12': _vm.isBasicMode},
        {'col-12 col-md-6': _vm.isAdvancedMode}
       ]},[(_vm.isAdvancedMode)?_c('b-row',{staticClass:"mb-4"},[_c('b-col',{staticClass:"pl-md-4",attrs:{"md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.t('supported_languages_title')))]),_c('multi-select-chip',{attrs:{"items":_vm.languagesSelectList},on:{"select":_vm.handleLanguageSelect}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"pl-md-4",attrs:{"sm":"12"}},[_c('label',[_vm._v(_vm._s(_vm.t('available_information_title')))]),_c('div',{staticClass:"mb-3"},[_c('n-checkbox',{attrs:{"title":_vm.t('show_footer_label'),"checked":_vm.textsCustomization.show_footer_text,"visible_for_permissions":_vm.visible_for_permissions},on:{"input":function($event){return _vm.onStatusChange($event, 'show_footer_text')}}}),_c('p',{staticClass:"help-text ml-4"},[_vm._v(" "+_vm._s(_vm.copyrightSuffix)+" ")])],1)])],1),_c('b-row',[_c('b-col',{staticClass:"pl-md-4",attrs:{"sm":"12"}},[(_vm.isAdvancedMode)?_c('div',{staticClass:"mb-3"},[_c('n-checkbox',{attrs:{"title":_vm.t('allow_add_users_label'),"checked":_vm.textsCustomization.env_options.visibility.acc_show_add_users_button,"visible_for_permissions":_vm.visible_for_permissions},on:{"input":function($event){return _vm.onStatusChange($event, 'acc_show_add_users_button')}}}),_c('p',{staticClass:"help-text ml-4"},[_vm._v(" "+_vm._s(_vm.t('allow_add_users_help_text'))+" ")])],1):_vm._e()])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"ml-auto",attrs:{"xl":"3","md":"4","xs":"12","sm":"6"}},[_c('n-button',{attrs:{"visible_for_permissions":_vm.visible_for_permissions,"native-type":"submit","type":"primary","id":"btn_submit","round":"","block":""}},[[_vm._v(" "+_vm._s(_vm.t('Save'))+" ")]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }