<template>
    <b-card  >
        <slot name="header">
            <h5 slot="header" class="title">
                <svgicon class="icon" icon="server-configuration" width="16" height="16" />
                <span style="padding-left:1em">{{t('Server Configuration')}}</span>
            </h5>
        </slot>
        <b-container fuild style="width: 100%" class="form-group p-0 m-0">
            <b-row  align-h="center" >
                <b-col lg="6" md="12">
                    <b-row class="row-height">
                        <b-col>
                            <fg-input
                                id="ldap-server-name"
                                v-model="server.name"
                                v-validate="{ required: true, regex: /^([a-zA-Z0-9\_@.])+$/ }"
                                name="Server Name"
                                :error="getError('Server Name')"
                                :label="t('Server Name')"
                                autocapitalize="yes"
                                :readonly="!newServer"
                                @input="(event) => $emit('server-change', event, 'name')"
                            >
                            </fg-input>
                        </b-col>
                    </b-row>
                    <b-row class="row-height">
                        <b-col>
                            <fg-input
                                id="ldap-server-location"
                                v-model="server.location"
                                @input="(event) => $emit('server-change', event, 'location')"
                                :label="t('Server IP address or domain')"
                                name="IP address or Domain"
                                v-validate="'required|ip_or_fqdn'"
                                :error="getError('IP address or Domain')">
                            </fg-input>
                        </b-col>
                    </b-row>
                    <b-row class="row-height">
                        <b-col>
                            <fg-input
                                id="ldap-server-port"
                                v-model="server.port"
                                @input="(event) => $emit('server-change', event, 'port')"
                                :label="t('Listening port')"
                                name="Listening Port"
                                v-validate="'required|between:1,65535|numeric'"
                                :error="getError('Listening Port')"
                                type="number"
                            ></fg-input>
                        </b-col>
                    </b-row>
                    <b-row class="row-height">
                        <b-col>
                            <div class="form-group has-label no-margin-bottom"><label>{{ t('Mode') }}</label></div>
                            <el-select
                                id="ldap-server-mode"
                                v-model="server.mode"
                                @change="(event) => $emit('server-change', event, 'mode')"
                                class="select-primary"
                                :placeholder="t('Mode')"
                                value="Primary">
                                <el-option v-for="option in $options.serverModes"
                                    class="select-primary"
                                    :id="`${option.key}-server-mode`"
                                    :value="option.key"
                                    :label="t(option.label)"
                                    :key="option.key">
                                </el-option>
                            </el-select>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col lg="6" md="12">
                    <b-row class="row-height margin-bottom">
                        <b-col>
                            <b-form-group label-cols="4" :label="t('Status')" label-for="ldap-server-status">
                                <n-switch
                                    id="ldap-server-status"
                                    :value="server.status === 'on' ? true : false"
                                    @input="(event) => $emit('server-change', event, 'status')"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="row-height margin-bottom">
                        <b-col>
                            <div
                                class="form-group has-label no-margin-bottom"
                            ><label>{{ t('encrypt-communications') }}</label></div>
                            <el-select
                                id="ldap-server-encryption"
                                v-model="server.encryption"
                                class="select-primary"
                                :placeholder="t('encrypt-communications')"
                                @change="(event) => $emit('server-change', event, 'encryption')"
                            >
                                <el-option v-for="option in $options.encryptOptions"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="t(option.label)"
                                    :key="option.value">
                                </el-option>
                            </el-select>
                        </b-col>
                    </b-row>
                    <b-row class="row-height" v-if="server.encryption != 'none'">
                        <b-col>
                            <certificate-selector v-model="server.ca_certificate">
                                <div slot='header' class="form-group has-label no-margin-bottom">
                                    <label>{{ t('select server certificate') }}</label>
                                </div>
                            </certificate-selector>
                        </b-col>
                    </b-row>
                    <b-row class="row-height margin-bottom">
                        <b-col>
                            <div
                                class="form-group has-label no-margin-bottom"
                            ><label>{{ t('type-of-ldap') }}</label></div>
                            <el-select
                                id="ldap-server-type"
                                v-model="server.ldap"
                                @change="(event) => $emit('server-change', event, 'ldap')"
                                class="select-primary"
                                :placeholder="t('type-of-ldap')"
                            >
                                <el-option v-for="option in $options.ldapTypeOptions"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="t(option.label)"
                                    :key="option.value">
                                </el-option>
                            </el-select>
                        </b-col>
                    </b-row>
                    <b-row class="row-height align-items-center mt-1">
                        <b-col>
                            <n-button type="primary" id="load-predefined-button" class="no-margin" round block @click.native="loadPredefinedValues">
                                {{ t('load-predefined-values') }}
                            </n-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </b-card>
</template>
<script>
import { Select, Option } from 'element-ui';
import { Switch } from '@/components';
import bus from './bus';
import CertificateSelector from './CertificateSelector';
import { find, propEq } from 'ramda'

export default {
    inject: ['$validator'],
    name: 'server-form',
    serverModes: [
        {key: "primary", value: "Primary", label: "Primary"},
        {key: "secondary", value: "Secondary", label: "Secondary"}
    ],
    encryptOptions: [
        {value: "none", label: "Not encrypted"},
        {value: "ssl", label: "SSL"},
        {value: "tls", label: "TLS"}
    ],
    ldapTypeOptions: [
        {value: "w2k", label: "W2K"},
        {value: "lotus", label: "Lotus"},
        {value: "iplanet", label: "iPlanet"}
    ],
    props: {
        server: {
            type: Object,
            default: () => {
                return {
                    name: "",
                    location: "",
                    port: 389,
                    mode: "primary",
                    status: 'off',
                    encryption: "none",
                    ldap: "w2k",
                    ca_certificate: null
                }
            }
        },
        clusterName: {
            type : String,
        },
        clusterType: {
            type : String,
        },
        newServer: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // server: null
            updating: false
        }
    },
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [Switch.name]: Switch,
        [CertificateSelector.name] : CertificateSelector,
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        onValidate() {
        this.$validator.validateAll();
        if (this.errors.any()) {
          bus.$emit('errors-changed', this.errors.errors)
        }
      },
      loadPredefinedValues() {
        this.$emit('load-predefined-values');
      }
    },
    mounted() {
        // Listen on the bus for the parent component running validation
        bus.$on('validate', this.onValidate);

        // Watch for the changes to the childs error bag and pass back to the parent
        this.$watch(() => this.errors.errors, (newValue, oldValue) => {
        const newErrors = newValue.filter(error => { find(propEq('field', error.field))(oldValue) === undefined });
        const oldErrors = oldValue.filter(error => { find(propEq('field', error.field))(newValue) === undefined });
        bus.$emit('errors-changed', newErrors, oldErrors);
        });
    },
    beforeDestroy() {
        // When destroying the element remove the listeners on the bus.
        // Useful for dynaically adding and removing child components
        bus.$emit('errors-changed', [], this.errors.errors);
        bus.$off('validate', this.onValidate);
    }
}

</script>

<style lang="scss" scoped>
    .no-margin-bottom {
        margin-bottom: 0;
    }
    .align-flex-end {
        align-items: flex-end;
    }
    .no-margin {
        margin: 0;
    }
    .refresh {
        cursor: pointer;
        margin-left: 2em;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    
    @media all and (max-width: 991px) {
        .margin-bottom {
            margin-bottom: 2%;
        }
    }
    @media all and (min-width: 991px) {
        .blank{
            min-height: 67px;
        }
        .row-height {
           height: 85px;
        }
    }
</style>
