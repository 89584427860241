import { render, staticRenderFns } from "./IdentityProvidersEdit.vue?vue&type=template&id=162bf203&scoped=true&"
import script from "./IdentityProvidersEdit.vue?vue&type=script&lang=js&"
export * from "./IdentityProvidersEdit.vue?vue&type=script&lang=js&"
import style0 from "./IdentityProvidersEdit.vue?vue&type=style&index=0&id=162bf203&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162bf203",
  null
  
)

export default component.exports