<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    >
      <template #button>
        <n-button
          id="new-cluster-or-server-button"
          type="primary"
          size="md"
          round
          block
          v-b-modal.modal-new
          @click.native="handleOnNew"
          :visible_for_permissions="[
                { actions: ['POST'], path: '/external-servers/.*' },
              ]"
        >
          <i><svgicon class="icon" icon="icon-new" /></i>
          {{ t("new") }}
        </n-button>
      </template>
    </page-title-card>
    <b-card id="gm-external-servers" no-body>
      <b-card-body style="padding: 0px">
        <nested-paginated-table
          :rows="clusters"
          id="external-servers-table"
          :columns="realClusterTableColumns"
          :nestedColumns="realNestedColumns"
          :actions="clusterTableActions"
          :updating="updating"
          :nestedActions="nestedActions"
          :search="['cluster_name']"
          :showEmptyState="showOnlyOneEmptyState"
          @editServer="handleEditServer"
          @newServer="handleNewServer"
          @removeCluster="handleRemoveCluster"
          @removeServer="handleRemoveServer"
        />
        <paginated-table
          v-if="smsEmailServersRows.length"
          id="external-sms-email-table"
          :rows="smsEmailServersRows"
          :columns="smsEmailColumns"
          :actions="smsEmailActions"
          :updating="updating"
          :search="['server_id']"
          :showEmptyState="false"
          @item-edit="handleEditServer"
          @item-delete="handleRemoveServer"
        />
      </b-card-body>

      <template v-slot:footer>
        <div class="hr-grey mb-2" />
        <div
          @click="refresh"
          v-if="!updating"
          style="cursor: pointer; display: inline"
        >
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t("Update now") }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin" />
          {{ t("Updating...") }}
        </div>
      </template>

      <b-modal
        id="modal-new-cluster-or-server"
        ref="modal-new-cluster-or-server"
        :title="t('new_cluster_or_server')"
        @ok.prevent="submitNewClusterOrServer"
        @cancel="clearClusterOrServerData"
        @close="clearClusterOrServerData"
        :ok-title="t('accept')"
        :cancel-title="t('cancel')"
      >
        <b-form @submit.prevent="">
          <b-form-group>
            <el-select
              id="cluster-or-server-type-select"
              class="select-primary"
              :placeholder="t('cluster_or_server_type')"
              v-model="model.cluster_or_server_type"
              style="width: 100%"
              v-validate="'required'"
              :name="t('cluster_or_server_type')"
              :error="getError(t('cluster_or_server_type'))"
            >
              <el-option
                v-for="option in clusterOrServerTypes"
                class="select-primary"
                :id="`${option.label}-option`"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
            <div
              class="text-danger mt-1"
              style="font-size: 13px"
              v-show="getError(t('cluster_or_server_type'))"
            >
              {{ getError(t("cluster_or_server_type")) }}
            </div>
          </b-form-group>

          <fg-input
            v-if="isClusterType"
            v-model="model.cluster_name"
            v-validate="{
            required: true,
            regex: /^([a-zA-Z0-9\_@.])+$/,
            notexists: true,
          }"
            id="cluster-id-input"
            :name="t('cluster_id')"
            :error="getError(t('cluster_id'))"
            class="no-border no-height form-control-lg scheduled-tasks-modal-input"
            :placeholder="t('cluster_id')"
            addon-left-icon="now-ui-icons business_badge"
            autocapitalize="none"
            style="font-size: 1rem"
          />
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import clusters_list from "./clusters.json";
import PaginatedTable from "@/components/Tables/PaginatedTable";
import { NestedPaginatedTable } from "@/components";
import { Select, Option } from "element-ui";
import {mapActions, mapGetters} from "vuex";
import {
  clusterTableActions,
  clusterTableColumns,
  serversInClusterColumns,
  serversInClusterActions,
  smsEmailActions,
  smsEmailColumns
} from './tablesConfig.js';
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "general-mng-external-servers",
  clusterTypes: clusters_list,
  components: {
    PaginatedTable,
    NestedPaginatedTable,
    [Select.name]: Select,
    [Option.name]: Option,
    PageTitleCard
  },
  data() {
    return {
      model: {
        cluster_name: "",
        cluster_or_server_type: "",
      },
      updating: false,
      external_servers: new Map(),
      clusterOrServerTypes: [
        { value: "ldap", label: "LDAP" },
        { value: "sms", label: "SMS" },
        { value: "email", label: "Email" }
      ],
      clusterTableColumns: clusterTableColumns,
      clusterTableActions: clusterTableActions,
      nestedColumns: serversInClusterColumns,
      nestedActions: serversInClusterActions,
      smsEmailColumns: smsEmailColumns,
      smsEmailActions: smsEmailActions,
    };
  },
  mounted() {
    this.refresh(0);
  },
  computed: {
    ...mapGetters('generalManagement/externalServers', ['smsServers', 'emailServers']),
    layout() {
      return this.$store.getters["settings/layout"];
    },
    isClusterType() {
      return this.model.cluster_or_server_type === 'ldap';
    },
    clusters() {
      let ret = [];
      let vm = this;
      this.external_servers.forEach((value, key) => {
        let servers = [];
        if (value.servers) {
          value.servers.forEach((svalue, key) => {
            let item = {
              server_name: svalue.name,
              server_type: svalue.type + " " + this.$t("Server"),
              server_status: [
                { name: "", state: svalue.status === "on" ? true : false },
              ],
              server_address: svalue.location,
              server_mode: svalue.mode,
              cluster_name: value.name,
              cluster_type: value.type,
            };
            servers.push(item);
          });
        }
        let item = {
          cluster_name: value.name,
          cluster_type: value.type + " " + this.$t("Cluster"),
          cluster_status: "-",
          cluster_address: "-",
          cluster_mode: "-",
          servers: servers,
        };
        ret.push(item);
      });
      let arRet = Array.from(ret).sort((a, b) => {
        return a.cluster_name.toUpperCase() > b.cluster_name.toUpperCase()
          ? 1
          : -1;
      });
      return arRet;
    },
    smsEmailServersRows() {
      const smsItems = Object.values(this.smsServers);
      const emailItems = Object.values(this.emailServers);
      const serverItems = [...smsItems, ...emailItems]
      let serversRows = serverItems.map(serverItem => ({
        server_id: serverItem.kannel_id ? serverItem.kannel_id : serverItem.mta_id,
        server_type: serverItem.type === 'sms' ? `SMS Server (${serverItem.host})` : `Email Server (${serverItem.host})`,
        server_status: serverItem.enabled,
        server_host: serverItem.host,
        server_port: serverItem.port,
        server_mode: serverItem.mode ? serverItem.mode : "",
      }));
      let sortedServersRows = Array.from(serversRows).sort((a, b) => {
        return a.server_id.toUpperCase() > b.server_id.toUpperCase() ? 1 : -1;
      });
      return sortedServersRows;
    },
    realClusterTableColumns: {
      get() {
        if (this.layout == "desktop") {
          return this.clusterTableColumns;
        } else if (this.layout == "tablet") {
          return [this.clusterTableColumns[0], this.clusterTableColumns[1]];
        } else {
          return [this.clusterTableColumns[0], this.clusterTableColumns[1]];
        }
      },
    },
    realNestedColumns: {
      get() {
        if (this.layout == "desktop") {
          return this.nestedColumns;
        } else if (this.layout == "tablet") {
          return [this.nestedColumns[0]];
        } else {
          return [this.nestedColumns[0]];
        }
      },
    },
    showOnlyOneEmptyState() {
      const allTablesEmpty = !(this.clusters.length) && !(this.smsEmailServersRows.length);
      return allTablesEmpty;
    },
  },
  methods: {
    ...mapActions("generalManagement/externalServers", ["deleteSmsServer", "deleteEmailServer"]),
    handleEditServer(data) {
      const serverType = data.row.server_type.split(" ")[0].toLowerCase();
      switch (serverType) {
        case 'ldap':
          this.handleEditLdapServer(data.row.cluster_name, data.row.server_name)
          break;
        case 'sms':
          this.handleEditSmsServer(data.row.server_id)
          break;
        case 'email':
          this.handleEditEmailServer(data.row.server_id)
          break;
      }
    },
    handleEditLdapServer(clusterName, serverName) {
      this.$router.push({
        name: 'General Management / Cluster External Servers / Edit',
        params: {
          cluster_name: clusterName,
          cluster_type: 'ldap',
          server_name: serverName,
        }
      });
    },
    handleEditSmsServer(serverId) {
      this.$router.push({
        name: 'General Management / External Servers / SMS / Edit',
        params: {
          server_type: 'sms',
          server_id: serverId,
          isEdit: true
        }
      });
    },
    handleEditEmailServer(serverId) {
      this.$router.push({
        name: 'General Management / External Servers / Email / Edit',
        params: {
          server_type: 'email',
          server_id: serverId,
          isEdit: true
        }
      });
    },
    handleNewServer(data) {
      this.$router.push({
        path: `/general_management/external_servers/Create/${encodeURIComponent(
          data.row.cluster_name
        )}/${encodeURIComponent(data.row.cluster_type)}`,
      });
    },
    handleRemoveServer(data) {
      const serverType = data.row.server_type.split(" ")[0].toLowerCase();
      if (serverType === 'ldap') {
        if (
          data.row.cluster_name === "DNSDefault" &&
          this.external_servers.get("DNSDefault").servers.size <= 1
        ) {
          this.$bvModal
            .msgBoxConfirm(this.$t("cannot_delete_server"), {
              okTitle: this.$t('accept'),
              cancelTitle: this.$t('cancel')
            })
            .then((confirmed) => {})
            .catch(() => {});
        } else {
          this.$bvModal
            .msgBoxConfirm(this.$t("want_delete_server"), {
              okTitle: this.$t('accept'),
              cancelTitle: this.$t('cancel')
            })
            .then((confirmed) => {
              if (confirmed) {
                this.$store
                  .dispatch("generalManagement/externalServers/deleteServer", {
                    cluster_name: data.row.cluster_name,
                    server_name: data.row.server_name,
                  })
                  .then((res) => {
                    this.external_servers = _.cloneDeep(res);
                    this.$store.commit(
                      "setSuccess",
                      this.$t("server") +
                      " " +
                      data.row.server_name +
                      " " +
                      this.$t("successfully_removed")
                    );
                  })
                  .catch((error) => {
                    console.log("handleRemoveServer -> error", error);
                    if (
                      error &&
                      error.detail &&
                      error.detail.includes("not determine External Servers")
                    ) {
                      this.$store.commit(
                        "setError",
                        this.t(`EXTERNALSERVERSCLUSTER.not_determinate_server`) +
                        " " +
                        data.row.server_name
                      );
                    } else {
                      this.$store.commit(
                        "setError",
                        data.row.server_name + " " + this.t(error.type)
                      );
                    }
                  });
              }
            })
            .catch(() => {});
        }
      } else if (serverType === 'sms') {
        this.deleteSmsServer(data.row.server_id);
      } else if (serverType === 'email') {
        this.deleteEmailServer(data.row.server_id);
      }
    },
    handleRemoveCluster(data) {
      if (data.row.cluster_name === "DNSDefault") {
        this.$bvModal
          .msgBoxConfirm(this.$t("cannot_delete_cluster"), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
          .then((confirmed) => {})
          .catch(() => {});
      } else {
        this.$bvModal
          .msgBoxConfirm(this.$t("want_delete_cluster"), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
          .then((confirmed) => {
            if (confirmed) {
              this.$store
                .dispatch(
                  "generalManagement/externalServers/deleteCluster",
                  data.row.cluster_name
                )
                .then((res) => {
                  this.$store.commit(
                    "setSuccess",
                    this.$t("cluster") +
                      " " +
                      data.row.cluster_name +
                      " " +
                      this.$t("successfully_removed")
                  );
                  this.external_servers = res;
                })
                .catch((err) => {
                  if (
                    err.description &&
                    err.description.includes("not determine External Servers")
                  ) {
                    this.$store.commit(
                      "setError",
                      this.t(`EXTERNALSERVERSCLUSTER.not_determinate`) +
                        " " +
                        data.row.cluster_name
                    );
                  } else {
                    this.$store.commit(
                      "setError",
                      data.row.cluster_name + " " + this.t(err.type)
                    );
                  }
                });
            }
          })
          .catch(() => {});
      }
    },
    refresh(wait) {
      let _vm = this;
      this.updating = true;
      this.$store
        .dispatch("generalManagement/externalServers/getExternalServers")
        .then((res) => {
          _vm.external_servers = res.items;
        })
        .catch((err) => {
          if (this.external_servers.size === 0) {
            _vm.external_servers = this.$store.getters[
              "generalManagement/externalServers/externalServers"
            ];
          }
          this.updating = false;
        });
      this.$store.dispatch("generalManagement/externalServers/getSmsServers");
      this.$store.dispatch("generalManagement/externalServers/getEmailServers");
      this.updating = false;
    },
    handleOnNew() {
      this.$refs["modal-new-cluster-or-server"].show();
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async submitNewClusterOrServer() {
      let isValidForm = await this.$validator.validateAll();
      if (!isValidForm) return;
      let _vm = this;
      if (this.isClusterType) {
        let newCluster = {
          id: this.model.cluster_name,
          name: this.model.cluster_name,
          type: this.model.cluster_or_server_type,
          servers: []
        };
        this.$store
          .dispatch("generalManagement/externalServers/createCluster", newCluster)
          .then((res) => {
            if (res && res.status) {
              if (res.status == 204) {
                this.refresh(0);
                this.$store.commit(
                  "setSuccess",
                  _vm.$t("Cluster") + " " + _vm.$t("successfully created")
                );
              } else {
                let response = res;
                this.$store.commit("setError", { error: response });
              }
            } else {
              this.$store.commit("setError", res);
            }
          })
          .catch((err) => {
            console.error("submitNewCluster -> err", err);
            this.refresh(0);
          });
      } else if (['sms', 'email'].includes(this.model.cluster_or_server_type)) {
        this.handleNewSmsOrEmailServer();
      };
      this.clearClusterOrServerData();
      this.$nextTick(() => {
        this.$refs["modal-new-cluster-or-server"].hide();
      });
    },
    clearClusterOrServerData() {
      this.model.cluster_name = "";
      this.model.cluster_or_server_type = "";
    },
    handleNewSmsOrEmailServer() {
      this.$router.push({
        path: `/general_management/external_servers/Create/${encodeURIComponent(this.model.cluster_or_server_type)}`,
      });
    },
  },
  created() {
    this.$validator.extend("notexists", {
      getMessage: (field) => field + " " + this.$t("already exists") + ".",
      validate: (value) => {
        if (this.external_servers.get(value)) return false;
        return true;
      },
    });
    this.$validator.extend("community", {
      getMessage: (field) =>
        this.$t("Please enter a community name, for example public"),
      validate: (value) => {
        if (value === "") return false;
        return true;
      },
    });
  },
};
</script>
