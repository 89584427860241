<template>
  <b-card>
    <template v-slot:header>
      <h4>
        <i class="mr-2"><svgicon class="icon" icon="item-sso"/></i>
        <span>{{t('identity_provider_configuration')}}</span>
      </h4>
    </template>

    <el-form
      ref="identityProviderForm"
      :model="identityProviderTotal"
      label-width="140px"
      label-position="top"
      style="padding-top: 30px; padding-left: 30px; padding-right: 30px;"
      :rules="formRules"
    >
      <div class="row">
        <div class="col-12 col-sm-7 switch-group">
          <label class="">{{t('enabled')}}</label>
          <n-switch
            class="switch"
            v-model="identityProvider.enabled"
            @input="toggleState"
          />
        </div>
        <div class="col-12 col-sm-5">
          <label-legend :label="t('identity_provider_type')" :legend="t('identity_provider_type_desc')" />
          <el-form-item class="sso-external__identity-provider" prop="identity_provider_type">
            <el-select
              class="select-primary sso-external__provider-type-select"
              v-model="identityProvider.identity_provider_type"
              @input="toggle($event, 'identity_provider_type')"
              :disabled="editable"
            >
              <el-option
                v-for="option in $options.identityProviderOptions"
                :value="option.value"
                :label="t(option.label)"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <template v-if="this.identityProvider.identity_provider_type == 'IdentityProviderSSO'">
          <div class="col-12 col-md-7">
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('shared_secret')" :legend="t('shared_secret_desc')" />
                <el-form-item class="sso-logas-form-group" prop="jwt_secret">
                  <el-input
                    type="password"
                    v-model.lazy="identityProvider.configuration.jwt_secret" 
                    @input="debounceToggle($event, 'configuration', 'jwt_secret')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('url_unauthenticated')" :legend="t('url_unauthenticated_desc')" />
                <el-form-item class="sso-external-form-group" prop="unauthenticated_user_url">
                  <el-input
                    v-model.lazy="identityProvider.redirections.unauthenticated_user_url" 
                    @input="debounceToggle($event, 'redirections', 'unauthenticated_user_url')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('url_jwt_errors')" :legend="t('url_jwt_errors_desc')" />
                <el-form-item class="sso-external-form-group" prop="jwt_error_url">
                  <el-input
                    v-model.lazy="identityProvider.redirections.jwt_error_url" 
                    @input="debounceToggle($event, 'redirections', 'jwt_error_url')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('url_logout')" :legend="t('url_logout_desc')" />
                <el-form-item class="sso-external-form-group" prop="logout_url">
                  <el-input
                    v-model.lazy="identityProvider.redirections.logout_url" 
                    @input="debounceToggle($event, 'redirections', 'logout_url')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('jwt_issuer')" :legend="t('jwt_issuer_desc')" />
                <el-form-item class="sso-external-form-group" prop="jwt_iss">
                  <el-input
                    v-model.lazy="identityProvider.configuration.jwt_iss" 
                    @input="debounceToggle($event, 'configuration', 'jwt_iss')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('jwt_audience')" :legend="t('jwt_audience_desc')" />
                <el-form-item class="sso-external-form-group" prop="jwt_aud">
                  <el-input
                    v-model.lazy="identityProvider.configuration.jwt_aud" 
                    @input="debounceToggle($event, 'configuration', 'jwt_aud')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="this.identityProvider.identity_provider_type == 'IdentityProviderSSOMultipleAccount'">
          <div class="col-12 col-md-7">
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('url_unauthenticated')" :legend="t('url_unauthenticated_desc')" />
                <el-form-item class="sso-external-form-group" prop="unauthenticated_user_url">
                  <el-input
                    v-model.lazy="identityProvider.redirections.unauthenticated_user_url" 
                    @input="debounceToggle($event, 'redirections', 'unauthenticated_user_url')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('url_logout')" :legend="t('url_logout_desc')" />
                <el-form-item class="sso-external-form-group" prop="logout_url">
                  <el-input
                    v-model.lazy="identityProvider.redirections.logout_url" 
                    @input="debounceToggle($event, 'redirections', 'logout_url')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('jwt_public_key')" :legend="t('jwt_public_key_desc')" />
                <el-form-item class="sso-external-form-group" prop="jwt_public_key">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="-----BEGIN PUBLIC KEY-----"  
                    v-model.lazy="identityProvider.configuration.jwt_public_key" 
                    @input="debounceToggle($event, 'configuration', 'jwt_public_key')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('url_entitlement')" :legend="t('url_entitlement_desc')" />
                <el-form-item class="sso-external-form-group" prop="entitlement_url">
                  <el-input
                    v-model.lazy="identityProvider.redirections.entitlement_url" 
                    @input="debounceToggle($event, 'redirections', 'entitlement_url')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('entitlement_access')" :legend="t('entitlement_access_desc')" />
                <el-form-item class="sso-external-form-group" prop="entitlement_access_client_identifier">
                  <el-input
                    v-model.lazy="identityProvider.configuration.entitlement_access_client_identifier" 
                    @input="debounceToggle($event, 'configuration', 'entitlement_access_client_identifier')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('url_jwt_errors')" :legend="t('url_jwt_errors_desc')" />
                <el-form-item class="sso-external-form-group" prop="jwt_error_url">
                  <el-input
                    v-model.lazy="identityProvider.redirections.jwt_error_url" 
                    @input="debounceToggle($event, 'redirections', 'jwt_error_url')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('url_expiration')" :legend="t('url_expiration_desc')" />
                <el-form-item class="sso-external-form-group" prop="expiration_url">
                  <el-input
                    v-model.lazy="identityProvider.redirections.expiration_url" 
                    @input="debounceToggle($event, 'redirections', 'expiration_url')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('jwt_issuer')" :legend="t('jwt_issuer_desc')" />
                <el-form-item class="sso-external-form-group" prop="jwt_iss">
                  <el-input
                    v-model.lazy="identityProvider.configuration.jwt_iss" 
                    @input="debounceToggle($event, 'configuration', 'jwt_iss')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('jwt_audience')" :legend="t('jwt_audience_desc')" />
                <el-form-item class="sso-external-form-group" prop="jwt_aud">
                  <el-input
                    v-model.lazy="identityProvider.configuration.jwt_aud" 
                    @input="debounceToggle($event, 'configuration', 'jwt_aud')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('entitlement_access_user')" :legend="t('entitlement_access_user_desc')" />
                <el-form-item class="sso-external-form-group" prop="entitlement_access_user">
                  <el-input
                    v-model.lazy="identityProvider.configuration.entitlement_access_user" 
                    @input="debounceToggle($event, 'configuration', 'entitlement_access_user')"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label-legend :label="t('entitlement_access_pass')" :legend="t('entitlement_access_pass_desc')" />
                <el-form-item class="sso-external-form-group" prop="entitlement_access_pass">
                  <el-input
                    v-model.lazy="identityProvider.configuration.entitlement_access_pass" 
                    @input="debounceToggle($event, 'configuration', 'entitlement_access_pass')"
                    type="password"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="this.identityProvider.identity_provider_type === 'IdentityProviderIDAM'">
          <div class="col-6">
            <label-legend label="IDAM API URL" />
            <el-form-item class="sso-external-form-group" prop="api_url">
              <el-input
                v-model.lazy="identityProvider.api_url" 
                @input="debounceToggle($event, 'api_url')"
              ></el-input>
            </el-form-item>
            <label-legend :label="t('IDAM API key')" />
            <el-form-item class="sso-external-form-group" prop="api_key">
              <el-input
                v-model.lazy="identityProvider.api_key" 
                @input="debounceToggle($event, 'api_key')"
              ></el-input>
            </el-form-item>
          </div>          
        </template>
      </div>
    </el-form>
  </b-card>
   
</template>

<script>
import { Form, FormItem, Input } from "element-ui";
import { Switch, LabelLegend } from "@/components";
import { EventBus } from "./EventBus";

import _ from 'lodash';

export default {
  name: 'sso-external',
  components: {
    [Switch.name]: Switch,
    LabelLegend,
    Form,
    FormItem,
    Input
  },
  props: {
    value: {
        type: Object,
        required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  identityProviderOptions: [
    { value: 'IdentityProviderSSOMultipleAccount', label: 'IdentityProviderSSOMultipleAccount' },
    { value: 'IdentityProviderSSO', label: 'IdentityProviderSSO' },
    { value: 'IdentityProviderIDAM', label: 'IDAM' }
  ],
  data() {
    return {
      identityProvider: { 
        configuration: {
          /*jwt_secret: '',
          jwt_iss: '',
          jwt_aud: '',
          jwt_public_key: '',
          entitlement_access_client_identifier: '',
          entitlement_access_user: '',
          entitlement_access_pass: ''*/
        },
        redirections: {
          /*unauthenticated_user_url: '',
          jwt_error_url: '',
          logout_url: '',
          expiration_url: '',
          entitlement_url: '',*/
        },
        identity_provider_type: 'IdentityProviderSSOMultipleAccount',
        identity_provider_id: null,
        enabled: false,
        system: null,
        api_url: '',
        api_key: '',
      },
      formRules: {
        jwt_iss: [
          {
            max: 64,
            message: `${this.t('max_field')} 64`,
            trigger: ['blur', 'change']
          }
        ],
        jwt_aud: [
          {
            max: 64,
            message: `${this.t('max_field')} 64`,
            trigger: ['blur', 'change']
          }
        ],
        unauthenticated_user_url: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            type: "url",
            message: this.t('url_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 1024,
            message: `${this.t('max_field')} 1024`,
            trigger: ['blur', 'change']
          }
        ],
        jwt_error_url: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            type: "url",
            message: this.t('url_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 1024,
            message: `${this.t('max_field')} 1024`,
            trigger: ['blur', 'change']
          }
        ],
        logout_url: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            type: "url",
            message: this.t('url_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 1024,
            message: `${this.t('max_field')} 1024`,
            trigger: ['blur', 'change']
          }
        ],
        jwt_secret: [
          {
            required: this.value && this.value.identity_provider_id ? false : true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 256,
            message: `${this.t('max_field')} 256`,
            trigger: ['blur', 'change']
          },
          {
            min: 16,
            message: `${this.t('min_field')} 16`,
            trigger: ['blur', 'change']
          }
        ],
        entitlement_url: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            type: "url",
            message: this.t('url_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 1024,
            message: `${this.t('max_field')} 1024`,
            trigger: ['blur', 'change']
          }
        ],
        entitlement_access_client_identifier: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 64,
            message: `${this.t('max_field')} 64`,
            trigger: ['blur', 'change']
          }
        ],
        expiration_url: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            type: "url",
            message: this.t('url_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 1024,
            message: `${this.t('max_field')} 1024`,
            trigger: ['blur', 'change']
          }
        ],
        jwt_public_key: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 2048,
            message: `${this.t('max_field')} 2048`,
            trigger: ['blur', 'change']
          }
        ],
        api_key: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },          
          {
            max: 2048,
            message: `${this.t('max_field')} 2048`,
            trigger: ['blur', 'change']
          }
        ],        
        api_url: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },        
          {
            type: "url",
            message: this.t('url_field'),
            trigger: ['blur', 'change']
          },          
          {
            max: 21024,
            message: `${this.t('max_field')} 1024`,
            trigger: ['blur', 'change']
          }
        ],            
        entitlement_access_user: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 64,
            message: `${this.t('max_field')} 64`,
            trigger: ['blur', 'change']
          }
        ],
        entitlement_access_pass: [
          {
            required: true,
            message: this.t('required_field'),
            trigger: ['blur', 'change']
          },
          {
            max: 64,
            message: `${this.t('max_field')} 64`,
            trigger: ['blur', 'change']
          }
        ],
      },
    };
  },
  computed: {
    identityProviderTotal() {
      return Object.assign( 
        {}, 
        this.identityProvider.configuration, 
        this.identityProvider.redirections, 
        { api_url: this.identityProvider.api_url }, 
        { api_key: this.identityProvider.api_key }
      );
    }
  },
  watch: {
      value(newValue, oldValue) {
          if ( newValue){
              this.identityProvider = _.cloneDeep(newValue);
          }
      },
  },
  mounted () {
      EventBus.$on('resetState', this.resetState)
      if ( !!this.value){
          this.identityProvider = _.cloneDeep(this.value);
      }
  },
  methods: {
      debounceToggle: _.debounce(function (value, object, name) {
          if(name) {
            this.identityProvider[object][name] = value;
          } else {
            this.identityProvider[object] = value;
          }
          
          this.$refs['identityProviderForm'].validate(isValid => {
            if (isValid)
              this.$emit( 'input', this.identityProvider)
          });
      }, 500),
      toggle(event, name){
          this.identityProvider[name] = event
          this.$emit( 'input', this.identityProvider)
      },
      toggleState(event){
          this.$emit( 'input', { ...this.identityProvider, enabled: event })
      },
      resetState(){
          this.$emit( 'input', { ...this.identityProvider, enabled: !!!this.identityProvider.enabled })
      },
      getFormRef() {
          return this.$refs['identityProviderForm'];
      }
  },

};
</script>
<style lang="scss">
  .sso-external-form-group {
    label {
      margin-bottom: 0em;
      padding-bottom: 0.2em !important;
    }
    input {
      border-radius: 50px;
    }
  }
  .sso-external__identity-provider {
    label {
      margin-bottom: 0em;
      padding-bottom: 0.2em !important;
    }
    input {
      border-radius: 50px;
    }
  }
  .sso-external__provider-type-select {
    width: 100%;
    max-width: 32em;
  }
</style>
