<template>
  <div id='notifications-page'>
    <b-card class="card-user" no-body>
      <div class="author" >
        <div class="block block-one" ></div>
        <div class="block block-three" ></div>
        <div class="block block-four" ></div>
      </div> 
      <b-row>
        <b-col>
          <h4 class="notifications-header">
            <svgicon icon="devices" width="24" height="24" />
            <span>{{t('notifications-config')}}</span>
          </h4>
        </b-col>
        <b-col  md="4" lg="3" xl="2" class="ml-auto header-buttons">
          <n-button @click.native="handleSave"
                    type="primary"
                    size="md" round block>
            {{t('save')}} 
          </n-button>
        </b-col>
      </b-row>

      <div @click="openModal()" v-if="!updating" class="refresh">
        <i class="now-ui-icons arrows-1_refresh-69" /> {{t('refresh')}}
      </div>
      <div v-else class="refresh">
        <i class="now-ui-icons loader_refresh spin"/>
        {{t('updating')}}
      </div>
    </b-card>

    <b-row>
      <b-col lg="6" md="6" sm="12">
        <!-- ANTIVIRUS NOTIFICATIONS -->
        <notification-card :ref="antivirus.parentRef" 
          :formRef="antivirus.ref"
          :title="antivirus.title"
          :icon="antivirus.icon"
          :notification="antivirus.data" 
        />
      </b-col>

      <b-col lg="6" md="6" sm="12">
        <!-- ANTIMALWARE NOTIFICATIONS -->
        <notification-card :ref="antimalware.parentRef" 
          :formRef="antimalware.ref"
          :title="antimalware.title"
          :icon="antimalware.icon"
          :notification="antimalware.data" 
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <!-- PARENTAL CONTROL NOTIFICATIONS -->
        <notification-card :ref="parental.parentRef" 
          :formRef="parental.ref"
          :title="parental.title"
          :icon="parental.icon"
          :notification="parental.data" 
        />
      </b-col>

      <b-col lg="6" md="6" sm="12">
        <!-- ANTIBOTNET NOTIFICATIONS -->
        <notification-card :ref="antibotnet.parentRef" 
          :formRef="antibotnet.ref"
          :title="antibotnet.title"
          :icon="antibotnet.icon"
          :notification="antibotnet.data" 
        />
      </b-col>
    </b-row>

    <b-modal id="modal-refresh" ref="modal" :title="t('sure-to-refresh')" @ok="handleOkModal" @show="resetModal" @hidden="resetModal">
      {{t('all-changes-lost')}}
    </b-modal>
  </div>
</template>
<script>

import NotificationCard from "./Components/NotificationCard";

export default {
  name: 'notifications',
  components: {
    NotificationCard
  },
  data() {
    return {    
      updating: false,
      antivirus: { 
        title: this.t('av-notif'), icon: 'item-antivirus',
        data: null, ref: 'av-form', parentRef: 'av-notif-card'
      },
      antimalware: { 
        title: this.t('am-notif'), icon: 'item-antiphishing',
        data: null, ref: 'am-form', parentRef: 'am-notif-card'
      },
      parental: { 
        title: this.t('pc-notif'), icon: 'new-alert',
        data: null, ref: 'pc-form', parentRef: 'pc-notif-card'
      },
      antibotnet: { 
        title: this.t('ab-notif'), icon: 'rule-conditions',
        data: null, ref: 'ab-form', parentRef: 'ab-notif-card'
      }
    };
  },
  computed: {
    
  },
  methods: {
    refresh(wait){
      this.updating = true;
      
      // TO-DO: REFRESH DATA
      this.$refs[this.antivirus.parentRef].getFormRef().resetFields();
      this.$refs[this.antimalware.parentRef].getFormRef().resetFields();
      this.$refs[this.parental.parentRef].getFormRef().resetFields();
      this.$refs[this.antibotnet.parentRef].getFormRef().resetFields();

      setTimeout( () => {
        this.updating = false
      }, 2000);
    },  
    resetModal() {
      this.$refs.modal.hide()
    },
    openModal(){
      this.$refs.modal.show()
    },
    handleOkModal() {
      this.refresh();
      this.$refs.modal.hide()
    },
    getData() {
      // TO-DO: GET DATA from store

      this.antivirus.data = {
        time: 6,
        sender: '',
        subject: '',
        message: ''
      };
      this.antimalware.data = {
        time: 6,
        sender: '',
        subject: '',
        message: ''
      };
      this.parental.data = {
        time: 6,
        sender: '',
        subject: '',
        message: ''
      };
      this.antibotnet.data = {
        time: 6,
        sender: '',
        subject: '',
        message: ''
      }
    },
    handleSave() {
      let isOk = true;

      this.$refs[this.antivirus.parentRef].getFormRef().validate((valid) => {
        if (!valid) isOk = false;
      });
      this.$refs[this.antimalware.parentRef].getFormRef().validate((valid) => {
        if (!valid) isOk = false;
      });
      this.$refs[this.parental.parentRef].getFormRef().validate((valid) => {
        if (!valid) isOk = false;
      });
      this.$refs[this.antibotnet.parentRef].getFormRef().validate((valid) => {
        if (!valid) isOk = false;
      });

      if (isOk) {
        // TO-DO: SAVE DATA into store
        console.log('Validation Error. Not Save')
      } else {
        console.log('SAVE')
      }
    }
  },
  created () {
    this.getData()
  },
  
};
</script>
<style scoped lang="scss">
.form-container {
   padding-left: 2em;
   min-width: 78%;
}

.notifications-header {
  margin-left: 1em;
  
  span {
    margin-left: 1em;
  }
}

.header-buttons {
  margin-right: 2em;
}

.refresh {
  cursor: pointer;
  margin-left: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>    
