<template>
  <b-card class="border p-2 input-text-card">
    <h5 class="title card-title"> {{ cardTitle }}</h5>
    <b-row>
      <b-col>
        <fg-input
          v-model="title"
          @input="handleChange($event, 'title')"
          id="blocking_page_title"
          name="blocking_page_title"
          :label="t('blocking_page_title_label')"
          :placeholder="t('blocking_page_title_placeholder')"
          v-validate="{ required: true, max: 256 }"
          :error="getError('blocking_page_title')"
          :visible_for_permissions="visible_for_permissions"
          style="margin-bottom: 25px;"
          :isRtl="isLanguageRtl(languageCode)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <fg-input
          v-model="description"
          @input="handleChange($event, 'description')"
          id="blocking_page_description"
          name="blocking_page_description"
          :label="t('blocking_page_description_label')"
          :placeholder="t('blocking_page_description_placeholder')"
          v-validate="{ required: true, max: 1024 }"
          :error="getError('blocking_page_description')"
          :visible_for_permissions="visible_for_permissions"
          style="margin-bottom: 20px;"
          :isRtl="isLanguageRtl(languageCode)"
          :textarea="true"
          rows="3"
        />
      </b-col>
    </b-row>

    <div v-if="hasBlockingReason" class="ml-3">
      <n-checkbox
        :title="t('show_blocking_reason_label')"
        :checked="show_blocking_reason"
        @input="handleChange($event, 'show_blocking_reason')"
        :visible_for_permissions="visible_for_permissions"
      />
      <p class="help-text ml-4">
        {{ blockingReasonHelpText }}
      </p>
    </div>
  </b-card>
</template>

<script>
import languagesList from '@/mixins/languagesList';
import FormMixin from '@/mixins/FormMixin';
import Checkbox from '@/components/Inputs/Checkbox';
import _ from "lodash";

export default {
  name: 'blocking-page-text-card',
  mixins: [languagesList, FormMixin],
  components: {
    [Checkbox.name]: Checkbox,
  },
  props: {
    languageCode: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    },
    pageIndex: {
      type: Number,
      default: 0
    },
    pageCustomization: {
      type: Object,
    },
    visible_for_permissions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      title: '',
      description: '',
      show_blocking_reason: true
    }
  },
  created() {
    if (this.pageCustomization){
      this.title = this.pageCustomization.title;
      this.description = this.pageCustomization.description;
      this.show_blocking_reason = !!(this.pageCustomization.show_blocking_reason);
    }
  },
  methods: {
    handleChange(value, id) {
      this.$data[id] = value;
      const changeData = {
        data: {
          title: this.title,
          description: this.description,
          ...(this.hasBlockingReason && {
            show_blocking_reason: this.show_blocking_reason
          })
        },
        type: this.pageType
      }
      this.$emit('change', changeData);
    },
  },
  computed: {
    cardTitle() {
      if (this.pageType) {
        const title = this.t(`${this.pageType}_card_title`);
        return `${this.pageIndex}. ${title}`;
      }
    },
    hasBlockingReason() {
      return _.has(this.pageCustomization, 'show_blocking_reason');
    },
    blockingReasonHelpText() {
      return this.t(`${this.pageType}_blocking_help_text`)
    }
  }
}
</script>
