<template>
  <b-card class="card-height">
    <slot name="header">
      <h5 slot="header" class="title">
        <svgicon class="icon" icon="item-admin" width="16" height="16" />
        <span style="padding-left:1em">{{t('administrator')}}</span>
      </h5>
    </slot>

    <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
      <b-row  align-h="center" >
        <b-col>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-admin-bn"
                v-model="server.dn"
                v-validate="{ required: true }"
                name="dn"
                :error="getError('dn')"
                :label="t('dn')"
                @input="(event) => $emit('administrator-change', event, 'dn')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-admin-pass"
                v-model="server.password"
                v-validate="{ required: true }"
                type="password"
                name="password"
                :error="getError('password')"
                :label="t('password')"
                @input="(event) => $emit('administrator-change', event, 'password')"
              ></fg-input>
            </b-col>
          </b-row>
          <b-row class="row-height">
            <b-col>
              <fg-input
                id="ldap-admin-base"
                v-model="server.base"
                @input="(event) => $emit('administrator-change', event, 'base')"
                :label="t('base')"
                name="base"
                v-validate="{ required: true }"
                :error="getError('base')"
              ></fg-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>
<script>
import bus from './bus';
import { find, propEq } from 'ramda';

export default {
  inject: ['$validator'],
  name: 'administrator-form',
  props: {
    server: {
      type: Object,
      default: () => {
        return {
          dn: "",
          password: "",
          base: ""
        }
      }
    },
    clusterName: {
      type : String,
    },
    clusterType: {
      type : String,
    }
  },
  data() {
    return {
      updating: false
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    onValidate() {
      this.$validator.validateAll();
      if (this.errors.any()) bus.$emit('errors-changed', this.errors.errors);
    }
  },
  mounted() {
    // Listen on the bus for the parent component running validation
    bus.$on('validate', this.onValidate);

    // Watch for the changes to the childs error bag and pass back to the parent
    this.$watch(() => this.errors.errors, (newValue, oldValue) => {
      const newErrors = newValue.filter(error => { find(propEq('field', error.field))(oldValue) === undefined });
      const oldErrors = oldValue.filter(error => { find(propEq('field', error.field))(newValue) === undefined });
      bus.$emit('errors-changed', newErrors, oldErrors);
    });

    // ASM-9403 Remove autofill values DN and password (fix bug firefox)
    if(window.location.hash.includes('Create')) {
      document.getElementById('ldap-admin-bn').setAttribute("autocomplete", "new-password");
      document.getElementById('ldap-admin-pass').setAttribute("autocomplete", "new-password");
    }
  },
  beforeDestroy() {
    // When destroying the element remove the listeners on the bus.
    // Useful for dynaically adding and removing child components
    bus.$emit('errors-changed', [], this.errors.errors);
    bus.$off('validate', this.onValidate);
  }
}

</script>

<style lang="scss" scoped>
  .no-margin-bottom {
    margin-bottom: 0;
  }
  .align-flex-end {
    align-items: flex-end;
  }
  .no-margin {
    margin: 0;
  }
  .refresh {
    cursor: pointer;
    margin-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .row-height {
    height: 33%;
  }
  .card-height {
    height: calc(100% - 30px);
  }
</style>
