<template>
  <b-container id="gm-external-servers-edit" fuild style="max-width: 2560px">
    <form @submit.prevent="save" autocomplete="off">
      <b-card class="card-user" no-body>
        <div class="author">
          <div class="block block-one"></div>
          <div class="block block-three"></div>
          <div class="block block-four"></div>
        </div>
        <b-row>
          <b-col align-self="center">
            <h4 class="gm-external-servers-header">
              <span>{{ pageTitle }}</span>
            </h4>
          </b-col>
          <b-col md="4" lg="3" xl="2" class="ml-auto header-buttons">
            <b-row class="button-padding">
              <n-button
                id="save-cluster-server-button"
                @click.native="handleSave"
                type="primary"
                size="md"
                round
                block
                :visible_for_permissions='[{actions: ["PATCH", "POST"], path: "/external-servers/.*/servers"}]'
              >
                {{ t("save") }}
              </n-button>
            </b-row>
            <b-row class="button-padding">
              <n-button
                id="cancel-cluster-server-creation"
                @click.native="handleCancel"
                type="primary"
                size="md"
                round
                block
              >
                {{ t("back") }}
              </n-button>
            </b-row>
          </b-col>
        </b-row>
        <div class="hr-grey mb-2" />
        <template v-if="isEdit">
          <div @click="$refs.modal.show()" v-if="!updating" class="refresh">
            <i class="now-ui-icons arrows-1_refresh-69" /> {{ t("Update now") }}
          </div>
          <div v-else class="refresh">
            <i class="now-ui-icons loader_refresh spin" />
            {{ t("Updating...") }}
          </div>
        </template>
        <b-col md="4" lg="3" xl="2" class="ml-auto"> </b-col>
      </b-card>
      <b-row style="padding: 0">
        <b-col align-self="center">
          <sms-server-form
            :server="server"
            @server-change="(event, type) => handleServerChange(event, type)"
          />
        </b-col>
      </b-row>
      <b-modal
        id="modal-refresh"
        ref="modal"
        @ok="handleOkModal"
        @show="$refs.modal.hide()"
        @hidden="$refs.modal.hide()"
      >
        {{ t("are-you-sure") }}
      </b-modal>
    </form>
  </b-container>
</template>

<script>
import SmsServerForm from "./components/SmsServerForm";
import {mapGetters} from "vuex";
import bus from "./components/bus";
import _ from "lodash";

export default {
  name: "sms-server-edit",
  components: {
    "sms-server-form": SmsServerForm,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updating: false,
      serverId: "",
      server: {
        type: "kannel",
        status: 'off',
        host: "",
        port: 13013,
        encryption: "none",
        path: "cgi-bin/sendsms",
        order: "",
        username: "",
        password: "",
        additionalFixedParameters: "",
        mode: "GET",
        from: "",
        authorizationHeader: ""
      },
    };
  },
  methods: {
    handleSave() {
      this.save();
    },
    handleCancel() {
      this.$router.back();
    },
    handleServerChange(data, key) {
      switch (key) {
        case "status":
          this.server.status = data ? "on" : "off";
          break;
        case "type":
          if (data === "kannel") {
            this.server.mode = "GET";
            this.server.port = 13013;
            this.server.path = "cgi-bin/sendsms"
          } else if (data === "twillio") {
            this.server.mode = "POST";
            this.server.port = 443;
            this.server.path = "/2010-04-01/Accounts//Messages.json";
          } else if (data === "3rd party service") {
            this.server.mode = "POST_CUSTOM_AUTH";
            this.server.port = "";
            this.server.path = "";
          }
          this.server.type = data;
          break;
        default:
          this.server[key] = data;
      }
    },
    handleOkModal() {
      this.getServer();
      this.$refs.modal.hide();
    },
    async save() {
      let isValid = await this.$validator.validateAll();
      if (isValid) {
        const serverData = {
          host: this.server.host,
          port: Number(this.server.port),
          ssl: this.server.encryption === 'ssl',
          path: this.server.path,
          from: this.server.from,
          username: this.server.username,
          password: this.server.password,
          authorization_header: this.server.authorizationHeader,
          mode: this.server.mode,
          enabled: this.server.status === 'on',
          order: Number(this.server.order),
          additional_fixed_param_values: this.server.additionalFixedParameters
        };
        this.isEdit
          ? await this.$store
            .dispatch("generalManagement/externalServers/updateSmsServer", {
              serverId: this.serverId,
              serverData
            })
          : await this.$store.dispatch("generalManagement/externalServers/createSmsServer", serverData);
      }
    },
    async getServer() {
      try {
        await this.$store.dispatch("generalManagement/externalServers/getSmsServer", this.serverId);
        const serverData = _.cloneDeep(this.$store.getters["generalManagement/externalServers/getSmsServer"]);
        this.server = {
          type: this.getServerType(serverData.mode),
          status: serverData.enabled ? 'on' : 'off',
          host: serverData.host,
          port: serverData.port,
          encryption: serverData.ssl ? 'ssl' : 'none',
          path: serverData.path,
          order: serverData.order,
          username: serverData.username,
          password: "",
          additionalFixedParameters: serverData.additional_fixed_param_values,
          mode: serverData.mode,
          from: serverData.from,
          authorizationHeader: serverData.authorization_header
        };
      } catch (error) {
        await this.$router.replace({ name: "General Management / External Servers" });
      }
    },
    getServerType(mode) {
      const typesForModes = {
        "GET": "kannel",
        "POST": "twillio",
        "POST_CUSTOM_AUTH": "3rd party service"
      };
      return typesForModes[mode];
    }
  },
  computed: {
    ...mapGetters('generalManagement/externalServers', ['smsServers']),
    pageTitle() {
      return this.serverId ? `${this.t('edit_server')}: ${this.serverId}` : this.t('new_server');
    }
  },
  created() {
    this.serverId = this.$route.params.server_id ? this.$route.params.server_id : '';
    if (this.isEdit) {
      this.getServer();
    }
  },
  mounted() {
    this.$on("veeValidate", () => {
      bus.$emit("validate");
    });
    bus.$on("errors-changed", (newErrors, oldErrors) => {
      newErrors.forEach((error) => {
        if (!this.errors.has(error.field))
          this.errors.add(error.field, error.msg);
      });
      if (oldErrors)
        oldErrors.forEach((error) => {
          this.errors.remove(error.field);
        });
    });
  },
  beforeDestroy() {
    this.$store.dispatch("generalManagement/externalServers/resetLocalServer", 'sms');
  },
  watch: {
    smsServers(newValue, OldValue){
      const newServerCreated = Object.keys(newValue) > Object.keys(OldValue);
      if (newServerCreated){
        this.$router.push({ name: "General Management / External Servers" });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .gm-external-servers-header {
    margin-left: 1em;
    span {
      margin-left: 1em;
    }
  }
  .button-padding {
    padding: 0% 15%;
  }
  .refresh {
    cursor: pointer;
    margin-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .row-height {
    height: 25%;
  }
</style>
