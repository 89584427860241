<template>
  <b-form @submit.prevent="save">
    <b-container
      id="page_DataExportEdit"
      fuild
      style="max-width: 2560px"
      class="p-0"
    >
      <b-card>
        <page-head-actions
          :title="title"
          :editable="false"
          ref="pageHeader"
        >
          <template slot="buttons">
            <n-button
              type="primary"
              size="md"
              round
              block
              :visible_for_permissions="[{ actions: ['PATCH'], path: '/data-export/configuration' }]"
              @click.native="save"
            >
              {{ t("Save") }}
            </n-button>
            <n-button
              type="primary"
              size="md"
              round
              block
              @click.native="cancel"
              >{{ t("Back") }}</n-button
            >
          </template>
        </page-head-actions>
      </b-card>
      <b-card>
        <template v-slot:header>
          <h4>
            <span>{{t('dataset_configuration')}}</span>
          </h4>
        </template>

        <el-form
          ref="form"
          :model="dataExport"
          label-width="200px"
          label-position="left"
          style="padding-left: 60px; padding-right: 30px; padding-top: 30px"
        >
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <el-form-item prop="status" :label="t('status')" style="display: inline-block;">
              <n-checkbox
                :inline="true"
                :checked="dataExport.enabled"
                @input="statusChanged($event)"/>
            </el-form-item>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="this.id == 'allowed_traffic' || this.id == 'blocked_traffic'">
            <div>
              <el-form-item prop="export_interval_time_minutes" :label="t('export_interval_time_minutes')" style="display: inline-block;">
                <el-select class="select-primary mr-3"
                style="min-width: 130px;"
                size="large"
                v-model="dataExport.export_interval_time_minutes">
                  <el-option v-for="(option, index) in exportInterval"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="`data_export_interval_${index}`">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div>
              <el-form-item prop="data_export_compression_type" :label="t('data_export_compression_type')" style="display: inline-block;">
                <el-select class="select-primary mr-3"
                style="min-width: 130px;"
                size="large"
                v-model="dataExport.data_export_compression_type">
                  <el-option v-for="(option, index) in compressionType"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="`data_export_${index}`">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div>
              <el-form-item prop="products" :label="t('products')" style="display: inline-block;">
                <n-selectable-button-list size="md" round block :buttonList="getParsedProducts" @selectedButtons="handleProductSelection"/>
              </el-form-item>
            </div>
          </div>
        </div>
        </el-form>
    </b-card>
    <confirmation-modal ref='confirmation-modal'/>
    </b-container>
  </b-form>
</template>
 
<script>
  import PageHeadActions from "@/components/PageHeadActions";
  import { Form, FormItem, Input } from "element-ui";
  import Checkbox from '@/components/Inputs/Checkbox.vue';
  import ButtonListSelectable from '@/components/ButtonListSelectable.vue';
  import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
  export default {
    name: 'data-export-edit',
    components: {
      PageHeadActions,
      'n-checkbox': Checkbox,
      Form,
      FormItem,
      Input,
      'n-selectable-button-list': ButtonListSelectable,
      ConfirmationModal
    },
    props: {
      id: {
        type: String,
        default: "",
      }
    },
    mounted() {
      this.getDataExport();
    },
    methods: {
      getDataExport() {
        let _vm = this
        this.$store.dispatch('generalManagement/dataExport/getDataExportById', this.id)
          .then( res => {
            _vm.dataExport = _.cloneDeep(res);
            _vm.dataExport_orig = _.cloneDeep(_vm.dataExport);
          })
          .catch( err => {
            console.log("Error", err);
          });
      },
      save() {
        let _vm = this;
        let key = this.dataExport.set_type;
        let updatedData = {};
        updatedData[key] = (this.id == 'blocked_traffic' || this.id == 'allowed_traffic') ? { enabled: this.dataExport.enabled, export_interval_time_minutes: this.dataExport.export_interval_time_minutes, products: this.dataExport.products, data_export_compression_type: this.dataExport.data_export_compression_type } : { enabled: this.dataExport.enabled, products: this.dataExport.products, data_export_compression_type: this.dataExport.data_export_compression_type };
        this.$store.dispatch('generalManagement/dataExport/updateDataExport', updatedData)
          .then( res => {
            _vm.dataExport_orig = _.cloneDeep(_vm.dataExport);
          })
          .catch( err => {
            _vm.dataExport_orig = _.cloneDeep(_vm.dataExport);
            console.log("Error", err);
          });
      },
      cancel() {
        this.$router.push({
          name: `General Management / Data Export`
        });
      },
      statusChanged(event){
        this.dataExport.enabled = event;
      },      
      handleProductSelection(data) {
        this.dataExport.products = data;
        //console.log("Products", this.dataExport.products);
      }
    },
    data() {
      return {
        dataExport: {
          set_type: "", 
          enabled: false, 
          export_interval_time_minutes: "", 
          products: [], 
          data_export_compression_type: ""
        },
        dataExport_orig: null
      };
    },
    computed: {
      title() {
        return `${this.t(this.id)}`;
      },
      compressionType () {
        return [
          {
            value: 'gzip',
            label: this.t('gzip'),
          },
          {
            value: 'none',
            label: this.t('none'),
          },
        ];
      },
      exportInterval () {
        return [
          {
            value: 5,
            label: '5',
          },
          {
            value: 15,
            label: '15',
          },
          {
            value: 45,
            label: '45',
          },
          {
            value: 60,
            label: '60',
          }
        ];
      },
      getParsedProducts() {
        return this.getAvailableProducts
          .filter(product => this.installedProducts.includes(product)).map(product => ({
            id: product,
            label: product,
            active: this.dataExport.products.includes(product)
          })
        )
      },
      installedProducts(){
        return this.$store.getters['settings/getProducts']
      },
      getAvailableProducts() {
        if (this.id == 'blocked_traffic') {
          return ["NetworkSecure", "RouterSecure", "EndpointSecure", "DNSecure", "CBEndpoint"];
        } else if (this.id == 'allowed_traffic') {
          return ["NetworkSecure", "RouterSecure", "DNSecure", "CBEndpoint"];
        } else if (this.id == 'router_deployed') {
          return ["RouterSecure"];
        } else {
          return ["NetworkSecure", "RouterSecure", "EndpointSecure", "DNSecure", "CBEndpoint"];
        }
      }
    },
    beforeRouteLeave: async function(to, from, next) {
      if (!_.isEqual(this.dataExport_orig, this.dataExport)) {
        const isConfirmed = await this.$refs['confirmation-modal'].open({
          title: ' ',
          message: this.t('confirmation_text'),
          ok_title: this.t('keep_editing'),
          cancel_title: this.t('discard')
        });
        if (!isConfirmed) {
          next();
        }
      } else {
        next();
      }
    }
  }
</script> 
 
<style lang="scss" scoped>
  div.card-body {
    padding: 0px;
  }
  div.card.page-head-actions {
    margin-bottom: 0px;
  }
  div.card-footer {
    padding-top: 0px;
  }
</style>