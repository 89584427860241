<template>
  <div class="navigation-tab">
    <p> {{ t('description') }} </p>
    <b-form-group>
      <div class="container">
        <div v-for="radio in radioButtons" :key="radio.name">
          <img class="img" :src="radio.src"/>
          <b-form-radio
            v-model="selected"
            :value="radio.value"
            :disabled="!permission_granted"
            class="radio-button"
          >
            {{ radio.name }}
          </b-form-radio>
        </div>
      </div>
    </b-form-group>
    <b-col xl="3" md="4" xs="12" sm="6" class="ml-auto mt-5">
      <n-button
        block
        round
        type="primary"
        class="text-uppercase"
        :disabled="isSaveDisabled"
        :visible_for_permissions="visible_for_permissions"
        @click.native="saveChanges"
      >
        {{ t("Save UI Customization") }}
      </n-button>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import permissions from '@/mixins/permissions';

export default {
  name: 'navigation-tab',
  mixins: [permissions],
  data() {
    return {
      selected: 'dark',
      radioButtons: [
        {
          name: this.t("dark"),
          value: "dark",
          src: require("@/assets/images/dark.png"),
        },
        {
          name: this.t("light"),
          value: "light",
          src: require("@/assets/images/light.png"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('generalManagement/customization', ['envOptions', 'navigation']),
    isSaveDisabled() {
      return this.selected === this.navigation;
    },
  },
  mounted() {
    this.selected = this.navigation;
  },
  methods: {
      saveChanges() {
        const data = {
          navigation: this.selected,
        };

        this.$store
          .dispatch("generalManagement/customization/updateCustomization", {
            id: "acc",
            data: { env_options: data },
          })
          .then(() => {
            this.$store.commit("setSuccess", this.t("save_success"));
          });
      },
  }
}
</script>

<style lang="scss">
.navigation-tab {
  .container {
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-top: 20px;
  }

  .img {
    box-shadow: 0 2px 5px 2px #bebebe;
  }

  .radio-button {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    label::before, label::after {
      cursor: pointer;
      left: -2rem;
    }
  }
}
</style>
