<template>
  <b-card class="border languages-card">
    <div class="header">
      <svgicon icon="warning" class="warning-icon" />
      <span class="gray-text">{{t("warning")}}</span>
    </div>
    <hr class="line" />
    <div class="card-content">
      <p class="my-3">{{fontFamily}} {{t("is not compatible with the following languages")}}</p>
      <ul>
        <li v-for="language in languages" :key="language">
          {{ language }}
        </li>
      </ul>
      <p class="gray-text mb-2">{{t("Fallback font")}}</p>
      <p class="mb-2">{{fallbackFont}}</p>
    </div>
  </b-card>
</template>

<script>
import { SUPPORTED_LANGUAGES } from "@/util/languageUtils";

export default {
  name: 'languages-message',
  props: {
    fontFamily: String,
    fallbackFont: String,
    languagesList: Array,
  },
  computed: {
    languages() {
      return this.languagesList.map((languageItem) => {
        const languageConfig = SUPPORTED_LANGUAGES.find((lang) => {
          return lang.language_id === languageItem
        })

        if (languageConfig) {
          return languageConfig.language_name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div.languages-card {
  .header {
    font-size: 18px;
    height: 36px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .card-content {
    margin: -15px;
    padding: 15px;
    padding-inline-start: 64px;
    background-color: #e5e5e538;
  }

  .gray-text {
    text-transform: uppercase;
    color: #9BA2AF;
  }

  hr.line {
    margin: 15px -15px;
  }

  .warning-icon {
    height: 27px;
    margin-inline-end: 25px;
    color: #E86D42;
    width: 24px;
  }
}
</style>
