<template>
  <b-card class="border">
    <b-row>
      <b-col>
        <h5 class="title">{{ t("Graphs & Report colors") }}</h5>
        <p class="mb-4">{{ t("Use these colors to generate graphic") }}</p>
      </b-col>
      <b-col xl="3" md="4" xs="12" sm="6" class="ml-auto d-flex pb-4" v-if="isShowResetButton">
        <n-button
          block
          round
          simple
          type="primary"
          class="text-uppercase mr-4"
          @click.native="$emit('resetGraphsColors')"
        >
          <i class="tim-icons icon-refresh-01 mr-2"></i> {{ t("Reset to default") }}
        </n-button>
      </b-col>
    </b-row>

      <div
        class="picker-container col-sm-3 col-xl-2"
        v-for="color in Object.keys(value)"
        :key="color"
      >
        <div class="row">
          <div class="position-relative">
            <b-form-input
              type="color"
              v-model="value[color]"
              :disabled="isPickerDisabled"
              class="color-picker"
              :class="{ 'color-picker-disabled': isPickerDisabled }"
            />
            <div
              class="icon-container"
              :class="{ 'icon-container-disabled': isPickerDisabled }"
            >
              <svgicon icon="item-filteringlogconfig" />
            </div>
          </div>
          <div class="additional-color" :style="{ background:  graphsColorsWithShades[color][1]}"></div>
          <div class="additional-color" :style="{ background:  graphsColorsWithShades[color][2]}"></div>
        </div>
        <p class="row mt-2 mb-0">{{ value[color] }}</p>
        <p class="row">{{ getRGBColor(value[color]) }}</p>
      </div>
  </b-card>
</template>

<script>
import { convertHexToRGB } from "@/util/convertColors";
import permissions from '@/mixins/permissions';

export default {
  name: 'graphs-colors',
  mixins: [permissions],
  data() {
    return {
      popoverShow: false,
    }
  },
  props: {
    value: Object,
    graphsColorsWithShades: Object,
    isGraphsColorsChanged: Boolean,
  },
  methods: {
    getRGBColor(color) {
      return convertHexToRGB(color)
    }
  },
  computed: {
    isPickerDisabled() {
      return !this.permission_granted;
    },
    isShowResetButton() {
      return this.isGraphsColorsChanged && this.permission_granted;
    }
  }
}
</script>

<style lang="scss" scoped>
h5.title {
  text-transform: uppercase;
  color: var(--light);
  font-size: 16px;
  margin-bottom: 8px;
}

.picker-container {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;

  &:hover .icon-container {
    opacity: 1;
  }
  &:hover .icon-container-disabled {
    opacity: 0;
  }
}

input.color-picker {
  cursor: pointer;
  width: 60px;
  height: 60px;
  border: none;
  padding: 0;

  &.form-control[disabled] {
    opacity: 1;
  }
}

input.color-picker-disabled {
  cursor: default;
}

input[type="color"].color-picker {
	-webkit-appearance: none;
	border: none;
}
input[type="color"].color-picker::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"].color-picker::-webkit-color-swatch {
	border: none;
}

.additional-color {
  width: 30px;
  height: 60px;
}

.icon-container {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  margin: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
}
</style>
