<template>
  <b-card class="card-user">
    <tabs
      id="custom-texts-tabs"
      ref="custom-texts-tabs"
      type="primary"
      v-model="active_tab"
      :isTextTabNav="true"
    >
      <tab-pane id="securityManagerUiTextTab" :label="this.t('security-manager-ui')">
        <security-manager-ui-texts-tab
          v-if="active_tab === 'securityManagerUiTextTab'"
          v-model="textAndLanguagesCustomization"
          :customizationType="customizationType"
          :visible_for_permissions="visible_for_permissions"
          @save="save"
        />
      </tab-pane>
      <tab-pane
        v-if="customizationType === 'acc'"
        id="blockingPagesTextTab"
        :label="this.t('blocking-pages')"
      >
        <blocking-pages-texts-tab
          v-if="active_tab === 'blockingPagesTextTab'"
          v-model="textAndLanguagesCustomization"
          :visible_for_permissions="visible_for_permissions"
          @save="save"
        />
      </tab-pane>
    </tabs>
  </b-card>
</template>

<script>
import { TabPane, Tabs } from "@/components";
import SecurityManagerUiTexts from "./Tabs/SecurityManagerUiTexts";
import BlockingPagesTexts from "./Tabs/BlockingPagesTexts";

export default {
  name: "text-customization",
  components: {
    [SecurityManagerUiTexts.name]: SecurityManagerUiTexts,
    [BlockingPagesTexts.name]: BlockingPagesTexts,
    TabPane,
    Tabs,
  },
  props: {
    value: {
      type: Object,
    },
    customizationType: {
      type: String
    },
    visible_for_permissions: {
      type: Array,
      default: () => []
    },
},
  data() {
    return {
      active_tab: 'securityManagerUiTextTab',
      textAndLanguagesCustomization: null
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue){
        this.textAndLanguagesCustomization = newValue;
        setTimeout(() => {
          this.$validator.reset();
        }, 100)
      }
    },
  },
  created () {
    if (this.value){
      this.textAndLanguagesCustomization = this.value;
    }
  },
  methods: {
    save(data){
      this.textAndLanguagesCustomization = data;
      this.$emit('input', this.textAndLanguagesCustomization)
    },
  },
}
</script>
