<template>
  <b-form @submit.prevent="save">
    <b-container
      id="page_IdentityProvidersEdit"
      fuild
      style="max-width: 2560px"
      class="p-0"
    >
      <b-card>
        <page-head-actions
          :title="title"
          :editable="!editable"
          :validationRules="validationRules"
          :validationMessage="validationMessage"
          :placeholder="placeholder"
          :value="getNewIdentityProviderId"
          @input="setNewIdentityProviderId"
          ref="pageHeader"
        >
          <template slot="buttons">
            <n-button
              type="primary"
              size="md"
              round
              block
              @click.native="preSave"
              >{{ t("save") }}</n-button
            >
            <n-button
              type="primary"
              size="md"
              round
              block
              @click.native="cancel"
              >{{ t("back") }}</n-button
            >
          </template>
        </page-head-actions>

        <template v-slot:footer>
          <div class="hr-grey mb-2" />
          <div
            @click="update"
            v-if="!updating"
            style="cursor: pointer; display: inline"
          >
            <i class="now-ui-icons arrows-1_refresh-69" />
            {{ t("Update now") }}
          </div>
          <div v-else>
            <i class="now-ui-icons loader_refresh spin" />
            {{ t("Updating...") }}
          </div>
        </template>
      </b-card>

      <template v-if="identityProvider">
        <identity-providers-configuration
          ref="identProvComp"
          v-if="isIdentityProvidersDatabase"
          v-model="identityProvider"
        />
        <ldap-configuration
          ref="identProvComp"
          v-if="isLDAP"
          v-model="identityProvider"
          :clusters="ldap_clusters"
          :groups="ldap_groups"
          @update_groups="UpdateLDAPGroups"
          @setCluster="setCluster"
        />
        <div v-if="isSSO">
          <sso-logas
            ref="identProvComp"
            v-if="isSystem"
            v-model="identityProvider"
          />
          <sso-external
            v-else
            ref="identProvComp"
            v-model="identityProvider"
            :editable="editable"
          />
        </div>
      </template>

      <b-modal
        id="modal-status-providers"
        ref="modal-status-providers"
        :title="t('warning')"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        @cancel="enableDisable.cancel"
        @ok="enableDisable.action"
      >
        {{ enableDisable.message }}
      </b-modal>

      <!--<b-modal
        id="modal"
        ref="modal"
        @ok="handleConfirm"
        @cancel="handleCancel"
        :title="t('go_back_title')"
      >
        {{ t("go_back_content") }}
      </b-modal>-->
    <confirmation-modal ref='confirmation-modal'/>
    </b-container>
  </b-form>
</template>

<script>
import PageHeadActions from "@/components/PageHeadActions";
import IdentityProvidersConfiguration from "./components/IdentityProvidersConfiguration";
import LDAPConfiguration from "./components/LDAPConfiguration";
import SSOLogAs from "./components/SSOLogAs";
import SSOExternal from "./components/SSOExternal";
import { EventBus } from "./components/EventBus";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
import { filterPayloadByRequiredProps } from '@/util/util';

export default {
  name: "identity-providers-edit",
  components: {
    PageHeadActions,
    IdentityProvidersConfiguration,
    "ldap-configuration": LDAPConfiguration,
    "sso-logas": SSOLogAs,
    "sso-external": SSOExternal,
    ConfirmationModal
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: true,
    },
    guiStore: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      updating: false,
      identityProviderData: null,
      identityProviderData_orig: null,
      enableDisable: {
        action: () => {},
        message: "",
        cancel: () => {},
      },
      ldap_groups: [],
      ldap_clusters: [],
      ldap_groups_query: {
        sort: "asc",
        field_name: "name",
        field_operator: "sw",
        limit: 100,
        field_value: null,
      },
      force_query_groups: false,
      gui: {
        isp: "ISP",
        res: "Residential",
        ent: "Enterprise",
      },
      newIdentityProviderName: "",
      newIdentityProviderObject: new Object(),
      ldapCluster: null
    };
  },
  computed: {
    identityProvider: {
      get() {
        if (!this.editable) {
          this.$store.dispatch("providers/clearProviderDetails", this.guiStore);
          this.$store.dispatch("providers/setProviderType", {
            type: this.guiStore,
            providerType: this.type,
          });
          this.identityProviderData = this.$store.getters[
            `providers/${this.guiStore}ProviderDetails`
          ];

          if(this.newIdentityProviderObject)
            this.identityProviderData["local_cache"] = this.newIdentityProviderObject["local_cache"]
          if (this.identityProviderData && this.identityProviderData.configuration) this.identityProviderData.configuration.jwt_aud = "";
          if (this.identityProviderData && this.identityProviderData.configuration) this.identityProviderData.configuration.jwt_iss = "";
          if (this.identityProviderData && this.identityProviderData.configuration) this.identityProviderData.configuration.jwt_secret = "";
        } else if (this.identityProviderData && this.editable && this.type == 'IdentityProviderSSOMultipleAccount') {
          this.identityProviderData.configuration.redirect_sso_jwt_url = this.$store.getters[
            `providers/${this.guiStore}ProviderDetails`
          ].configuration.redirect_sso_jwt_url;
        } else if (!!!this.identityProviderData) {
          this.identityProviderData = this.$store.getters[
            `providers/${this.guiStore}ProviderDetails`
          ];
        }
        if (this.ldapCluster != null) {
          this.identityProviderData.ldap_cluster = this.ldapCluster;
        }
        this.updating = false;
        if (!this.identityProviderData_orig) this.identityProviderData_orig = this.identityProviderData;

        return this.identityProviderData;
      },
      set(newValue) {
        if (this.editable) {          
          this.identityProviderData = {
            ...this.identityProviderData,
            ...newValue,
          };
        } else {        
          this.newIdentityProviderObject = {
            ...this.newIdentityProviderObject,
            ...newValue,
          };
        }
        if (this.isLDAP)
          if (newValue.ldap_groups_query)
            this.field_value_ldap_query_groups = newValue.ldap_groups_query;
      },
    },
    title() {
      return `${this.identityProvider.identity_provider_id}`;
    },
    isIdentityProvidersDatabase() {
      return (
        this.identityProvider.identity_provider_type ===
        "IdentityProviderDatabase"
      );
    },
    isLDAP() {
      return (
        this.identityProvider.identity_provider_type === "IdentityProviderLDAP"
      );
    },
    isSSO() {
      return (
        this.identityProvider.identity_provider_type === "IdentityProviderSSOMultipleAccount" ||
        this.identityProvider.identity_provider_type === "IdentityProviderSSO" ||
        this.identityProvider.identity_provider_type === "IdentityProviderIDAM"
      );
    },
    isSystem() {
      return !!this.identityProvider && this.identityProvider.system;
    },
    key() {
      return (
        this.identityProvider.identity_provider_type +
        "_" +
        this.identityProvider.identity_provider_id +
        "_" +
        this.isSystem
      );
    },
    ldap_cluster() {
      if (this.isLDAP) return this.identityProvider.ldap_cluster;
      else return null;
    },
    field_value_ldap_query_groups: {
      get() {
        return this.ldap_groups_query.field_value;
      },
      set(newValue) {
        this.ldap_groups_query.field_value = newValue;
        if (this.ldap_cluster) {
          this.getQueryGroups(
            this.ldap_cluster,
            this.ldap_groups_query,
            this.force_query_groups
          );
          //this.force_query_groups = false;
        }
      },
    },
    editable() {
      return this.edit;
    },
    placeholder() {
      return this.t("name");
    },
    getNewIdentityProviderId() {
      return this.newIdentityProviderName;
    },
    validationRules() {
      return 'required|regex:^[a-zA-Z0-9]*$';
    },
    validationMessage() {
      return this.t('not_valid_field');
    },
  },
  created() {
    this.$store.dispatch("providers/clearProviderDetails", this.guiStore);
  },
  mounted() {
    this.$nextTick(function () {
        if (this.editable) {
          this.getIdentityProvider();
        }
        this.loadIfLDAP();
    })
  },
  beforeRouteUpdate(to, from, next) {
    const toGUI = to.params.guiStore;
    const isIOT = this.$store.getters["settings/isIOT"];
    isIOT ? next(toGUI !== "res") : next(toGUI !== "ent");
  },
  methods: {
    cancel() {
      this.$router.push({ path: '/general_management/authentication/isp' });
    },
    preSave() {
      if (this.editable) {
        this.handleStatusChange();
      } else {
        this.createIdentityProvider();
      }
    },
    save() {      
      let data = {
        id: this.id,
        env: this.guiStore,
        type: this.type,
        data: this.identityProvider,
      };
      this.$refs["identProvComp"]
        .getFormRef()
        .validate(async (valid, errors) => {
          if (valid) {
            this.$store
              .dispatch("providers/setProviderDetails", data)
              .then(() => {
                if( _.get(this.identityProviderData.configuration, "jwt_secret")  ){                  
                  delete this.identityProviderData.configuration.jwt_secret
                  this.identityProvider = this.identityProviderData
                }
                
                this.$store.commit("setSuccess", this.t("save_success"));
              });
          } else {
            this.$store.commit("setError", this.t("errors_in_form"));
          }
        });
    },
    filterPayloadByType(provider) {
      const providerType = provider.identity_provider_type;
      const payloadPropsByType = {
        IdentityProviderSSO: ['identity_provider_id', 'identity_provider_type', 'enabled', 'configuration', 'redirections'],
        IdentityProviderIDAM: ['identity_provider_id', 'identity_provider_type', 'enabled', 'api_key', 'api_url'],
      }
      let filteredPayload = provider;
      if (payloadPropsByType[providerType]) {
        const propsByType = payloadPropsByType[providerType];
        filteredPayload = filterPayloadByRequiredProps(provider, propsByType);
      }
      delete filteredPayload.system;
      return filteredPayload;
    },
    loadIfLDAP() {
      if (this.isLDAP) {
        this.getExternalServers();
        this.force_query_groups = true;
        this.UpdateLDAPGroups("");
      }
    },
    update() {
      this.loadIfLDAP();
      if (this.editable) {
        this.getIdentityProvider(true);
      }
    },
    UpdateLDAPGroups(query) {
      this.field_value_ldap_query_groups = query;
    },
    setCluster(cluster) {
      this.ldapCluster = cluster;
      this.force_query_groups = true;
      this.UpdateLDAPGroups("");
    },
    async getIdentityProvider(wait) {
      this.updating = true;
      if (wait) {
        await this.$store.dispatch("providers/getProviderDetails", {
          type: this.guiStore,
          id: this.id,
        });
        if (this.type == 'IdentityProviderSSOMultipleAccount') {
          await this.$store.dispatch("providers/getProviderSsoUrl", {
            type: this.guiStore,
            id: 'IdentityProviderSSO',
          });
        }
      } else {
        this.$store.dispatch("providers/getProviderDetails", {
          type: this.guiStore,
          id: this.id,
        }).then(() => {
          if (this.type == 'IdentityProviderSSOMultipleAccount') {
            this.$store.dispatch("providers/getProviderSsoUrl", {
              type: this.guiStore,
              id: 'IdentityProviderSSO',
            }).then(() => {
              this.identityProviderData = null;
              this.identityProviderData_orig = null;
              this.loadIfLDAP();
              this.updating = false;
            });
          } else {
            this.identityProviderData = null;
            this.identityProviderData_orig = null;
            this.loadIfLDAP();
            this.updating = false;
          }
        }).catch((err) => {
          console.error("Error getting provider --> ", err);
          this.$router.replace({ name: "General Management / Authentication" });
        });
      }
    },
    resetEnableDisableModal() {
      this.enableDisable = {
        action: () => {},
        message: "",
        cancel: () => {},
      };
    },
    handleStatusChange() {
      this.$store
        .dispatch("providers/updateProviderStatus", {
          type: this.guiStore,
          provider: this.identityProvider,
          force: false,
          simulation: true,
        })
        .then(() => {
          this.$store.dispatch(
            "providers/getIsSystemSsoProviderEnabled",
            this.guiStore
          );
          this.resetEnableDisableModal();
          this.save();
        })
        .catch((err) => {
          if (err.enableds) {
            this.enableDisable = {
              action: async () => {
                this.$store
                  .dispatch("providers/updateProviderStatus", {
                    type: this.guiStore,
                    provider: this.identityProvider,
                    force: true,
                    simulation: true,
                  })
                  .then(() => this.save())
                  .catch((err) => this.save());
              },
              message:
                err.cause === "twoOrMoreEnableds"
                  ? this.t("want_disable_others")
                  : `${this.t("want_disabled_all")} ${
                      this.gui[this.guiStore]
                    } ${this.t("want_disabled_all_2")}`,
              cancel: () => {
                EventBus.$emit("resetState");
                this.save();
              },
            };
            this.$refs["modal-status-providers"].show();
          } else {
            EventBus.$emit("resetState");
            this.save();
          }
        });
    },
    createIdentityProvider() {
      this.$refs.pageHeader
      .validate()
      .then((valid, errors) => {
        if (valid) {
          this.$refs["identProvComp"]
          .getFormRef()
          .validate(async (valid, errors) => {
            if (valid) {
              let newIdentityProvider = new Object();
              if ('identity_provider_type' in this.newIdentityProviderObject) {
                Object.assign(newIdentityProvider, this.newIdentityProviderObject);
              } else {
                Object.assign(newIdentityProvider, this.identityProvider);
              }
              newIdentityProvider.identity_provider_id = this.newIdentityProviderName;
              newIdentityProvider = this.filterPayloadByType(newIdentityProvider);
              this.$store
                .dispatch("providers/createProvider", {
                  type: this.guiStore,
                  provider: newIdentityProvider,
                })
                .then(
                  (res) => {
                    this.$store.commit("setSuccess", this.t("save_success"));
                    this.$router.push({
                      name: 'General Management / Authentication / Identity Providers / Edit',
                      params: {
                        edit: true,
                        id: newIdentityProvider.identity_provider_id,
                        type: newIdentityProvider.identity_provider_type,
                        guiStore: this.guiStore
                      },
                    });
                  }
                );
            }
          });
        }
      });
    },
    getExternalServers() {
      let _vm = this;
      this.$store
        .dispatch("generalManagement/externalServers/getExternalServers")
        .then((res) => {
          let clusters = [];
          if (res && res.items) {
            const data = res.items;
            data.forEach((cluster) => {
              if (cluster.servers && cluster.servers.length > 0) {
                for (let server of cluster.servers) {
                  if (server.status === "on") {
                    clusters.push({ key: cluster.name, value: cluster.name });
                    break;
                  }
                }
              }
            });
          }
          _vm.ldap_clusters = clusters;
        })
        .catch((err) => {
          _vm.ldap_clusters = [];
        });
    },
    getQueryGroups(ldap_cluster, filter, force) {
      let _vm = this;
      this.$store
        .dispatch("generalManagement/externalServers/getQueryGroups", {
          cluster: ldap_cluster,
          filter: filter,
          force: force,
        })
        .then((res) => {
          _vm.ldap_groups = res;
          if (_vm.$refs["identProvComp"] && _vm.$refs["identProvComp"].ldapGroups()) {
            _vm.$refs["identProvComp"].ldapGroups()
          }
        })
        .catch((err) => {
          _vm.ldap_groups = [];
        });
    },
    /*handleConfirm() {
      this.$refs.modal.hide();
      this.$store.dispatch("providers/clearProviderDetails", this.guiStore);
      // Go to previous route in history
      this.$router.go(-2);
    },
    handleCancel() {
      this.$refs.modal.hide();
    },*/
    setNewIdentityProviderId(value) {
      this.newIdentityProviderName = value;
    },
  },
  beforeRouteLeave: async function(to, from, next) {
      if (!_.isEqual(this.identityProviderData_orig, this.identityProviderData)) {
        const isConfirmed = await this.$refs['confirmation-modal'].open({
          title: this.t('discard_changes_title'),
          message: this.t('discard_changes_content'),
          ok_title: this.t('keep_editing'),
          cancel_title: this.t('discard')
        });
        if (!isConfirmed) {
          this.$store.dispatch("providers/clearProviderDetails", this.guiStore);
          next();
        }
      } else {
        this.$store.dispatch("providers/clearProviderDetails", this.guiStore);
        next();
      }
    }
};
</script>


<style lang="scss" scoped>
div.card-body {
  padding: 0px;
}
div.card.page-head-actions {
  margin-bottom: 0px;
}
div.card-footer {
  padding-top: 0px;
}
</style>
