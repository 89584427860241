<template>
  <ldap-server-edit v-if="clusterOrServerType === 'ldap'"/>
  <sms-server-edit
    v-else-if="clusterOrServerType === 'sms'"
    :isEdit="isEdit"
  />
  <email-server-edit
    v-else-if="clusterOrServerType === 'email'"
    :isEdit="isEdit"
  />
</template>

<script>
import LdapServerEdit from "./LdapServerEdit";
import SmsServerEdit from "./SmsServerEdit";
import EmailServerEdit from "./EmailServerEdit";

export default {
  name: "general-mng-external-servers-edit",
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "ldap-server-edit": LdapServerEdit,
    "sms-server-edit": SmsServerEdit,
    "email-server-edit": EmailServerEdit
  },
  computed: {
    clusterOrServerType () {
      let type = '';
      if (this.$route.params.cluster_type && this.$route.params.cluster_type.includes('ldap')) {
        type = 'ldap'
      } else if (this.$route.params.server_type) {
        type = this.$route.params.server_type;
      }
      return type;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
