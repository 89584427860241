<template>
    <b-card>
        <slot name="header">
            <h5 slot="header" class="title">Roles Asignment</h5>
        </slot>

        <form @submit.prevent="save">
            <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                <b-row>
                    <b-col xl="6" md="7" class="mb-2" visible_for_products='NetworkSecure'>
                        <div class="form-group has-label"><label>NetworkSecure role</label></div>
                        <el-select class="select-primary"
                            size="large"
                            placeholder="NetworkSecure Role"
                            v-model="role_ns"
                            @input="setNSrole"
                            :disabled="!!roles.ns_role">
                                <el-option v-for="option in ns_roles"
                                    class="select-primary"
                                    :value="option.key"
                                    :label="option.value"
                                    :key="option.key">
                                </el-option>
                        </el-select>
                    </b-col>
                    <b-col xl="6" md="5" align-self="end">
                        <div class="form-group has-label"><label>
                            <template v-if="roles.ns_role">*</template>
                            <template v-else>CAUTION!</template>
                            NetworkSecure role can't be changed</label></div>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- <b-col>
                        <paginated-table :rows='asm_roles' :columns='columns' @item-toggle="handleToggle"/>
                    </b-col> -->
                </b-row>
                <b-row>
                    <b-col v-for="item in asm_roles" :key="item.role">
                        <div class="form-group has-label"><label>AllotSecure roles</label></div>
                        <b-container fluid >
                            <b-row no-gutters >
                                <b-col xl="3" lg="4" cols="8" class="form-group has-label pt-2" align-self="center">
                                    <h5 class="mx-auto my-auto">{{ item.role }}</h5>
                                </b-col> 
                                <b-col xl="1" lg="2" cols="2" align-self="center">
                                    <n-switch :key="item.role" v-model="item.enabled" @input="toggle($event, item.role)"/>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-col>
                </b-row>
            </b-container>
        </form>

        <template v-slot:footer>
            <div class="hr-grey mb-2"/>
            <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
                <i class="now-ui-icons arrows-1_refresh-69" />
                {{ t('Update now') }}
            </div>
            <div v-else>
                <i class="now-ui-icons loader_refresh spin"/>
                {{ t('Updating...') }}
            </div>
        </template>

        <b-modal ref="modal" @ok="handleConfirm" @cancel="handleCancel" :title="t('Set NetworkSecure role')">
                      {{ t('Are you sure? This action can\'t be undone') }}
        </b-modal>


    </b-card>
</template>


<script>
import { Select, Option } from 'element-ui';
// import { PaginatedTable } from '@/components';
// import { SwitchFormatter, ListFormatter } from '@/components/Tables/Formatters'
import { Switch } from '@/components';

export default {
    name: 'roles-form',
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        // PaginatedTable,
        [Switch.name]: Switch,
    },
    props: {
        value: { 
            type: Object        
        },
        asmroles : {
            type: Map
        },
        nsroles : {
            type: Map
        },
    },
    ns_roles : [{ key: 'Customer Care Administrator', value: 'Customer Care Administrator'},
               { key: 'Monitor Administrator', value: 'Monitor Administrator'},
               { key: 'Operations Administrator', value: 'Operations Administrator'},
               { key: 'Read Only Administrator', value: 'Read Only Administrator'} 
    ],
    data() {
        return {
            roles: { ns_role: null, asm_roles: []},
            role_ns: null,
            updating: false,
/*             columns: [
                {
                    prop: 'role',
                    label: 'Role',
                    minWidth: 300
                },
                {
                    prop: 'enabled',
                    label: '',
                    minWidth: 75,
                    formatter: SwitchFormatter
                }
            ] */
        }
    },
    watch: {
        value(newValue, oldValue) {
            this.roles = newValue;
            if ( this.roles.ns_role)
                this.role_ns = this.roles.ns_role
        }
    },
    mounted () {
        if ( !!this.value){
            this.roles = this.value;
            if ( this.roles.ns_role)
                this.role_ns = this.roles.ns_role
        }
    },
    methods: {
        handleConfirm(){
            this.roles.ns_role = this.role_ns
            this.$refs.modal.hide()
            this.$emit('input', this.roles)
        },
        handleCancel(){
            this.roles.ns_role = this.role_ns = null
            this.$refs.modal.hide()
        },
        toggle(event, role){
            if (event){
                if (!this.roles.asm_roles.includes(role))
                    this.roles.asm_roles.push(role)
            }
            else{
                this.roles.asm_roles = this.roles.asm_roles.filter( item => item != role)
            }
            this.$emit('input', this.roles)
        },
        setNSrole(event){
            this.role_ns = event
            this.$refs.modal.show()
            //this.$emit('input', this.roles)
        },
        refresh(){
            this.updating = true;
            this.$emit('update')
            setTimeout( () => {
			    this.updating = false
		    }, 2000);
        }
    },
    computed: {
        ns_roles(){
            if (this.nsroles){
                let ret = [];
                let vm = this
                this.nsroles.forEach( (value, key) => {
                    let item = {
                        key: key,
                        value: value.role_id
                    }
                    ret.push(item)
                })
                let arRet = Array.from(ret).sort( (a,b) => {
                    return a.value.toUpperCase() > b.value.toUpperCase() ? 1: -1
                })
                return arRet;
            }
            else
                return this.$options.ns_roles
        },
        asm_roles:{
            get(){
                if (this.asmroles){
                    let ret = [];
                    let vm = this
                    this.asmroles.forEach( (value, key) => {
                        let it = { role: key, enabled: this.roles.asm_roles.includes(key)}
                        ret.push(it)
                    })
                    let arRet = Array.from(ret).sort( (a,b) => {
                        return a.role.toUpperCase() > b.role.toUpperCase() ? 1: -1
                    })
                    return arRet;
                }
                else{
                    return []
                }
            },
        }
    }

}
</script>    
