<template>
  <div class="custom-dropdown-container" v-click-outside="closeDropdown">
    <div
      class="dropdown"
      :class="[
        {opened: isDropdownOpen},
        {disabled: isDropdownDisabled},
      ]"
      @click="switchDropdown"
    >
      <span class="dropdown-text">{{ getDropdownLabel(selectedValue) }}</span>
      <i
        class="now-ui-icons mr-1"
        :class="[ isDropdownOpen ? 'arrows-1_minimal-up' : 'arrows-1_minimal-down' ]"
      />
    </div>
    <div class="options" v-if="isDropdownOpen">
       <fg-input class="my-2">
          <el-input
            clearable
            class="dropdown-search"
            :placeholder="t('Start typing')"
            prefix-icon="el-icon-search"
            type="search"
            v-if="isShowSearch"
            v-model="searchValue"
          />
       </fg-input>
      <div v-if="defaultValue" @click="onItemClick(defaultValue)">
        <p class="subtitle">{{ t('default') }}</p>
        <div
          class="item"
          :class="{active: getDropdownValue(selectedValue) === getDropdownValue(defaultValue)}"
        >
          {{ getDropdownLabel(defaultValue) }} <i class="tim-icons icon-check-2 check-icon" />
        </div>
        <hr />
      </div>
      <p class="subtitle">{{ t('other') }}</p>
      <div
        class="item"
         :class="{active: getDropdownValue(selectedValue) === getDropdownValue(item)}"
        v-for="item in filteredDropdownItems"
        @click="onItemClick(item)"
        :key="getDropdownValue(item)"
      >
        {{ getDropdownLabel(item) }} <i class="tim-icons icon-check-2 check-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import permissions from '@/mixins/permissions'

export default {
  name: 'custom-dropdown',
  mixins: [permissions],
  props: {
    dropdownItems: {
      type: Array,
      default: () => [],
      required: true
    },
    selectedValue: String | Object,
    defaultValue: String | Object,
    isShowSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      searchValue: '',
    }
  },
  computed: {
    filteredDropdownItems() {
      if (this.isShowSearch) {
        return this.dropdownItems.filter(item => this.getDropdownLabel(item).toLowerCase().includes(this.searchValue.toLowerCase()));
      }
      return this.dropdownItems;
    },
    isDropdownDisabled() {
      return !this.permission_granted;
    }
  },
  methods: {
    onItemClick(item) {
      this.isDropdownOpen = false;
      this.$emit('selectValue', item);
    },
    switchDropdown() {
      this.isDropdownOpen = !this.isDropdownDisabled && !this.isDropdownOpen;
    },
    getDropdownLabel(dropdownItem) {
      if (typeof dropdownItem === 'object') {
        return dropdownItem.label || '';
      }

      return dropdownItem;
    },
    getDropdownValue(dropdownItem) {
      if (typeof dropdownItem === 'object') {
        return dropdownItem.value || '';
      }

      return dropdownItem;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    }
  },
}
</script>

<style lang="scss">
.custom-dropdown-container {
  position: relative;
  display: inline-block;
  width: 260px;
  color: #ffffff99;

  .dropdown {
    cursor: pointer;
    border: 1px solid #E2E5E6;
    border-radius: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &.opened {
      border-color: var(--primary);
    }
    &.disabled {
      cursor: default;
    }
  }

  p.subtitle {
    color: #B3B8C1;
    margin: 20px 0 10px;
    text-transform: uppercase;
  }

  .active, div.active:hover {
    background: #F4F4F6;
  }

  div.active .check-icon {
    opacity: 1;
  }

  .dropdown-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .options {
    position: absolute;
    width: 100%;
    border: 1px solid #E3E3E3;
    border-radius: 10px;
    padding: 15px;
    margin-top: 8px;
    background-color: #28293D;
    z-index: 10;
    max-height: 400px;
    overflow: auto;
  }

  .item {
    cursor: pointer;
    margin: 0 -15px;
    padding: 14px 25px;
    display: flex;
    justify-content: space-between;

    .check-icon {
      color: var(--primary);
      opacity: 0;
    }

    &:hover {
      background: #F4F4F6;
    }
  }

  .dropdown-search input{
    padding-inline-start: 30px;
  }
}

.white-content .custom-dropdown-container {
  color: #444444;

  .options {
    background-color: white;
  }
}
</style>
