<template>
  <div>
    <p class="mb-4">{{ t('colors description') }}</p>
    <b-row>
      <b-col sm="12" md="6">
        <color-card
          :isDefaultColor="isDefaultPrimaryColor"
          :resetColorText="resetPrimaryColorText"
          :popoverTitle="t('For good readability')"
          :popoverTextOne="t('Primary color must have good contrast')"
          :popoverTextTwo="t('Otherwise, button text will not be readable')"
          :title="t('Primary color')"
          :usageDescription="t('primary color usage')"
          :visible_for_permissions="visible_for_permissions"
          v-model="primaryColor"
          @resetColor="resetPrimaryColor"
        />
      </b-col>
      <b-col sm="12" md="6">
        <color-card
          :isDefaultColor="isDefaultSecondaryColor"
          :resetColorText="resetSecondaryColorText"
          :title="t('Secondary color')"
          :usageDescription="t('secondary color usage')"
          :visible_for_permissions="visible_for_permissions"
          v-model="secondaryColor"
          @resetColor="resetSecondaryColor"
        />
      </b-col>
      <b-col cols="12">
        <graphs-colors
          v-model="graphsColors"
          :graphsColorsWithShades="graphsColorsWithShades"
          :isGraphsColorsChanged="isGraphsColorsChanged"
          :visible_for_permissions="visible_for_permissions"
          @resetGraphsColors="resetGraphsColors"
        />
      </b-col>
      <b-col xl="3" md="4" xs="12" sm="6" class="ml-auto d-flex">
        <n-button
          block
          round
          type="primary"
          class="text-uppercase"
          :visible_for_permissions="visible_for_permissions"
          @click.native="saveChanges"
        >
          {{ t('Save color') }}
        </n-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createShadeColor } from "@/util/convertColors";
import ColorCard from './ColorCard';
import GraphsColors from './GraphsColors';
import permissions from '@/mixins/permissions';

export default {
  name: 'colors-tab',
  mixins: [permissions],
  components: {
    [ColorCard.name]: ColorCard,
    [GraphsColors.name]: GraphsColors,
  },
  data() {
    return {
      primaryColor: '',
      secondaryColor: '',
      graphsColors: {
        color1: '',
        color2: '',
        color3: '',
        color4: '',
        color5: '',
        color6: '',
      },
      isGraphsColorsChanged: false,
    }
  },
  computed: {
    ...mapGetters({
      initialPrimaryColor: 'generalManagement/customization/primaryColor',
      initialSecondaryColor: 'generalManagement/customization/secondaryColor',
      initialGraphsColors: 'generalManagement/customization/graphsColors',
      envOptions: 'generalManagement/customization/envOptions',
      defaultPrimaryColor: 'generalManagement/customization/defaultPrimaryColor',
      defaultSecondaryColor: 'generalManagement/customization/defaultSecondaryColor',
      defaultGraphsColors: 'generalManagement/customization/defaultGraphsColors',
    }),
    isDefaultPrimaryColor() {
      return this.primaryColor === this.defaultPrimaryColor;
    },
    isDefaultSecondaryColor() {
      return this.secondaryColor === this.defaultSecondaryColor;
    },
    resetPrimaryColorText() {
      return `${this.t('Reset to default')} ${this.defaultPrimaryColor}`;
    },
    resetSecondaryColorText() {
      return `${this.t('Reset to default')} ${this.defaultSecondaryColor}`;
    },
    graphsColorsWithShades() {
      return Object.keys(this.graphsColors).reduce((acc, color) => {
        acc[color] = [
          this.graphsColors[color],
          createShadeColor(this.graphsColors[color], 60),
          createShadeColor(this.graphsColors[color], 120)
        ];
        return acc;
      }, {});
    },
  },
  watch: {
    graphsColors: {
      handler(newValue) {
        this.isGraphsColorsChanged = this.checkIfColorsChanged(this.defaultGraphsColors, newValue);
      },
      deep: true,
    },
    defaultGraphsColors(newValue) {
      this.isGraphsColorsChanged = this.checkIfColorsChanged(newValue, this.graphsColors);
    }
  },
  methods: {
    checkIfColorsChanged(defaultColors, newColors) {
      return !_.isMatchWith(defaultColors, newColors, (defaultValue, newValue) => {
        if (defaultValue.toLowerCase() === newValue.toLowerCase()) {
          return true
        }
      })
    },
    saveChanges() {
      const data = {
        colors: {
          primary: this.primaryColor,
          secondary: this.secondaryColor,
          graphs: _.clone(this.graphsColors)
        }
      };

      this.$store
        .dispatch("generalManagement/customization/updateCustomization", {
          id: "acc",
          data: { env_options: data },
        })
        .then(() => {
          this.$store.commit("setSuccess", this.t("save_success"));
        });
    },
    resetGraphsColors() {
      this.graphsColors = _.clone(this.defaultGraphsColors);
      this.saveChanges();
    },
    resetPrimaryColor() {
      this.primaryColor = this.defaultPrimaryColor;
      this.saveChanges();
    },
    resetSecondaryColor() {
      this.secondaryColor = this.defaultSecondaryColor;
      this.saveChanges();
    },
  },
  mounted() {
    this.primaryColor = this.initialPrimaryColor;
    this.secondaryColor = this.initialSecondaryColor;
    this.graphsColors = _.clone(this.initialGraphsColors);
  },
}
</script>

