<template>
  <b-card>
    <template v-slot:header>
      <h4 id="identity_providers_list">
        <i class="mr-2"><svgicon class="icon" icon="item-sso"/></i>
        <span>{{t('identity-providers')}}</span>
      </h4>
    </template>

    <paginated-table  :id="`${guiStore}_paginatedTable`"
                      :rows='rows'
                      :columns='columns'
                      :actions='actions'
                      :updating="updating"
                      :withCreate="true"
                      :createButtonPermissions='[{actions: ["POST"], path: "/identity/providers/.*"}]'
                      @item-edit='handleEdit'
                      @status-change='handleStatusChange'
                      @item-delete='handleDelete'
                      @handle-new='handleNew'
    />

    <b-modal id="modal-status-providers"
            ref="modal-status-providers"
            :title="t('warning')"
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            @cancel="enableDisable.cancel"
            @ok="enableDisable.action">
      {{enableDisable.message}}
    </b-modal>
  </b-card>

</template>

<script>
import PaginatedTable from '@/components/Tables/PaginatedTable';
import Switch from '@/components/Tables/Formatters/NoFunctionalSwitch';
import { Form, FormItem, Input, Select, Option} from 'element-ui';

export default {
  name: 'identity-providers',
  components: {
    PaginatedTable,
    Form,
    FormItem,
    Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    guiStore: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      columns: [
        { prop: 'identity_provider_id', label: this.t('name'), minWidth: 180 },
        { prop: 'identity_provider_type', label: this.t('type'), minWidth: 180 },
        { prop: 'enabled', label: this.t('enabled'), minWidth: 100, formatter: Switch, visible_for_permissions: [{actions: ["PATCH"], path: "/identity/providers/.*"}]  }
      ],
      actions: {
        minWidth: 150,
        label: this.t('actions'),
        fixed: 'right',
        items: [
          {
            type: 'warning', icon: 'now-ui-icons business_badge',
            event: 'item-edit', action: this.t('Edit'), visible_for_permissions: [{actions: ["PATCH"], path: "/identity/providers/.*"}]
          },
          {
            type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove',
            action: this.t('Delete'), confirm: true, event: 'item-delete', message: this.t('are_you_sure?'),
            visibleIf: (row) => { return !row['system'] === true} , visible_for_permissions: [{actions: ["DELETE"], path: "/identity/providers/.*"}]
          },
        ]
      },
      identityProvider: {
        identity_provider_id: '',
        identity_provider_type: '',
        enabled: false
      },
      identityProviderISP: [
        { value: 'IdentityProviderLDAP', label: 'IdentityProviderLDAP' }
      ],
      identityProviderEntRes: [
        { value: 'IdentityProviderSSOMultipleAccount', label: 'IdentityProviderSSOMultipleAccount' }
      ],
      enableDisable: {
        updated: undefined,
        action: () => {},
        message: '',
        cancel: () => {}
      },
      gui: {
        isp: 'ISP',
        res: 'Residential',
        ent: 'Enterprise'
      },
      external_servers: new Map(),
      updating: false
    };
  },
  computed: {
    identityProviderOptions: {
      get() {
        return this.guiStore === 'isp'
          ? this.identityProviderISP
          : this.identityProviderEntRes
      }
    },
    rows() {
      const identityProviderList =  _.cloneDeep(this.$store.getters[`providers/${this.guiStore}ProviderList`]);
      return identityProviderList.map(row => ({...row, switchDisabled: this.isSwitchDisabled(row)}));
    }
  },
  methods: {
    isSwitchDisabled(row) {
      if (row.identity_provider_type === 'IdentityProviderLDAP') {
        const isNonDefaultProviderWithoutCluster = !row.ldap_cluster && !row.system;
        const isProviderWithoutActiveServer = row.ldap_cluster && !this.isClusterWithServersExists(row.ldap_cluster);
        return isNonDefaultProviderWithoutCluster || isProviderWithoutActiveServer;
      }
      return false;
    },
    isClusterWithServersExists(clusterName) {
      if (!this.external_servers.size) {
        return false;
      }

      for (let [key, value] of this.external_servers) {
        if (value.name === clusterName
          && value.servers.length
          && value.servers.some(server => server.status === 'on')) {
            return true;
        };
      }
      return false;
    },
    resetEnableDisableModal() {
      this.enableDisable = {
        updated: undefined,
        action: () => {},
        message: '',
        cancel: () => {}
      }
    },
    async handleDelete(data) {
      if (data.row.enabled) {
        this.enableDisable = {
          updated: data.row,
          action: this.resetEnableDisableModal,
          message: this.t('cant_remove_enabled'),
          cancel: () => {}
        }
        this.$refs['modal-status-providers'].show();
      } else {
        await this.$store.dispatch('providers/deleteProvider', { type: this.guiStore, provider: data.row });
      }
    },
    handleNew() {
      this.$router.push({
          name: 'General Management / Authentication / Identity Providers / New',
          params: {
            edit: false,
            type: this.guiStore == 'isp' ? 'IdentityProviderLDAP' : 'IdentityProviderSSOMultipleAccount',
            guiStore: this.guiStore
          },
        });
    },
    handleEdit(data) {
      this.$router.push({
          name: 'General Management / Authentication / Identity Providers / Edit',
          params: {
            edit: true,
            id: data.row.identity_provider_id,
            type: data.row.identity_provider_type,
            guiStore: this.guiStore
          },
        });
    },
    handleStatusChange(updatedRow) {
      this.$store.dispatch('providers/updateProviderStatus', { type: this.guiStore, provider: updatedRow, force: false })
        .then(() => {
          this.$store.dispatch('providers/getIsSystemSsoProviderEnabled', this.guiStore);
          this.resetEnableDisableModal();
        })
        .catch(err => {
          if (err.enableds) {
            this.enableDisable = {
              updated: updatedRow,
              action: async() => {
                this.$store.dispatch('providers/updateProviderStatus', { type: this.guiStore, provider: updatedRow, force: true }).then(() =>{}).catch(error => {                  
                  if(!error.enableds)
                    updatedRow.enabled = !updatedRow.enabled;
                })
              },
              message: err.cause === 'twoOrMoreEnableds'
                ? this.t('want_disable_others')
                : `${this.t('want_disabled_all')} ${this.gui[this.guiStore]} ${this.t('want_disabled_all_2')}`,
              cancel: this.cancelEnableDisable
            }
            this.$refs['modal-status-providers'].show();
          } else {
            updatedRow.enabled = !updatedRow.enabled;
          }
        })
    },
    cancelEnableDisable() {
      this.enableDisable.updated.enabled = !this.enableDisable.updated.enabled;
    },
    getRowsData() {
      try { 
        this.$store.dispatch('providers/getProviderList', this.guiStore);
      } catch(e) {
        console.error('Error getting rows data--> ', e)
      }
    }
  },
  created() {
    this.updating = true;
    this.getRowsData();
    if (this.$store.getters[`generalManagement/administrators/asm_roles`].size == 0) {
      this.$store.dispatch("generalManagement/administrators/getRoles")
    }
    let _vm = this;
    this.$store
      .dispatch("generalManagement/externalServers/getExternalServers")
      .then((res) => {
        _vm.external_servers = res.items;
        this.updating = false;
      })
      .catch((err) => {
        if (this.external_servers.size === 0) {
          _vm.external_servers = this.$store.getters[
            "generalManagement/externalServers/externalServers"
            ];
        }
        this.updating = false;
      });
  },
};
</script>
<style lang="scss">
  .identity-provider {
    label {
      margin-bottom: 0em;
      padding-bottom: 0.2em !important;
    }
    input {
      border-radius: 50px;
    }
  }
</style>
