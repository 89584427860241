<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-card>
      <tabs
        ref="custom-tabs"
        id="custom-tabs"
        type="primary"
        v-model="active_tab"
        centered
      >
        <tab-pane id="ISP" :label="this.t('ISP')">
        <span slot="label">
          <i class="now-ui-icons business_bank"></i>
        </span>
          <b-container
            v-if="active_tab === 'ISP'"
            fuild
            style="max-width: 2560px"
          >
            <b-row>
              <b-col cols="12" class="entranceFromLeft row-eq-height">
                <text-customization
                  v-model="textsAndLanguagesModel"
                  class="w-100"
                  :customizationType="getActiveTab()"
                  :visible_for_permissions="[{ actions: ['PATCH', 'PUT'], path: '/customization/.*' }]"
                />
              </b-col>
              <b-col cols="12" class="entranceFromRight row-eq-height">
                <images-form
                  id="images-isp"
                  v-model="images"
                  type="isp"
                  @update="updateImage($event, 'isp')"
                  @reset="resetImage($event, 'isp')"
                  :visible_for_permissions="[
                  { actions: ['PATCH', 'PUT'], path: '/customization/.*' },
                ]"
                  :localized_text="localized_text"
                >
                  <div slot="header">
                    <h5 class="title">
                      <i class="tim-icons icon-camera-18 mr-2" />{{
                        t("Custom Pictures")
                      }}
                    </h5>
                    <div>{{ t("It is recommended the use of PNG images") }}</div>
                  </div>
                </images-form>
              </b-col>
            </b-row>
          </b-container>
        </tab-pane>
        <tab-pane
          id="Enterprise"
          v-if="$store.getters['settings/isIOT']"
          :label="this.t('Enterprise')"
        >
        <span slot="label">
          <i class="now-ui-icons business_briefcase-24"></i>
        </span>
          <b-container
            v-if="active_tab === 'Enterprise'"
            fuild
            style="max-width: 2560px"
          >
            <b-row>
              <b-col cols="12" class="entranceFromLeft row-eq-height">
              <text-customization
                v-model="textsAndLanguagesModel"
                class="w-100"
                :customizationType="getActiveTab()"
                :visible_for_permissions="[{ actions: ['PATCH', 'PUT'], path: '/customization/.*' }]"
              />
              </b-col>
              <b-col cols="12" class="entranceFromRight row-eq-height">
                <images-form
                  id="images-ent"
                  v-model="images"
                  type="enterprise"
                  @update="updateImage($event, 'ent')"
                  @reset="resetImage($event, 'ent')"
                  :visible_for_permissions="[
                  { actions: ['PATCH', 'PUT'], path: '/customization/.*' },
                ]"
                  :localized_text="localized_text"
                >
                  <div slot="header">
                    <h5 class="title">
                      <i class="tim-icons icon-camera-18 mr-2" />{{
                        t("Custom Pictures")
                      }}
                    </h5>
                    <div>{{ t("It is recommended the use of PNG images") }}</div>
                  </div>
                </images-form>
              </b-col>
            </b-row>
          </b-container>
        </tab-pane>
        <tab-pane
          id="Residential"
          v-if="!$store.getters['settings/isIOT']"
          :label="this.t('Residential')"
        >
        <span slot="label">
          <i class="now-ui-icons users_single-02"></i>
        </span>
          <b-container
            v-if="active_tab === 'Residential'"
            fuild
            style="max-width: 2560px"
          >
            <b-row>
              <b-col cols="12" class="entranceFromLeft row-eq-height">
                <text-customization
                  v-model="textsAndLanguagesModel"
                  class="w-100"
                  :customizationType="getActiveTab()"
                  :visible_for_permissions="[{ actions: ['PATCH', 'PUT'], path: '/customization/.*' }]"
                />
              </b-col>
              <b-col cols="12" class="entranceFromRight row-eq-height">
                <images-form
                  id="images-tenant"
                  v-model="images"
                  type="tenant"
                  @update="updateImage($event, 'acc')"
                  @reset="resetImage($event, 'acc')"
                  :visible_for_permissions="[
                  { actions: ['PATCH', 'PUT'], path: '/customization/.*' },
                ]"
                  :localized_text="localized_text"
                >
                  <div slot="header">
                    <h5 class="title">
                      <i class="tim-icons icon-camera-18 mr-2" />
                      {{ t("Custom Pictures") }}
                    </h5>
                    <div>{{ t("It is recommended the use of PNG images") }}</div>
                  </div>
                </images-form>
              </b-col>
              <b-col cols="12" class="entranceFromLeft row-eq-height border-top">
                <ui-customization class="w-100" />
              </b-col>
            </b-row>
          </b-container>
        </tab-pane>
      </tabs>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import ImagesForm from "./ImagesForm";
import TextsForm from "./TextsForm";
import LanguagesForm from "./LanguagesForm";
import UICustomization from "./UICustomization";
import TextCustomization from "./TextCustomization";
import { TabPane, Tabs } from "@/components";
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";
import _ from "lodash";
import { DEFAULT_BLOCKING_PAGE_TEXTS } from "@/util/languageUtils";

export default {
  name: "customization",
  components: {
    ImagesForm,
    TextsForm,
    LanguagesForm,
    TabPane,
    Tabs,
    PageTitleCard,
    [UICustomization.name]: UICustomization,
    [TextCustomization.name]: TextCustomization
  },
  data() {
    return {
      updating: false,
      customization: {},
      active_tab: "ISP",
      enabledLanguages: [],
      defaultLanguagesSetting: [
        { "language_id": "en-US", "enabled": true },
        { "language_id": "fr-FR", "enabled": false },
        { "language_id": "de-DE", "enabled": false },
        { "language_id": "es-ES", "enabled": false },
        { "language_id": "ar-OM", "enabled": false },
        { "language_id": "ru-RU", "enabled": false },
        { "language_id": "uk-UA", "enabled": false },
        { "language_id": "pt-PT", "enabled": false },
        { "language_id": "he-IL", "enabled": false }
      ],
      defaultBlockingPagesTexts: DEFAULT_BLOCKING_PAGE_TEXTS
    };
  },
  tabs: {
    ISP: {
      id: "isp",
    },
    Enterprise: {
      id: "ent",
    },
    Residential: {
      id: "acc",
    },
  },
  computed: {
    textsAndLanguagesModel: {
      get() {
        return {
          textsCustomization: this.textsCustomization,
          languagesCustomization: this.languagesCustomization
        }
      },
      set(newValue) {
        const type = this.getActiveTab();
        const {textsCustomization, languagesCustomization} = newValue;
        let update = this.createTextCustomizationObject(textsCustomization, type);
        if (type === 'acc') {
          _.set(update, 'languages', languagesCustomization);
        }
        this.update(update, this.getActiveTab());
      }
    },
    textsCustomization() {
      const type = this.getActiveTab();
      return this.createTextCustomizationObject(this.customization[type], type);
    },
    languagesCustomization() {
      const type = this.getActiveTab();
      let customizationLanguages = [];
      const customizationLanguagesAvailable =
        !!(this.customization && this.customization[type] && this.customization[type].languages);

      customizationLanguagesAvailable
        ? customizationLanguages = [...this.customization[type].languages]
        : customizationLanguages = [...this.defaultLanguagesSetting];

      if (this.customization[type]) {
        this.customization[type].languages = customizationLanguages;
      };
      return customizationLanguages;
    },
    images: {
      get() {
        let type = this.getActiveTab();
        return this.createImagesCustomizationObject(this.customization[type], type);
      },
      set(newValue) {
        let update = {
          show_footer_image:
            newValue.show_footer_image == undefined
              ? true
              : newValue.show_footer_image,
          show_footer_text: this.customization.show_footer_text,
        };
        this.update(update, this.getActiveTab());
      },
    },
    localized_text: {
      get() {
        return {
          change: this.t('Set Image'),
          remove: this.t('Remove Image'),
          tap: '<br><br><br><br><br>' + this.t('Tap here to set an image'),
          drag: this.t('Set Image')};
      }
    }
  },
  mounted() {
    Object.keys(this.$options.tabs).forEach((tab) =>
      this.refresh(this.$options.tabs[tab].id)
    );
  },
  methods: {
    async refresh(type) {
      let _vm = this;
      this.updating = true;
      this.$store
        .dispatch("generalManagement/customization/getCustomization", type)
        .then((res) => {
          Vue.set(_vm.customization, type, res);
          this.updating = false;
        });
    },
    async update(data, type) {
      let _vm = this;
      this.$store
        .dispatch("generalManagement/customization/updateCustomization", {
          id: type,
          data: data,
        })
        .then(() => {
          let tmp = _.cloneDeep(_vm.customization[type]);
          _.merge(tmp, data);
          Vue.set(_vm.customization, type, tmp);
          this.$store.commit("setSuccess", this.t("save_success"));
        });
    },
    updateImage(event, type) {
      let data = {
        show_footer_text: this.customization[type].show_footer_text,
      };
      if (['isp', 'ent'].includes(type)) {
        data['show_footer_image'] = this.customization[type].show_footer_image;
      }
      let img_id = event.image;
      data[img_id] = event.data;
      this.update(data, type);
    },
    resetImage(event, type) {
      this.updateImage({ image: event, data: null }, type);
    },
    getActiveTab() {
      let _vm = this;
      let ret = this.$store.getters["settings/isIOT"] ? "ent" : "acc";
      Object.keys(this.$options.tabs).forEach((tab) => {
        if (_vm.active_tab === tab) ret = _vm.$options.tabs[tab].id;
      });
      return ret;
    },
    createTextCustomizationObject(data, type) {
      const object = {
        app_name: _.get(data, 'app_name', null),
        company_name: _.get(data, 'company_name', null),
        company_url: _.get(data, 'company_url', null),
        show_footer_text: _.get(data, 'show_footer_text', true),
      }
      if (type === 'acc') {
        object['env_options'] = {
          visibility: {
            acc_show_add_users_button: _.get(
              data, 'env_options.visibility.acc_show_add_users_button', true
            )
          },
          blocking_pages: _.get(
            data, 'env_options.blocking_pages', this.defaultBlockingPagesTexts
          )
        }
      }
      return object;
    },
    createImagesCustomizationObject(data, type) {
      const object = {
        image_background: _.get(data, 'image_background', null),
        image_logo: _.get(data, 'image_logo', null),
        image_favicon: _.get(data, 'image_favicon', null),
      }
      if (['isp', 'ent'].includes(type)) {
        object['image_footer'] = _.get(data, 'image_footer', null);
        object['show_footer_image'] = _.get(data, 'show_footer_image', true);
      }
      return object;
    }
  },
};
</script>
