import _ from "lodash";

export default {
    // ! TODO: REMOVE GENERAL MIXIN FORM CB COMPS FOLDER
	data() {
		return {
            validations: {
                // ! MOVE TO CONFIG FILE
                common: {
                    required: true,
                    numeric: true,
                },
                max99: {
                    required: true,
                    numeric: true,
                    max_value: 99,
                    min_value: 0
                },
                max512char: {
                    max: 512,
                },
                max80char: {
                    max: 80
                },
                max20: {
                    required: true,
                    numeric: true,
                    max_value: 20,
                    min_value: 0
                },
                url: 'url',
                email: 'email'
            },
		}
	},
	methods: {
        onKeyPress(evt) {
            const event = evt || window.event;
            const charCode = event.which || event.keyCode;

            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            } else {
                return true;
            }
        },
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
	}
}
