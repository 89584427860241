<template>
  <b-container id="gm-external-servers-edit" fuild style="max-width: 2560px">
    <form @submit.prevent="save">
      <b-card class="card-user" no-body>
        <div class="author">
          <div class="block block-one"></div>
          <div class="block block-three"></div>
          <div class="block block-four"></div>
        </div>
        <b-row>
          <b-col align-self="center">
            <h4 class="gm-external-servers-header">
              <span>{{ clusterName }}</span>
            </h4>
          </b-col>
          <b-col md="4" lg="3" xl="2" class="ml-auto header-buttons">
            <b-row class="button-padding">
              <n-button
                id="save-cluster-server-button"
                @click.native="handleSave"
                type="primary"
                size="md"
                round
                block
                :visible_for_permissions='[{actions: ["PATCH", "POST"], path: "/external-servers/.*/servers"}]'
              >
                {{ t("save") }}
              </n-button>
            </b-row>
            <b-row class="button-padding">
              <n-button
                id="cancel-cluster-server-creation"
                @click.native="handleCancel"
                type="primary"
                size="md"
                round
                block
              >
                {{ t("back") }}
              </n-button>
            </b-row>
          </b-col>
        </b-row>
        <div class="hr-grey mb-2" />
        <template v-if="!newServer">
          <div @click="$refs.modal.show()" v-if="!updating" class="refresh">
            <i class="now-ui-icons arrows-1_refresh-69" /> {{ t("Update now") }}
          </div>
          <div v-else class="refresh">
            <i class="now-ui-icons loader_refresh spin" />
            {{ t("Updating...") }}
          </div>
        </template>
        <b-col md="4" lg="3" xl="2" class="ml-auto"> </b-col>
      </b-card>
      <b-row style="padding: 0">
        <b-col md="12" lg="8">
          <server-form
            :server="server"
            :newServer="newServer"
            :clusterName="clusterName"
            :clusterType="clusterType"
            @server-change="(event, type) => handleServerChange(event, type)"
            @load-predefined-values="loadPredefinedValues"
          />
        </b-col>
        <b-col md="12" lg="4">
          <administrator-form
            :server="server"
            :clusterName="clusterName"
            :clusterType="clusterType"
            @administrator-change="
              (data, key) => handleAdministratorChange(data, key)
            "
          />
        </b-col>
      </b-row>
      <b-row style="padding: 0">
        <b-col md="12" lg="8">
          <users-form
            :server="server"
            :clusterName="clusterName"
            :clusterType="clusterType"
            @users-change="(data, key) => handleUsersChange(data, key)"
          />
        </b-col>
        <b-col md="12" lg="4">
          <groups-form
            :server="server"
            :clusterName="clusterName"
            :clusterType="clusterType"
            @groups-change="(data, key) => handleGroupsChange(data, key)"
          />
        </b-col>
      </b-row>
      <b-modal
        id="modal-new"
        ref="modal"
        :title="clusterName"
        @ok="handleOkModal"
        @show="$refs.modal.hide()"
        @hidden="$refs.modal.hide()"
      >
        {{ t("are-you-sure") }}
      </b-modal>
    </form>
  </b-container>
</template>

<script>
import ServerForm from "./components/ServerForm";
import UsersForm from "./components/Users";
import Administrator from "./components/Administrator";
import Groups from "./components/Groups";
import bus from "./components/bus";

export default {
  name: "general-mng-ldap-server-edit",
  components: {
    "server-form": ServerForm,
    "users-form": UsersForm,
    "administrator-form": Administrator,
    "groups-form": Groups,
  },
  data() {
    return {
      updating: false,
      newServer: true,
      server: {
        name: "",
        location: "",
        type: "",
        port: 636,
        mode: "primary",
        status: "on",
        encryption: "none",
        ldap: "w2k",
        base: "",
        dn: "",
        users: {
          get: "-",
        },
        groups: {},
      },
      serverName: "",
      clusterName: "",
      clusterType: "",
    };
  },
  methods: {
    handleSave() {
      this.save();
    },
    handleCancel() {
      this.$router.back();
    },
    handleServerChange(data, key) {
      if (key === "status") this.server.status = data ? "on" : "off";
      else if (key === "port") this.server.port = +data;
      else this.server[key] = data;
    },
    handleUsersChange(data, key) {
      this.server.users[key] = data;
    },
    handleAdministratorChange(data, key) {
      this.server[key] = data;
    },
    handleGroupsChange(data, key) {
      if (key === "nested") this.server.groups.nested = +data;
      else this.server.groups[key] = data;
    },
    handleOkModal() {
      this.getServer();
      this.$refs.modal.hide();
    },
    loadPredefinedValues() {
      this.server = {
        ...this.server,
        users: {
          alias: "proxyAddresses",
          email: "mail",
          filter: "",
          get: "-",
          ha1: "",
          members: "",
          name: "sAMAccountName",
          objects: "(objectCategory=person)",
        },
        groups: {
          members: "(member=%dn%)",
          name: "cn",
          nested: 0,
          objects: "(objectCategory=group)",
        },
      };
    },
    async save() {
      console.log("this.newServer: ", this.newServer);
      console.log("this.clusterName: ", this.clusterName);
      console.log("this.server", this.server);
      let isValid = await this.$validator.validateAll();
      if (isValid) {
        if (this.newServer) {
          if (await this.checkIfUniqueName()) {
            this.$store
              .dispatch("generalManagement/externalServers/createServer", {
                cluster_name: this.clusterName,
                data: this.server,
              })
              .then(
                (response) => {
                  this.newServer = false;
                  this.$store.commit("setSuccess", this.t("save_success"));
                },
                (error) => {
                  switch (error.type) {
                    case "EXTERNALSERVER.INVALID_CERTIFICATE":
                      this.$store.commit(
                        "setError",
                        this.t("Invalid certificate")
                      );
                      break;
                    default:
                      this.$store.commit(
                        "setError",
                        this.server.name + " " + this.t(error.type)
                      );
                  }
                }
              );
          } else {
            this.$store.commit(
              "setError",
              `${this.t("server_name")} ${this.server.name} ${this.t(
                "already_exists"
              )}`
            );
          }
        } else {
          this.$store
            .dispatch("generalManagement/externalServers/updateServer", {
              clusterId: this.clusterName,
              serverName: this.serverName,
              serverData: this.server,
            })
            .then(
              (response) => {
                this.$store.commit("setSuccess", this.t("save_success"));
              },
              (error) => {
                switch (error.type) {
                  case "EXTERNALSERVER.INVALID_CERTIFICATE":
                    this.$store.commit(
                      "setError",
                      this.t("Invalid certificate")
                    );
                    break;
                  default:
                    this.$store.commit(
                      "setError",
                      this.server.name + " " + this.t(error.type)
                    );
                }
              }
            );
        }
      }
    },
    async getServer() {
      try {
        await this.$store.dispatch(
          "generalManagement/externalServers/getServer",
          {
            clusterId: this.clusterName,
            serverName: this.serverName,
          }
        );
        this.server = _.cloneDeep(
          this.$store.getters["generalManagement/externalServers/getServer"]
        );
      } catch (e) {
        console.error("Error getting server--> ", e);
        this.$router.replace({ name: "General Management / External Servers" });
      }
    },
    async checkIfUniqueName() {
      const cluster = await this.$store.dispatch(
        "generalManagement/externalServers/getCluster",
        this.clusterName
      );
      let serversNames;
      if (cluster && cluster.servers)
        serversNames = cluster.servers.map((s) => s.name);
      else return false;
      if (!serversNames.includes(this.server.name)) return true;
      else {
        this.errors.add({
          field: "Server Name",
          msg: "Server name must be unique",
        });
        return false;
      }
    },
  },
  created() {
    if (this.$route.params.cluster_type.includes("ldap")) {
      this.clusterType = 'ldap';
      this.server.type = 'ldap';
    }
    this.clusterName = this.$route.params.cluster_name;
    const serverName = this.$route.params.server_name;
    if (serverName && serverName !== "") {
      this.newServer = false;
      this.serverName = serverName;
      this.getServer();
    }
  },
  mounted() {
    //Emit that validation is required on the bus
    this.$on("veeValidate", () => {
      bus.$emit("validate");
    });

    //Listen on the bus for changers to the child components error bag and merge in/remove errors
    bus.$on("errors-changed", (newErrors, oldErrors) => {
      newErrors.forEach((error) => {
        if (!this.errors.has(error.field))
          this.errors.add(error.field, error.msg);
      });
      if (oldErrors)
        oldErrors.forEach((error) => {
          this.errors.remove(error.field);
        });
    });
  },
  beforeDestroy() {
    this.$store.dispatch("generalManagement/externalServers/resetLocalServer", 'ldap');
  },
};
</script>

<style lang="scss" scoped>
.gm-external-servers-header {
  margin-left: 1em;
  span {
    margin-left: 1em;
  }
}
.button-padding {
  padding: 0% 15%;
}
.refresh {
  cursor: pointer;
  margin-left: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.row-height {
  height: 25%;
}
</style>
