<template>
    <b-card class="card-user" no-body>
        <div class="author">
            <div class="block block-one"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>
        </div>

        <b-card-body style="margin-top: -30px; min-height: 75px">
            <b-row>
                <b-col cols="12">
                    <h5 class="title">
                        <i class="mr-2"><svgicon class="icon" icon="groups" /></i>{{ t("Account Jobs") }}
                    </h5>
                </b-col>
            </b-row>
        </b-card-body>

        <b-container fuild style="max-width: 2560px;" >
            <b-row no-gutters align-h="between">
                <b-col md="12" class="pt-2">{{t('account_jobs_text')}}</b-col>
            </b-row>
        </b-container>

        <b-card-body style="padding: 0px;">
            <paginated-table :rows="rows"
                            :columns="columns"
                            :updating="updating"
                            :search="[
                                        {value: 'job_id', key: 'job_id', label: t('job_id')},
                                        {value: 'account_id', key: 'account_id', label: t('account_id')},
                                        {value: 'group_id', key: 'group_id', label: t('group_id')},
                                        {
                                            value: 'status', key: 'status', label: t('status'),
                                            isExtended:true, extendedOptions: statusOptions, hideTextInput: true
                                        },
                                        {
                                            value: 'action', key: 'action', label: t('action'),
                                            isExtended:true, extendedOptions: actionOptions, hideTextInput: true
                                        }
                                    ]"
                            v-model="search_field_value"
                            :itemsPerPage="query.limit"
                            :serverpagination="search_pagination"
                            @pagination="HandlePagination"
                            @prev="handlePrevPage" @next="handleNextPage"
            >
                <template v-slot:expand="props">
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col cols="10">
                            <h5>{{t('Logs')}}:</h5>
                            <b-row v-for="log in props.row.logs" :key="log">
                                <b-col cols="8">
                                    <span>{{ toLocaleString(datetimeFromISO(log.substring(0,log.indexOf(' - ')))) }}</span>
                                    <span class="log-message">{{ log.substring(log.indexOf(' - ') + 3) }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </template>
            </paginated-table>
        </b-card-body>

        <template v-slot:footer>
            <div class="hr-grey mb-2"/>
            <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
                <i class="now-ui-icons arrows-1_refresh-69" />
                {{ t('Update now') }}
            </div>
            <div v-else>
                <i class="now-ui-icons loader_refresh spin"/>
                {{ t('Updating...') }}
            </div>
        </template>
    </b-card>
</template>

<script>
    import { PaginatedTable } from '@/components';
    import { DateTimeFormatter, ProgressFormatter } from '@/components/Tables/Formatters'

    export default {
        name: "gm_account_jobs",
        components: {
            PaginatedTable
        },
        data() {
            return {
                jobs: [],
                updating: false,
                query: {
                    sort: 'asc',
                    limit: 10
                },
                self_query: {},
                currentPage: 1,
                nextPage: 0,
            };
        },
        async mounted() {
            this.refresh(0)
        },
        computed: {
            rows(){
                let ret = [];
                this.jobs.forEach((job) => {
                    let row = {
                        job_id: job.job_id,
                        description: job.context.action,
                        created_at: this.toLocaleString(this.datetimeFromISO(job.created_at)),
                        account_id: job.context.account_id,
                        group_id: job.context.group_id,
                        status: job.execution.status,
                        updated_at: this.toLocaleString(this.datetimeFromISO(job.execution.updated_at)),
                        total: job.execution.total,
                        processed: job.execution.processed,
                        updated: job.execution.updated,
                        progress: job.execution.status === 'finished'
                            ? 100 
                            : job.execution.total === 0
                                ? 0 
                                : parseInt(job.execution.processed * 100 / job.execution.total),
                        logs: job.execution.logs
                    }
                    ret.push(row)
                })
                return ret;
            },
            search_field_value: {
                get(){
                    if( this.query && this.query.field_value)
                        return { field: 'job_id', value: this.query.field_value}
                    else
                        return  { field: 'job_id', value: ''}
                },
                set(newValue){
                    this.handleSearch(newValue)
                }
            },
            search_pagination: {
                get(){
                    return { prev: !!this.query && !!this.query.prev_id, 
                            next: !!this.query && !!this.query.next_id,
                            currentPage: this.currentPage }
                }
            },
            columns(){
                return [
                    {
                        prop: 'job_id',
                        label: this.t('job_id'),
                        minWidth: 150,
                    },
                    {
                        prop: 'logs',
                        type : 'expand',
                        minWidth: 50,
                    },
                    {
                        prop: 'description',
                        label: this.t('description'),
                        minWidth: 180,
                        translateCells: true
                    },
                    {
                        prop: 'created_at',
                        label: this.t('created_at'),
                        minWidth: 195,
                        formatter: DateTimeFormatter
                    },
                    {
                        prop: 'account_id',
                        label: this.t('account_id'),
                        minWidth: 150,
                    },
                    {
                        prop: 'group_id',
                        label: this.t('group_id'),
                        minWidth: 150,
                    },
                    {
                        prop: 'status',
                        label: this.t('status'),
                        minWidth: 130,
                        translateCells: true
                    },
                    {
                        prop: 'updated_at',
                        label: this.t('last_update'),
                        minWidth: 195,
                        formatter: DateTimeFormatter
                    },
                    {
                        prop: 'total',
                        label: this.t('total'),
                        minWidth: 130,
                    },
                    {
                        prop: 'processed',
                        label: this.t('processed'),
                        minWidth: 130,
                    },
                    {
                        prop: 'updated',
                        label: this.t('updated'),
                        minWidth: 130,
                    },
                    {
                        prop: 'progress',
                        label: this.t('progress'),
                        minWidth: 180,
                        formatter: ProgressFormatter
                    }
                ]
            },
            statusOptions() {
                return [
                    {value : 'not_started', key : 'not_started', label : this.t('not_started')},
                    {value : 'running', key : 'running', label : this.t('running')},
                    {value : 'finished', key : 'finished', label : this.t('finished')},
                    {value : 'cancelled', key : 'cancelled', label : this.t('cancelled')},
                    {value : 'failed', key : 'failed', label : this.t('failed')}
                ]
            },
            actionOptions() {
                return [
                    {value : 'delete_account', key : 'delete_account', label : this.t('delete_account')},
                    {value : 'delete_group', key : 'delete_group', label : this.t('delete_group')},
                    {value : 'add_group_devices', key : 'add_group_devices', label : this.t('add_group_devices')},
                    {value : 'delete_group_devices', key : 'delete_group_devices', label : this.t('delete_group_devices')}
                ] 
            }            
        },
        methods: {
            refresh(wait){
                this.updating = true;
                this.$store.dispatch('generalManagement/jobs/getAccountJobs', { wait: wait, query: this.query})
                    .then( res => {
                        this.jobs = res.items
                        this.query = res.query
                        this.self_query = res.self
                        this.currentPage += this.nextPage
                        this.updating = false
                    })
                    .catch( err => {
                        this.updating = false
                    });
            },
            reload(){
                if (this.self_query){
                    this.query = this.self_query
                    this.nextPage = 0;
                    this.refresh()
                }
            },
            handlePrevPage(){
                if (this.query){
                    ['next_id', 'next_name'].forEach( item => {
                        if ( this.query[item])
                        delete this.query[item]
                    });
                    if ( this.query.prev_id){
                        this.nextPage = -1
                        this.refresh()
                    }
                }
            },
            handleNextPage(){
                if (this.query){
                    ['prev_id', 'prev_name'].forEach( item => {
                        if ( this.query[item])
                        delete this.query[item]
                    });
                    if ( this.query.next_id){
                        this.nextPage = 1
                        this.refresh()
                    }
                }
            },
            HandlePagination(value){
                this.currentPage = 1;
                this.self_query.limit = value;
                ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
                    if ( this.self_query[item])
                    delete this.self_query[item]
                });
                this.reload()
            },
            handleSearch: _.debounce(function (value) {
                this.currentPage = 1
                this.nextPage = 0;
                if ( value){
                    this.query.field_operator = 'eq'
                    this.query.field_name = value.field;
                    this.query.field_value = value.value;
                }
                else{
                    ['field_operator', 'field_name', 'field_value'].forEach( item => {
                        if ( this.query[item]) delete this.query[item]
                    });                
                };
                ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
                    if ( this.query[item]) delete this.query[item]
                });
                this.refresh();
            }, 500),
        },
    };
</script>
<style>

</style>