<template>
  <div>
    <n-button
        simple
        round
        type="primary"
        class="text-uppercase"
        :visible_for_permissions="visible_for_permissions"
        @click.native="toggleUploadModal"
    >
      <i class="now-ui-icons arrows-1_cloud-upload-94 mr-1" />  {{t('Upload font')}}
    </n-button>
    <b-modal
      centered
      id="upload-modal"
      v-model="isShowUploadModal"
      :title="t('Upload a font')"
      content-class="upload-modal"
      header-close-variant="dark"
      header-class="upload-modal-header"
    >
      <p class="mb-4">{{t("Please, upload a font")}}</p>
      <div class="upload-container">
        <div
          class="upload-area"
          :class="{drag: dragging}"
          @dragenter="dragging=true"
          @dragend="dragging=false"
          @drop="dragging=false"
          @dragleave="dragging=false"
        >
          <p v-if="filename">{{ filename }}</p>
          <div v-else>
            <svgicon class="upload-icon" icon="upload" />
            <p>
              <span class="primary-text">{{t("Choose a file")}}</span> {{t("or drag it here")}}
            </p>
          </div>
          <input
            id="file"
            type="file"
            class="file"
            accept=".ttf, .otf"
            @input="updateValue"
          >
        </div>
        <span>{{t("Only TTF or OTF files supported")}}</span>
      </div>
      <p class="my-4">{{t("For non supported languages")}}</p>
      <template #modal-footer>
        <b-row align-h="between" class="w-100">
          <n-button
            type="neutral"
            class="text-uppercase"
            @click.native="toggleUploadModal"
          >
            {{t('Cancel')}}
          </n-button>
          <n-button
            block
            round
            type="primary"
            class="text-uppercase"
            @click.native="saveValue"
          >
            {{t('Upload')}}
          </n-button>
        </b-row>
      </template>
    </b-modal>
    <b-modal
      centered
      id="warning-modal"
      v-model="isShowWarningModal"
      :title="t('warning')"
      content-class="upload-modal"
      header-close-variant="dark"
      header-class="upload-modal-header"
      v-if="uploadedFontConfig"
    >
      <p class="mb-4">{{t("The font family is not compatible")}}</p>
      <p class="subtitle">{{t("Security Manager Languages")}}</p>
      <p
        v-for="{ languageId, languageName, compatibleText, isCompatible} in enabledLanguages"
        :key="languageId"
      >
        <span class="language-name">{{ languageName }}</span>
        <span
          class="not-compatible"
          :class="{ compatible: isCompatible }"
        >
          {{ compatibleText }}
        </span>
      </p>

      <p class="bottom-text">{{t("For non supported languages")}}</p>
      <template #modal-footer>
        <n-button
          block
          round
          type="primary"
          class="text-uppercase ok-button"
          @click.native="closeWarningModal"
        >
          {{t('OK')}}
        </n-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SUPPORTED_LANGUAGES } from "@/util/languageUtils";
import permissions from '@/mixins/permissions';

export default {
    name: 'upload-font',
    mixins: [permissions],
    props: {
      text: {
        type: String,
        default: 'Upload',
      },
      value: File,
    },
    data() {
      return {
        dragging: false,
        isShowUploadModal: false,
        isShowWarningModal: false,
        uploadedFont: null,
        filename: '',
      }
    },
    computed: {
      ...mapGetters('generalManagement/customization', ['accLanguages', 'uploadedFontConfig']),
      enabledLanguages() {
        return this.accLanguages.reduce((acc, language) => {
          if(language.enabled) {
            const languageId = language.language_id;
            const isCompatible = this.checkLanguageCompatible(languageId)

            acc.push({
              languageId: language.language_id,
              languageName: this.getLanguageName(languageId),
              compatibleText: isCompatible ? this.t('Compatible') : this.t('Not compatible'),
              isCompatible,
            })
          }

          return acc;
        }, [])
      }
    },
    watch: {
      uploadedFontConfig(newVal) {
        if (newVal) {
          this.isShowWarningModal = true;
        }
      }
    },
    methods: {
      toggleUploadModal() {
        this.isShowUploadModal = !this.isShowUploadModal;
        this.filename = '';
        this.uploadedFont = null;
      },
      saveValue() {
        this.$emit('input', this.uploadedFont);
        this.toggleUploadModal();
      },
      updateValue(e) {
        this.uploadedFont = e.target.files[0];
        this.filename = this.uploadedFont.name.split(".")[0]
      },
      closeWarningModal() {
        this.isShowWarningModal = false;
        this.$store.dispatch("generalManagement/customization/clearUploadedFile");
      },
      checkLanguageCompatible(language) {
        if(this.uploadedFontConfig.supported_languages.includes(language)) {
          return true
        }

        return false
      },
      getLanguageName(language) {
        const languageConfig = SUPPORTED_LANGUAGES.find((lang) => {
          return lang.language_id === language
        })

        if (languageConfig) {
          return languageConfig.language_name
        }
      }
    }
}
</script>

<style lang="scss">
div.upload-modal {
  border-radius: 20px;

  .language-name {
    width: 120px;
    margin-inline-start: 20px;
    display: inline-block;
  }

  .subtitle {
    text-transform: uppercase;
    color: #B3B8C1;
    margin-bottom: 50px;
  }

  .not-compatible {
    color: #EF4A38;
    text-transform: uppercase;
  }

  .compatible {
    color: #B3B8C1;
  }
  .ok-button {
    margin-inline-start: auto;
  }

  .bottom-text {
    margin: 50px 0 30px;
  }

  .upload-modal-header h5 {
    text-transform: uppercase;
    font-size: 24px;
  }

  .modal-body + footer.modal-footer {
    border-top: 1px solid #dddddd;
    padding: 10px;
    padding-top: 10px;
  }

  .btn-neutral {
    color: initial;
  }

  .upload-container {
    max-width: 250px;
    text-align: center;
    margin: 0 auto;

    & > span {
      display: block;
      margin-top: 20px;
      color: var(--light);
    }

    .primary-text {
      color: var(--primary);
    }
  }

  .upload-area {
    position: relative;
    margin: 0 auto;
    border: 3px dashed #E3E3E3;
    border-radius: 10px;
    padding: 20px;

    &.drag {
      border-color: var(--primary);
    }

    .upload-icon {
      width: 50px;
      height: 50px;
      color: #DADDE2;
      margin-bottom: 30px;
      margin-top: 22px;
    }

    .file {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}
</style>
