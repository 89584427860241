<template>

    <b-card>
          <template v-slot:header>
            <h4>
              <i class="mr-2"><svgicon class="icon" icon="item-sso"/></i>
              <span>{{t('identity_provider_configuration')}}</span>
            </h4>
          </template>

          <el-form
            ref="identityProviderForm"
            :model="value"
            label-width="140px"
            label-position="top"
            style="padding-top: 30px; padding-left: 30px"
            :rules="{}"
          >
            <div class="row"> 
              <div class="col-md-4 col-xl-4 col-12">     
                  <div class="row" style="padding-bottom: 8vh">
                    <div class="col-md-6 col-xl-6 col-12">
                      <label> {{t('Identity provider type') }}</label>
                    </div>
                    <div class="col-md-6 col-xl-6 col-12" style="margin-top:-2vh">
                      <el-select
                        size="large"
                        placeholder="Identity Provider"
                        v-validate="'required'"
                        :error="getError('IdentityProvider')"
                        name="IdentityProvider"
                        :value="value.identity_provider_type">
                        <el-option v-for="option in $options.identityProviders"
                          class="select-primary"
                          :value="option.key"
                          :label="option.value"
                          :key="option.key">
                        </el-option>
                      </el-select>
                    </div>  
                  </div> 
                <div class="row" style="padding-bottom: 8vh">
                    <div class="col-md-6 col-xl-6 col-12">
                      <label> {{ t('Load cache if unavailable') }} </label>
                    </div>
                    <div class="col-md-6 col-xl-6 col-12" style="margin-top:-2vh">
                      <n-switch  id="inpu-enable" :value="value.local_cache" @input="toggle($event, 'local_cache')"/>
                    </div>  
                </div>   
                <div class="row" style="padding-bottom: 5vh">  
                  <div class="col-md-6 col-xl-6 col-12">
                    <label>{{ t('LDAP Cluster') }} </label>
                  </div>
                  <div class="col-md-6 col-xl-6 col-12" style="margin-top:-2vh">
                    <el-select
                      size="large"
                      placeholder="LDAP Cluster"
                      v-validate="'required'"
                      :error="getError('LDAPCluster')"
                      name="LDAPCluster"
                      @change="clusterChanged($event, 'ldap_cluster')"
                      :value="value.ldap_cluster">
                      <el-option v-for="option in clusters"
                        class="select-primary"
                        :value="option.key"
                        :label="option.value"
                        :key="option.key">
                      </el-option>
                    </el-select>
                  </div>
                </div> 
              </div>

              <div class="col-md-8 col-xl-8 col-12">
                <paginated-table :rows='rows'
                        :columns='columns'
                        :actions='actions'
                        :search="[]"
                        :withCreate="true"
                        @item-delete='handleDelete'
                        @edit-cancel='handleCancel'
                        @handle-new='handleNew'
                />
              </div> 
            </div>  
          </el-form>
      </b-card>
   
</template>

<script>


import { Switch } from '@/components';
import PaginatedTable from '@/components/Tables/PaginatedTable';
import EditSelect from '@/components/Tables/Formatters/EditSelect';
import { Select, Option } from 'element-ui';

export default {
  name: 'ldap-configuration',
  props: {
    value: {
        type: Object,
        required: true
    },
    clusters: {
        type: Array,
        default: () => []
    },
    groups: {
        type: Array,
        default: () => []
    }
  },
  components: {
      [Switch.name]: Switch,
      [Option.name]: Option,
      [Select.name]: Select,
      PaginatedTable,
  } , 
  identityProviders: [
      {
          key: 'IdentityProviderLDAP',
          value: 'IdentityProviderLDAP'
      }
  ],
 
  data() {
    return {
        identity_provider_type: 'IdentityProviderLDAP',
        configuration: this.value,
        columns: [
          { prop: 'ldap_group', label: this.t('group'), minWidth: 250, 
            selectOptions: [], 
            formatter: EditSelect,
            optionChange: null
          },
          { prop: 'sm_role', label: this.t('role'), minWidth: 250,
            mySelf: this,
            formatter: EditSelect,
            optionChange: null
          },
        ],
        actions: {
          minWidth: 75,
          label: this.t('actions'),
          fixed: 'right',
          items: [
            {
              type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove',
              action: this.t('Remove'), confirm: false, event: 'item-delete'
            },
          ]
        },
    };
  },
  computed: {
    rows: {
      get() {
        try {
          return this.value.table_mapping.items  
        } catch (error) {
          return []  
        }
      },
      set(data) {
        if ( data)
          this.configuration.table_mapping.items = data.reduce((acc, v) => {
              if (!acc.some(x => x.ldap_group === v.ldap_group && x.sm_role === v.sm_role)) 
                acc.push(v);
              return acc;
          }, []);
        else
          this.configuration.table_mapping.items = []

        this.$emit('input', this.configuration)
      }
    },
    selectedGroups() {
      return this.rows.map(row => row.ldap_group).filter(group => group != "");
    },
    filteredGroups() {
      return this.groups.filter(group => {
        return !this.selectedGroups.includes(group);
      });
    },
  },
  methods: {
      toggle(event, name){
        this.configuration[name] = event
        this.$emit('input',this.configuration)
      },
      clusterChanged(event, name){
        this.$emit('setCluster', event);
      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      handleDelete(data) {
        this.rows.splice(data.index, 1);
        this.rows = [...this.rows]
      },
      handleNew() {
        this.$emit('update_groups', '')
        this.rows.push({ldap_group: '', sm_role: ''})
      },
      handleCancel(data) {
        this.editingRow = null;
      },      
      optionChange(event, selected, row){
        this.rows[this.rows.indexOf(row)][selected] = event
        this.rows = [...this.rows]
      },
      getFormRef() {
        return this.$refs['identityProviderForm'];
      },
      getRoles() {
        if (this.$store.getters[`generalManagement/administrators/asm_roles`].size == 0) {
          let vm = this;
          this.$store.dispatch("generalManagement/administrators/getRoles")
          .then(() => {
            vm.setRoles();
          });
        } else {
          this.setRoles();  
        }
      },
      setRoles() {        
        this.columns[1].selectOptions = Array.from(this.$store.getters[`generalManagement/administrators/asm_roles`].keys()).map(role => 
        
          ({key: role, label: this.t(role == "Troubleshooting" ? role + "_ROL" : role), value: role})
        );  
      },
      ldapGroups() {
        this.columns[0].selectOptions = this.filteredGroups.map(item => { return { key: item, value: item }});
      }
  },
  created() {
    this.columns[0].optionChange = this.optionChange
    this.columns[1].optionChange = this.optionChange
    this.getRoles();
    this.$emit('update_groups', '')
  },
  mounted() {
    this.$nextTick(function () {
      this.getRoles();
    })
  }
};
</script>
<style scoped lang="scss">
  div.card-body {
    padding: 30px;
  }
  .label-default {
    margin-right: 10px;
  }
</style>    
