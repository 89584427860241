<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-card id="gm-data-export" no-body>
      <b-container fluid class="mobile-no-padding mobile-fixed-height">
        <b-card-body style="padding: 0px">
          <paginated-table
            :rows="rows"
            :columns="columns"
            :actions="actions"
            :updating="updating"
            @status-change='handleStatusChange'
            @item-edit="handleEdit"
            :showPagination="false"
          />
        </b-card-body>
      </b-container>
    </b-card>
  </div>
</template>

<script>
  import Switch from '@/components/Tables/Formatters/NoFunctionalSwitch';
  import List from '@/components/Tables/Formatters/List';
  import PaginatedTable from '@/components/Tables/PaginatedTable';
  import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

  export default {
    name: 'data-export',
    components: {
      PaginatedTable,
      PageTitleCard
    },
    props: {
    },
    data() {
      return {
        updating: false,
        columns: [
          { prop: "set_type", label: this.t("set_type"), minWidth: 130},
          { prop: "enabled", label: this.t("status"), minWidth: 100, formatter: Switch, visible_for_permissions: [{actions: ["PATCH"], path: "/data-export/configuration"}] },
          { prop: "export_interval_time_minutes", label: this.t("export_interval_time_minutes"), minWidth: 100},
          { prop: "products", label: this.t("products"), formatter: List, minWidth: 100},
          { prop: "data_export_compression_type", label: this.t("data_export_compression_type"), minWidth: 100},
        ],
        actions: {
          minWidth: 170,
          label: this.t("Actions"),
          fixed: "right",
          items: [
              {
                  type: "warning",
                  icon: "now-ui-icons business_badge",
                  event: "item-edit",
                  action: this.t("Edit"),
                  visible_for_permissions: [{actions: ["PATCH"], path: "/data-export/configuration"}]
              }
          ]
        },
        dataSet: []
      };
    },
    mounted() {
      this.refresh();
    },
    computed: {
      rows: {
        get() {
          const dataExport = _.cloneDeep(this.dataSet);
          if (dataExport.length > 0) {
            dataExport.forEach(element => {
              element.id = element.set_type;
              element.set_type = this.t(element.set_type);
              element.data_export_compression_type = this.t(element.data_export_compression_type);
              element.export_interval_time_minutes = element.export_interval_time_minutes ? element.export_interval_time_minutes : '-';
            });
          }
          return dataExport;
        },
        set(data) {}
      },
    },
    methods: {
      refresh(){
        let _vm = this
        this.updating = true;
        _vm.dataSet = [];
        this.$store.dispatch('generalManagement/dataExport/getDataExport')
          .then( res => {
            _vm.dataSet = res.items;
            this.updating = false;
          })
          .catch( err => {
            this.updating = false;
          });
      },
      handleEdit(data) {
        this.$router.push({
          name: `General Management / Data Export / Edit`,
          params: { id: data.row.id },
        });
      },
      handleStatusChange(updatedRow) {
        let key = updatedRow.id;
        let updatedData = {};
        updatedData[key] = { enabled: updatedRow.enabled };
        this.$store.dispatch('generalManagement/dataExport/updateDataExport', updatedData).then( res => {}).catch( err => { this.refresh(); } )
      }
    }
  }
</script>

<style scoped>
</style>
