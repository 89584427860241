<template>
  <div>
    <form @submit.prevent="save" v-if="selectedLanguage && !updating">
      <b-row class="d-flex align-items-center justify-content-between mb-4">
        <b-col sm="12" md="8" >
          <span class="mr-3">{{ t('language_select_help_text') }}</span>
          <el-select
            v-model="selectedLanguage"
            placeholder="Select the language"
            class="bordered-select"
          >
            <el-option
              v-for="option in languageOptions"
              :key="option.value"
              :label="option.name"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </b-col>
        <b-col sm="12" md="2" class="item-min-width">
          <n-button
            @click.native="showResetAlertModal = true"
            type="primary"
            size="md"
            round
            block
            class="bordered-button"
            :visible_for_permissions="visible_for_permissions"
          >
            <svgicon
              class="icon-reset"
              icon="icon-reset"
              width="18"
              height="18"
            />
            {{ t("reset") }}
          </n-button>
        </b-col>
      </b-row>
      <h5 class="text-uppercase tab-title">{{ t('customization_title') }}</h5>
      <p>{{ t('customization_description') }}</p>
      <b-row class="mt-4">
        <b-col
          sm="12" md="6"
          class="d-flex align-items-stretch"
          v-for="(page, index) in selectedLanguagePagesData"
          :key="selectedLanguage + index"
        >
          <blocking-page-text-card
            :languageCode="selectedLanguage"
            :pageType="page.pageType"
            :pageIndex="index+1"
            :pageCustomization="page.pageData"
            @change="handlePageDataChange"
            :visible_for_permissions="visible_for_permissions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="3" md="4" xs="12" sm="6" class="ml-auto">
          <n-button
            :visible_for_permissions="visible_for_permissions"
            native-type="submit"
            type="primary"
            id="btn_submit"
            round block
          >
            <template>
              {{ t('Save') }}
            </template>
          </n-button>
        </b-col>
      </b-row>
    </form>
    <BasicModal
      @onFirstBtn="resetBlockingPageText"
      @onSecBtn="showResetAlertModal = false"
      :firstBtnTxt="this.t('Reset')"
      :secBtnTxt="this.t('Cancel')"
      :txt="this.t('reset_alert_text')"
      :showModal="showResetAlertModal"
      :maxWidth="450"
    ></BasicModal>
  </div>
</template>

<script>
import BlockingPageTextCard from "./BlockingPageTextCard";
import BasicModal from "@/components/Modals/BasicModal";
import { DEFAULT_BLOCKING_PAGE_TEXTS } from "@/util/languageUtils";
import _ from "lodash";
import {mapActions} from "vuex";

export default {
  name: 'blocking-pages-texts-tab',
  components: {
    [BlockingPageTextCard.name]: BlockingPageTextCard,
    [BasicModal.name]: BasicModal
  },
  props: {
    value: {
      type: Object,
    },
    visible_for_permissions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selectedLanguage: 'en-US',
      blockingPagesTextsCustomization: null,
      showResetAlertModal: false,
      updating: false,
      isInitDone: false,
    }
  },
  created() {
    if (this.value) {
      this.blockingPagesTextsCustomization =
        _.cloneDeep(_.get(this.value, 'textsCustomization.env_options.blocking_pages'));
    };
  },
  mounted() {
    this.isInitDone = true;
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue){
        this.blockingPagesTextsCustomization =
          _.cloneDeep(_.get(this.value, 'textsCustomization.env_options.blocking_pages'));
        setTimeout(() => {
          this.$validator.reset();
        }, 100)
      }
    },
    blockingPagesTextsCustomization: {
      handler: function (newValue) {
        if (this.isInitDone) {
          const dirty = !_.isEqual(
            newValue,
            this.value.textsCustomization.env_options.blocking_pages
          );
          this.setIsFormDirty(dirty);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('settings', ['setIsFormDirty']),
    handlePageDataChange(change) {
      const {data, type} = change;
      this.blockingPagesTextsCustomization[this.selectedLanguage][type] = data;
    },
    async save(){
      this.updating = true;
      let isValid = await this.$validator.validateAll();
      if (isValid){
        const newTextsCustomization = _.cloneDeep(this.value.textsCustomization);
        newTextsCustomization.env_options.blocking_pages = this.blockingPagesTextsCustomization;
        this.$emit('save', {
          textsCustomization: newTextsCustomization,
          languagesCustomization: this.value.languagesCustomization
        })
        this.setIsFormDirty(false);
        setTimeout(() => {
          this.$validator.reset();
        }, 100)
      }
      this.updating = false;
    },
    resetBlockingPageText() {
      this.blockingPagesTextsCustomization = DEFAULT_BLOCKING_PAGE_TEXTS;
      this.showResetAlertModal = false;
      this.save();
    },
  },
  computed: {
    languageOptions() {
      return Object.keys(this.blockingPagesTextsCustomization).map(languageCode => ({
        name: this.t(languageCode),
        value: languageCode
      }));
    },
    selectedLanguagePagesData() {
      const result = [];
      const data = _.cloneDeep(this.blockingPagesTextsCustomization[this.selectedLanguage]);
      Object.keys(data).forEach((key, index) => result.push({
        pageType: key,
        pageData: data[key]
      }));
      const order = ['default', 'threat_protection', 'content_filter', 'password_override'];
      return result.sort((a,b) => order.indexOf(a.pageType) - order.indexOf(b.pageType));
    },
  }
}
</script>

<style lang="scss">
.icon-reset {
  margin: 0 6px 2px 0;
}
</style>
