<template>
    <div class="wrapper wrapper-full-page">
      <div class="full-page section-image" filter-color="black">
        <div class="content outer" style="height: 85vh;">
          <div class="container white middle">
           <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
                    <div class="inner">
                        <div style="color: #ffffff;">{{ t('About')}} {{appName}}</div>
                        <div id="div_version">{{ t('Version') }} {{ version }}</div>
                        <div style="font-size: 0.65em; line-height: 1.5em;">&copy; {{year}},
                         {{t('Designed by')}} <a :href="web">{{companyName}}</a>   
                        <br>{{ t('All rights Reserved')}}.</div>
                    </div>
            </zoom-center-transition>
          </div>
        </div>

        <div class="full-page-background" style="backgroundImage: url('img/bg.jpg'); max-width: 100%;"></div>
      </div>
    </div>
</template>

<script>
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
    components: {
        ZoomCenterTransition
    },
    name: 'about',
    data() {
        return {
            version: '', 
            year: new Date().getFullYear(),
            pageTransitionDuration: 200,
        };
    },
    computed: {
      appName() {
        return this.$store.getters['customProvider/appName']; 
      },
      companyName() {
        return this.$store.getters['customProvider/companyName']; 
      },
      web() {
        return this.$store.getters['customProvider/web']; 
      }
    },
    mounted(){
      if (process.env.VUE_APP_VERSION)
          this.version = process.env.VUE_APP_VERSION
    }
};
</script>
<style scoped lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}

.white {
    color: #d3d3d3;
}


.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  vertical-align: middle;
  line-height: 100px;
  font-size: 1.5em;
}
</style>
